<template>
    <!-- DEBUG -->
    <div v-if="1 < 0">
        <pre style="color: blue">{{ data }}</pre>
        <pre style="color: purple">{{ hasUnpaid }}</pre>
        <pre style="color: green">{{ hasPaid }}</pre>
    </div>

    <v-list-item
        v-if="status === 'DONE'"
        :value="value"
        @click="agendaRowClicked()"
    >
        <template #prepend>
            <v-chip
                prepend-icon="mdi-check"
                class="mr-5"
                :class="opacityClass"
                size="large"
                color="success"
                variant="flat"
            >
                {{ displayTime }}
            </v-chip>
        </template>
        <v-list-item-title class="text-bold text-black">
            {{ data.PatientName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-normal text-black">
            {{ $filters.codenumber(displayCodeNumber) }}
            <v-chip v-if="data.PatientAge" size="x-small">
                {{ data.PatientAge }} {{ $t("patient.age") }}
            </v-chip>
        </v-list-item-subtitle>
        <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
    </v-list-item>

    <v-list-item
        v-if="status === 'UNPAID'"
        :value="value"
        @click="agendaRowClicked()"
    >
        <template #prepend>
            <v-chip
                prepend-icon="mdi-account-cash"
                class="mr-5"
                :class="opacityClass"
                size="large"
                color="info"
                variant="flat"
            >
                {{ displayTime }}
            </v-chip>
        </template>
        <v-list-item-title class="text-bold text-black">
            {{ data.PatientName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-normal text-black">
            {{ $filters.codenumber(displayCodeNumber) }}
            <v-chip v-if="data.PatientAge" size="x-small">
                {{ data.PatientAge }} {{ $t("patient.age") }}
            </v-chip>
        </v-list-item-subtitle>
        <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
    </v-list-item>

    <v-list-item
        v-if="status === 'LATE'"
        :value="value"
        @click="agendaRowClicked()"
    >
        <template #prepend>
            <v-chip
                prepend-icon="mdi-account-clock"
                class="mr-5"
                :class="opacityClass"
                size="large"
                color="warning"
                variant="flat"
            >
                {{ displayTime }}
            </v-chip>
        </template>
        <v-list-item-title class="text-bold text-black">
            {{ data.PatientName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-normal text-black">
            {{ $filters.codenumber(displayCodeNumber) }}
            <v-chip v-if="data.PatientAge" size="x-small">
                {{ data.PatientAge }} {{ $t("patient.age") }}
            </v-chip>
        </v-list-item-subtitle>
        <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
        <template #append>
            <v-chip
                v-if="lateMins > 0"
                :class="opacityClass"
                color="warning"
                prepend-icon="mdi-timer-plus"
                variant="flat"
            >
                {{ lateMins }}+
            </v-chip>
        </template>
    </v-list-item>
    <v-list-item
        v-if="status === 'ARRIVED'"
        :value="value"
        @click="agendaRowClicked()"
    >
        <template #prepend>
            <v-chip
                prepend-icon="mdi-account-check"
                class="mr-5"
                :class="opacityClass"
                size="large"
                color="primary"
                variant="flat"
            >
                {{ displayTime }}
            </v-chip>
        </template>
        <v-list-item-title class="text-bold text-black">
            {{ data.PatientName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-normal text-black">
            {{ $filters.codenumber(displayCodeNumber) }}
            <v-chip v-if="data.PatientAge" size="x-small">
                {{ data.PatientAge }} {{ $t("patient.age") }}
            </v-chip>
        </v-list-item-subtitle>
        <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
        <template #append>
            <v-chip
                v-if="lateMins > 0"
                color="primary"
                :class="opacityClass"
                prepend-icon="mdi-timer-plus"
                variant="flat"
            >
                {{ lateMins }}+
            </v-chip>
        </template>
    </v-list-item>

    <v-list-item
        v-if="status === 'ELSE'"
        :value="value"
        @click="agendaRowClicked()"
    >
        <template #prepend>
            <v-chip
                prepend-icon="mdi-account-clock"
                variant="flat"
                class="mr-5"
                :class="opacityClass"
                size="large"
            >
                {{ displayTime }}
            </v-chip>
        </template>
        <v-list-item-title class="text-bold text-black">
            {{ data.PatientName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-normal text-black">
            {{ $filters.codenumber(displayCodeNumber) }}
            <v-chip v-if="data.PatientAge" size="x-small">
                {{ data.PatientAge }} {{ $t("patient.age") }}
            </v-chip>
        </v-list-item-subtitle>
        <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
    </v-list-item>
</template>
<script>
import { FIND_PATIENT_BY_CODENUMBER } from "@src/store/actionTypes";

export default {
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {},
        },
        hasUnpaid: {
            type: Array,
            required: false,
            default: () => [],
        },
        hasPaid: {
            type: Array,
            required: false,
            default: () => [],
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            status: "",
            statusValues: ["UNPAID", "DONE", "LATE", "ARRIVED", "ELSE"],
            lateMins: 0,
            opacityClass: "opacity-60",
        };
    },
    computed: {
        displayTime() {
            return this.data.AppStart.split(" ")[1].slice(0, 5);
        },
        displayCodeNumber() {
            const codeNumber = this.data.PatientCodeNumber;
            if (codeNumber.indexOf("{") >= 0) {
                return "Kennitala er ekki skráð";
            }
            return codeNumber;
        },
    },
    watch: {
        data: {
            handler: function () {
                this.updateStatus();
            },
            deep: true,
        },
        hasPaid() {
            this.updateStatus();
        },
        hasUnpaid() {
            this.updateStatus();
        },
    },
    mounted() {
        this.updateStatus();
    },
    methods: {
        agendaRowClicked() {
            const codenumber = this.data.PatientCodeNumber;
            this.$store
                .dispatch(FIND_PATIENT_BY_CODENUMBER, codenumber)
                .then(() => {
                    this.$router.push({ name: "journal" });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateStatus() {
            const codeNumber = this.data.PatientCodeNumber;
            const arrived = this.data.Arrived;
            const hasInvoice = this.data.hasInvoice;
            const appStart = this.data.AppStart;
            const now = new Date(),
                appointment = new Date(appStart);
            const test = (now - appointment) / 60000;
            let late = false;

            if (test >= 1) {
                late = true;
                // console.log(codeNumber, 'IS LATE', parseInt(test), 'minutes')
                let lateMins = parseInt(test);
                // max shown is 60 min
                if (lateMins > 60) {
                    lateMins = 60;
                } else if (lateMins > 10) {
                    lateMins -= lateMins % 10;
                }
                this.lateMins = lateMins;
            }

            const hasPaid = this.hasPaid.includes(codeNumber);
            const hasUnpaid = this.hasUnpaid.includes(codeNumber);

            // UNPAID
            if (hasUnpaid) {
                this.status = "UNPAID";
                return;
            }
            // DONE
            if (arrived && hasPaid) {
                this.status = "DONE";
                return;
            }
            // ARRIVED
            if (arrived && !hasInvoice) {
                this.status = "ARRIVED";
                return;
            }
            // LATE
            if (!arrived && late) {
                this.status = "LATE";
                return;
            }
            // ELSE
            this.status = "ELSE";
        },
    },
};
</script>
<style scoped>
.text-bold {
    font-weight: 700;
}
.text-normal {
    font-weight: 400;
}
</style>
