import ApiBase from "./apibase";

export default new (class Reception extends ApiBase {
    /**
     * Get the Signature system type
     * @returns {Promise}
     */
    getSignatureType() {
        return this.get("reception/signature/type");
    }

    requestSignature(id, params) {
        return this.postData(`reception/signature/request/${id}`, params);
    }
})();
