import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbarStore', {
  state: () => ({ 
    snackbar: false,
    snackbarText: '',
    snackClass: '',
    timeout: 2000,
  }),
  getters: {},
  actions: { 
      activate(text, status, timeout) {
        this.snackbar = false;
        this.snackbarText = text;
        this.snackClass = status;
        this.timeout = timeout;
        this.snackbar = true;
      }
  }
});
