<template>
    <v-container fluid>
        <v-row class="justify-center">
            <v-col cols="12">
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title
                            class="d-flex user-select-none mw-fitcontent"
                        >
                            {{ $t("doctor_dashboard.agenda") }}
                        </v-toolbar-title>
                        <div class="d-flex mx-auto align-items-center">
                            <v-btn
                                icon
                                size="x-small"
                                elevation="3"
                                @click="prevDay()"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div
                                class="text-bold text-center date-container mx-2"
                            >
                                {{ getCurrentDateString() }}
                            </div>
                            <v-btn
                                icon
                                size="x-small"
                                elevation="3"
                                @click="nextDay()"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn
                                :disabled="dayOffset === 0"
                                prepend-icon="mdi-calendar-today"
                                class="ml-4"
                                size="small"
                                elevation="3"
                                @click="resetOffset()"
                            >
                                {{ $t("doctor_dashboard.today") }}
                            </v-btn>
                        </div>
                        <div
                            v-if="lastUpdated"
                            class="d-flex align-items-center sub-header user-select-none mr-4"
                        >
                            {{ $t("doctor_dashboard.updated_at") }}
                            {{ lastUpdated }}
                        </div>
                    </v-toolbar>
                    <v-list class="p-4">
                        <div
                            v-if="loading"
                            class="d-flex justify-content-center align-items-center my-4 py-4"
                        >
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="200"
                                style="user-select: none"
                            >
                                {{ $t("login.loading") }}
                            </v-progress-circular>
                        </div>

                        <v-list-item
                            v-if="
                                !loading &&
                                done &&
                                showEmptyMessage &&
                                agendas.length === 0
                            "
                            class="d-flex justify-center"
                        >
                            <h3 class="text-center">
                                <v-icon color="grey" size="large">
                                    mdi mdi-calendar-alert
                                </v-icon>
                            </h3>
                            <p class="text-grey text-center">
                                {{ $t("doctor_dashboard.no_appointments") }}
                            </p>
                        </v-list-item>
                        <div v-if="!loading">
                            <div
                                v-for="(entry, i) in agendas"
                                :key="entry.id"
                                class="cards"
                                :class="i < agendas.length - 1 ? 'mb-2' : ''"
                                :style="{ background: entry.Color }"
                            >
                                <AgendaRow
                                    :data="entry"
                                    :has-unpaid="hasUnpaid"
                                    :has-paid="hasPaid"
                                    :value="i"
                                />
                            </div>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TmItemApi from "@src/api/tmitem";
import localService from "@src/api/local";
import AgendaRow from "./agendaRow.vue";

import { useStore } from "vuex";
import { useSnackbarStore } from "@stores/snackbarStore";

export default {
    components: { AgendaRow },
    data() {
        return {
            agendas: [],
            hasPaid: [],
            hasUnpaid: [],
            store: useStore(),
            snackbarStore: useSnackbarStore(),
            done: false,
            intervalIds: [],
            timeout: 15000, // 15 sek,
            lastUpdated: "",
            currentDate: "",
            dayOffset: 0,
            loading: true,
            showEmptyMessage: false,
        };
    },
    created() {
        this.loading = true;
        this.updateCurrentDate();
        this.getAgendas();
    },
    methods: {
        resetOffset() {
            this.dayOffset = 0;
            this.dayWasChanged();
        },
        isDateToday(date) {
            const today = new Date();
            return date.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
        },
        prevDay() {
            this.dayOffset -= 1;
            this.dayWasChanged();
        },
        nextDay() {
            this.dayOffset += 1;
            this.dayWasChanged();
        },
        dayWasChanged() {
            // only init and manual updates should show the spinner
            this.loading = true;
            this.updateCurrentDate();
            this.intervalIds.map((id) => clearInterval(id));
            this.getAgendas();
        },
        updateCurrentDate() {
            let date = new Date();
            date.setDate(date.getDate() + this.dayOffset);
            this.currentDate = date;
            return date;
        },
        getCurrentDateString() {
            try {
                const date = this.updateCurrentDate();
                const dayIndex = date.getDay().toString();
                const day = this.$t(`dates.days.${dayIndex}`);
                const dayNumber = date.getDate();
                const monthIndex = date.getMonth().toString();
                const month = this.$t(`dates.months.${monthIndex}`);
                const year = date.getFullYear();
                const todayString = this.$t(
                    "doctor_dashboard.today",
                ).toLowerCase();
                const dateIsToday = this.isDateToday(date);

                if (dateIsToday) {
                    return `${day} ${dayNumber}. ${month} ${year} (${todayString})`;
                } else {
                    return `${day} ${dayNumber}. ${month} ${year}`;
                }
            } catch (e) {
                return this.$t("doctor_dashboard.today");
            }
        },
        getTimeNow() {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            this.currentDate = new Date().toISOString().split("T")[0];
            return `${hours}:${minutes}`;
        },
        getAgendas() {
            this.showEmptyMessage = false;
            this.updateCurrentDate();
            // setting the interval
            this.intervalIds.map((id) => clearInterval(id));
            let id = setInterval(() => {
                this.getAgendas();
            }, this.timeout);
            this.intervalIds.push(id);

            const currentUserID = this.store.getters.currentUserID;
            const date = this.currentDate.toISOString().slice(0, 10);
            const start = new Date(date).setHours(0, 0, 0, 0);
            const end = new Date(date).setHours(23, 59, 59, 999);

            const data = {
                user_id: currentUserID,
                start_date: new Date(start.valueOf()),
                end_date: new Date(end.valueOf()),
                unit_id: "",
                resource_id: "",
                code_id: "",
            };

            TmItemApi.index(data)
                .then((res) => {
                    let agendas = res.data;
                    agendas.map(
                        (val) => (
                            (val.hasInvoice = false), (val.printed = false)
                        ),
                    );

                    if (agendas.length === 0) {
                        this.showEmptyMessage = true;
                    }

                    localService
                        .getUserInvoicesLight(currentUserID, date)
                        .then((res) => {
                            let data = res.data;
                            this.hasPaid = [];
                            this.hasUnpaid = [];

                            for (const item of data) {
                                const codenumber = item.patient.codenumber,
                                    printed =
                                        item.printed == "1" ? true : false;
                                printed
                                    ? this.hasPaid.push(codenumber)
                                    : this.hasUnpaid.push(codenumber);
                                this.hasPaid = [...new Set(this.hasPaid)];
                                this.hasUnpaid = [...new Set(this.hasUnpaid)];
                                agendas.map((a) =>
                                    a.PatientCodeNumber === codenumber
                                        ? ((a["hasInvoice"] = true),
                                          (a["printed"] = printed))
                                        : "",
                                );
                            }

                            this.agendas = agendas;
                            this.done = true;
                            this.lastUpdated = this.getTimeNow();
                        })
                        .catch(() => {
                            this.snackbarStore.activate(
                                "Agenda fetch failed",
                                "error",
                                2000,
                            );
                        })
                        .finally(() => {
                            if (!this.loading) {
                                return;
                            }
                            const time = this.agendas.length > 0 ? 1000 : 500;
                            // timeout is needed to "allow the dust to settle"
                            setTimeout(() => {
                                this.loading = false;
                            }, time);
                        });
                })
                .catch(() => {
                    this.snackbarStore.activate(
                        "Agenda fetch failed",
                        "error",
                        2000,
                    );
                });
        },
    },
};
</script>
<style scoped>
.sub-header {
    justify-content: space-between;
    align-items: center;
    height: 40px;
}
.cards {
    border-radius: 8px;
    margin-bottom: 0;
    padding: 10px;
}
/* longest datestring is Miðvikudagur xx. September xxxx */
.date-container {
    min-width: 240px;
}
.mw-fitcontent {
    max-width: fit-content;
}
</style>
