<template>
    <v-list-item class="m-0 p-0">
        <div class="date-button" :class="first ? '' : 'border-top'">
            <v-toolbar
                color="transparent"
                class="date-line ml-2"
                @click="collapseFunction"
            >
                <v-icon class="date-icon">mdi-calendar-month</v-icon>
                <v-toolbar-title>
                    <div class="text-bold user-select-none" :class="fontClass">
                        {{ formattedDate }}
                    </div>
                </v-toolbar-title>
                <!-- ICONS -->
                <div v-if="compact" class="mr-3">
                    <v-expand-transition appear leave>
                        <div class="d-flex">
                            <div
                                v-for="(type, index) in dateIcons"
                                :key="index"
                            >
                                <div v-if="iconAvailable(type)" class="ml-1">
                                    <v-tooltip
                                        activator="parent"
                                        location="top"
                                    >
                                        {{ iconTooltipTranslation(type) }}
                                    </v-tooltip>
                                    <v-avatar
                                        :color="getIcon(type).color"
                                        density="compact"
                                        size="small"
                                    >
                                        <v-icon
                                            v-if="isMdiIcon(type)"
                                            size="x-small"
                                            class="fa-avatar"
                                        >
                                            {{ getIcon(type).icon }}
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            size="x-small"
                                            class="fa-avatar"
                                            :class="getIcon(type).icon"
                                        />
                                    </v-avatar>
                                </div>
                            </div>
                        </div>
                    </v-expand-transition>
                </div>
                <v-icon
                    v-if="compact"
                    size="x-small"
                    class="text-black font-weight-light mr-5"
                >
                    mdi-chevron-right
                </v-icon>
                <v-icon
                    v-else
                    size="x-small"
                    class="text-black font-weight-light mr-5"
                >
                    mdi-chevron-down
                </v-icon>
            </v-toolbar>
        </div>
        <v-slide-y-transition appear leave>
            <v-list v-if="!compact" class="mx-0 p-0 bg-none">
                <journal-unit
                    v-for="(unit, index) in unitDataVariable"
                    :key="index"
                    :journal-data="unit"
                    class="p-0 m-0"
                    :unit="unit"
                    :font-class="fontClass"
                    @edit-item="handleEditItem"
                />
            </v-list>
        </v-slide-y-transition>
    </v-list-item>
    <div v-if="!compact" class="separator"></div>
</template>

<script>
import JournalUnit from "../journalunit/journalunit.vue";

export default {
    components: {
        JournalUnit,
    },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        compactDate: {
            type: Boolean,
            required: false,
            default: true,
        },
        first: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            compact: false,
            icons: [
                {
                    type: "2",
                    name: "diagnose",
                    color: "teal-lighten-2",
                    icon: "fat fa-person-dots-from-line",
                },
                {
                    type: "3",
                    name: "warning",
                    color: "blue",
                    icon: "fal fa-exclamation-triangle",
                },
                {
                    type: "4",
                    name: "lab",
                    color: "blue",
                    icon: "mdi-microscope",
                },
                { type: "5", name: "note", color: "blue", icon: "mdi-file" },
                {
                    type: "7",
                    name: "measure",
                    color: "green",
                    icon: "far fa-scalpel",
                },
                {
                    type: "9",
                    name: "attention",
                    color: "blue",
                    icon: "fal fa-exclamation-triangle",
                },
                {
                    type: "9N",
                    name: "attention_n",
                    color: "blue",
                    icon: "fal fa-exclamation-circle",
                },
                {
                    type: "9A",
                    name: "attention_a",
                    color: "blue",
                    icon: "fal fa-person-dots-from-line",
                },
                {
                    type: "9P",
                    name: "attention_p",
                    color: "blue",
                    icon: "fal fa-person-pregnant",
                },
                {
                    type: "9I",
                    name: "attention_i",
                    color: "blue",
                    icon: "fal fa-virus",
                },
                {
                    type: "9L",
                    name: "attention_l",
                    color: "blue",
                    icon: "fal fa-person-breastfeeding",
                },
                {
                    type: "9B",
                    name: "attention_b",
                    color: "blue",
                    icon: "mdi mdi-water-alert",
                },
                {
                    type: "9M",
                    name: "attention_m",
                    color: "blue",
                    icon: "mdi mdi-screw-round-top",
                },
                {
                    type: "16",
                    name: "prescription",
                    color: "blue",
                    icon: "mdi-prescription",
                },
                {
                    type: "18",
                    name: "vaccination",
                    color: "blue",
                    icon: "mdi-needle",
                },
            ],
            types: [],
        };
    },
    computed: {
        formattedDate() {
            let months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            const currentLanguage = this.$t("language.language");

            if (currentLanguage === "Icelandic") {
                months = [
                    "Janúar",
                    "Febrúar",
                    "Mars",
                    "Apríl",
                    "Maí",
                    "Júní",
                    "Júlí",
                    "Ágúst",
                    "September",
                    "Október",
                    "Nóvember",
                    "Desember",
                ];
            }

            const date = new Date(this.date);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const monthName = months[parseInt(month, 10) - 1];
            return `${day}. ${monthName} ${year}`;
        },
        unitDataVariable() {
            const grouped = [];
            let seen = [];
            for (let item of this.journalData) {
                let test = {
                    data_row: item.data.data_row_id,
                    version: item.data.version,
                };
                test = JSON.stringify(test);
                if (seen.indexOf(test) >= 0) {
                    // duplicate detected
                    item["do_not_render"] = true;
                    continue;
                }
                seen.push(test);
            }

            this.journalData.forEach((item) => {
                const unitId = item.data.metadata.unit_id;
                if (!grouped[unitId]) {
                    grouped[unitId] = {
                        unit_name: item.data.metadata.unit_name,
                        owner_name: item.data.metadata.owner_name,
                        items: [],
                    };
                }
                grouped[unitId].items.push(item);
            });
            return Object.values(grouped);
        },
        dateIcons() {
            let types = [];
            const subNine = "NAPILBM".split("");
            for (let variable of this.unitDataVariable) {
                const items = variable.items;
                for (let item of items) {
                    // type 9 has sub categories
                    if (item.type === "9") {
                        if ("data" in item && "category" in item.data) {
                            const category = item.data.category;
                            if (subNine.indexOf(category) >= 0) {
                                types.push(`9${category}`);
                            }
                        }
                        continue;
                    }
                    types.push(item.type);
                }
            }
            return [...new Set(types)];
        },
    },
    watch: {
        compactDate() {
            this.compact = this.compactDate === true ? true : false;
        },
    },
    mounted() {
        this.compact = this.compactDate === true ? true : false;
        this.types = this.icons.map((val) => val.type);
    },
    methods: {
        isMdiIcon(type) {
            return this.getIcon(type).icon.indexOf("mdi") >= 0;
        },
        iconTooltipTranslation(type) {
            const icon = this.icons.find((val) => val.type == type);
            const name = icon.name;
            return this.$t("journal.type." + name);
        },
        iconAvailable(type) {
            return this.types.indexOf(type) >= 0;
        },
        getIcon(type) {
            return this.icons.find((val) => val.type == type);
        },
        handleEditItem(info) {
            this.$emit("edit-item", info);
        },
        collapseFunction() {
            this.compact = this.compact === true ? false : true;
        },
    },
};
</script>
<style scoped>
.date-button:hover {
    background-color: rgba(110, 183, 204, 0.2) !important;
}
.date-line:hover {
    cursor: pointer;
}
.separator {
    height: 20px;
}
.date-icon {
    margin-left: 5px;
}
.border-top {
    padding: 0;
    border-top: 1px solid lightgray;
}
.bg-none {
    background: none;
}
</style>
