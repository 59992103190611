<template>
  <v-container class="my-3 p-0 mx-0">
    <v-card>
      <v-toolbar class="bg-primary">
        <i class="fas fa-plus-circle fa-xl ml-4"></i>
        <v-app-bar-title class="user-select-none">{{ $t('prescription.pharmacy') }}</v-app-bar-title>
        <div class="d-flex align-items-center mr-2">
          <span class="mr-1 cr">*</span>
          <select v-model="pharmacy" class="form-control sendto" >
            <option v-for="item in available" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
          <check-box-button v-model="homeDelivery" class="w-100">{{ $t('prescription.send_home') }}</check-box-button>
        </div>
      </v-toolbar>
      <v-scroll-y-transition appear leave>
        <v-container v-if="homeDelivery" class="pb-0">
          <div class="d-flex justify-content-between p-0">
            <div class="col-3 p-0 mx-0">
              <v-text-field 
                v-model="address" 
                :error="errors.address"
                required 
                variant="outlined"
                density="compact"
              >
                <template #label>
                  <span class="mr-1 cr">*</span>
                  <span>{{ $t('prescription.address') }}</span>
                </template>
              </v-text-field>
            </div>
            <div class="col-2 p-0 mx-0">
              <v-text-field 
                v-model="zipcode" 
                :error="errors.zip"
                required 
                variant="outlined"
                density="compact"
              >
                <template #label>
                  <span class="mr-1 cr">*</span>
                  <span>{{ $t('prescription.postal_code') }}</span>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 p-0 mx-0">
              <v-text-field 
                v-model="comment" 
                variant="outlined"
                density="compact"
                :label="$t('prescription.comment_for_delivery')"
              >
              </v-text-field>
            </div>
          </div>
        </v-container>
      </v-scroll-y-transition>
    </v-card>
  </v-container>
</template>

<script>
import { ref, computed, onMounted ,getCurrentInstance, defineExpose } from 'vue';
import localService from '@src/api/local';
import CheckBoxButton from '@components/checkBoxButton';
import LabelBasic from '@components/labelBasic';
import TextInput from '@components/textInput';


export default {
  components: {
    CheckBoxButton,
    LabelBasic,
    TextInput
  },
  setup() {
    const available = ref([]);
    const pharmacy = ref(0);
    const address = ref('');
    const zipcode = ref('');
    const comment = ref('');
    const homeDelivery = ref(false);

    const errors = ref({ address: false, zip: false });

    const requestData = computed(() => {
      let request = {
        pharmacy_id: pharmacy.value,
      };
      if (homeDelivery.value) {
        request.delivery = {
          address: address.value,
          zip: zipcode.value,
          comment: comment.value
        };
      }
      return request;
    });


    console.log(requestData.value,'________________________in pharmacy1')


    const setError = (error) => {
      errors.value[error] = true;
    };

    const clearError = (error) => {
      errors.value[error] = false;
    };

    const t = (key) => {
      return key; // Placeholder for translation function
    };

    onMounted(() => {
      localService.getAvailablePharmacies().then(res => {
        available.value = res.data;
      }).catch(err => {
          // Some 500 error
        });
    });

    defineExpose({
      requestData,
      setError,
      clearError,
    });

    return {
      available,
      pharmacy,
      address,
      zipcode,
      comment,
      homeDelivery,
      errors,
      requestData,
      setError,
      clearError,
      t,
    };
  }
};
</script>
<style scoped>
.cr { color: red; font-size:1rem; }
.sendto { min-height: 2.5rem; }
.sendto:hover { cursor: pointer; }
</style>

