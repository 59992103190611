<template>
    <v-app id="app" class="mx-2">
        <v-toolbar color="transparent" class="pt-0 border-bottom">
            <v-app-bar-nav-icon
                class="ml-5"
                @click="toggleJournalSettingsSidebar"
            />
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <p class="my-auto">{{ getFontSizeName() }}</p>
            <div>
                <v-btn
                    icon
                    :disabled="isSmallFontSize()"
                    @click="decreaseFontSize"
                >
                    <v-icon>mdi-format-font-size-decrease</v-icon>
                </v-btn>
                <v-btn
                    icon
                    :disabled="isExtraLargeFontSize()"
                    @click="increaseFontSize"
                >
                    <v-icon>mdi-format-font-size-increase</v-icon>
                </v-btn>
            </div>
            <v-btn v-if="isEditorCollapsed" icon @click="showEditor">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-container
            fluid
            class="scrollable-container pt-0 px-0 mx-0 overflow-x-hidden"
            @scroll="onScroll"
        >
            <template v-for="(date, index) in groupedByDateKeys" :key="date">
                <journal-day
                    :date="date"
                    :entries="groupedByDate[date]"
                    :journal-data="groupedByDate[date]"
                    :compact-date="daysCompact"
                    :first="index === 0"
                    :font-class="fontClass"
                    @edit-item="handleEditItem"
                />
            </template>
            <!-- No Data (no filters are being used) -->
            <div
                v-if="journalData.length === 0 && !isTypeFilterActive"
                class="no-more-data text-center mt-6"
            >
                <h3 class="text-center">
                    <v-icon color="grey" size="large">
                        mdi mdi-file-alert
                    </v-icon>
                </h3>
                <p class="text-grey text-center user-select-none mt-4">
                    {{ $t("journal.view.no_data_found") }}
                </p>
            </div>
            <!-- No Data (filters are being used) -->
            <div
                v-if="journalData.length === 0 && isTypeFilterActive"
                class="no-more-data text-center mt-6"
            >
                <h3 class="text-center">
                    <v-icon color="grey" size="large">
                        mdi mdi-file-alert
                    </v-icon>
                </h3>
                <p class="text-grey text-center user-select-none mt-4">
                    {{ $t("journal.view.no_filtered_data_found") }}
                </p>
            </div>
            <!-- Loader and No More Data Message -->
            <div v-if="noDataLeft" class="no-more-data text-center">
                <v-row
                    align-content="center"
                    class="fill-height"
                    justify="center"
                >
                    <v-col cols="6">
                        <v-col class="text-subtitle-1 text-center" cols="12">
                            <v-divider opacity="100%" color="primary" />
                            <p>{{ $t("journal.end_of_patient_history") }}</p>
                        </v-col>
                    </v-col>
                </v-row>
            </div>
            <div v-else-if="loading">
                <v-col cols="12" class="text-center my-0 py-0">
                    <v-col
                        class="text-subtitle-1 text-center user-select-none"
                        cols="12"
                    >
                        {{ $t("journal.loading_next_batch") }}
                    </v-col>
                    <v-progress-linear
                        color="primary"
                        height="6"
                        indeterminate
                        rounded
                        style="width: 50%; margin: 0 auto"
                    />
                    <v-col class="text-subtitle-1 text-center pb-0" cols="12">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-col>
                </v-col>
            </div>
        </v-container>
    </v-app>
</template>

<script>
import JournalDay from "./journalday/journalday.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { getCurrentInstance } from "vue";
import { watch } from "vue";

import { useSidebarsStore } from "@stores/SidebarsStore";
import { useUserSettingStore } from "@stores/userSettingStore";
import { useJournalStore } from "@src/stores/journalStore";

export default {
    components: {
        JournalDay,
    },
    props: {
        journalData: {
            type: Array,
            required: true,
        },
        isEditorCollapsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        onNoMoreData: {
            type: Boolean,
            required: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    emits: ["fetch-more-data", "edit-item", "show-editor"],
    setup(props) {
        const store = useStore();
        const patient_name = computed(() => store.getters.currentPatientName);
        const loading = ref(false);
        const noDataLeft = ref(false);
        const compactInitFetchDone = ref(false);
        const isTypeFilterActive = ref(false);
        const { proxy } = getCurrentInstance();
        const emit = proxy.$emit;

        const onScroll = (event) => {
            const target = event.target;
            if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
                loading.value = true;
                emit("fetch-more-data");
            }
        };
        const sideBarStore = useSidebarsStore();

        const toggleJournalSettingsSidebar = () => {
            const currentState = sideBarStore.getJournalSettingsSidebarState();
            sideBarStore.setShowJournalSettingsSidebar(!currentState);
        };

        // Watch for changes in journalData
        watch(
            () => props.journalData,
            () => {
                if (props.onNoMoreData) {
                    noDataLeft.value = true;
                    loading.value = false;
                } else {
                    noDataLeft.value = false;
                }
            },
            { deep: true },
        );

        watch(
            () => sideBarStore.typesFromJournalStore,
            (newValue) => {
                const filterTypesLength = newValue.length;
                const defaultTypesLength = sideBarStore.defaultTypes.length;
                const allActive = filterTypesLength === defaultTypesLength;
                allActive
                    ? (isTypeFilterActive.value = false)
                    : (isTypeFilterActive.value = true);
            },
            { deep: true },
        );

        return {
            patient_name,
            onScroll,
            loading,
            noDataLeft,
            compactInitFetchDone,
            toggleJournalSettingsSidebar,
            isTypeFilterActive,
        };
    },
    data() {
        return {
            userStore: useUserSettingStore(),
            journalStore: useJournalStore(),
            daysCompact: false,
        };
    },
    computed: {
        sortedData() {
            let sortedData = this.journalData;
            return sortedData;
        },
        groupedByDate() {
            const grouped = this.journalData.reduce((acc, item) => {
                const key = item.date.split(" ")[0];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});

            Object.keys(grouped).forEach((key) => {
                grouped[key].sort(
                    (a, b) => new Date(b.created) - new Date(a.created),
                );
            });

            const sortedGroups = Object.keys(grouped)
                .sort((a, b) => new Date(b) - new Date(a))
                .reduce((sorted, key) => {
                    sorted[key] = grouped[key];
                    return sorted;
                }, {});

            return sortedGroups;
        },
        groupedByDateKeys() {
            return Object.keys(this.groupedByDate);
        },
    },
    created() {
        const setting = this.userStore.getJournalSettingsCompactSettings();
        this.daysCompact = setting.days;
        // listen to compact changes
        this.userStore.$subscribe((mutation) => {
            if (mutation.storeId === "userSetting") {
                const updatedSettings =
                    this.userStore.getJournalSettingsCompactSettings();
                this.daysCompact = updatedSettings.days;

                if (!this.daysCompact) {
                    return;
                }
                this.loadExtra();
            }
        });
    },
    mounted() {
        if (this.daysCompact) {
            this.loadExtra();
        }
    },
    methods: {
        getFontSizeName() {
            return this.$t("journal.font_sizes." + this.fontClass);
        },
        decreaseFontSize() {
            this.journalStore.decreaseFontSize();
        },
        increaseFontSize() {
            this.journalStore.increaseFontSize();
        },
        isSmallFontSize() {
            return this.journalStore.getCurrentFontSizeName() === "small";
        },
        isExtraLargeFontSize() {
            return this.journalStore.getCurrentFontSizeName() === "extra-large";
        },
        // for compact days
        loadExtra() {
            // TODO check if user has manually collapsed all -> fetch again

            if (this.noDataLeft || this.compactInitFetchDone) {
                return;
            }

            this.loading = true;
            this.$emit("fetch-more-data");
            this.compactInitFetchDone = true;
        },
        compactDate(date) {
            return this.collapsedDates.indexOf(date) >= 0;
        },
        handleEditItem(info) {
            this.$emit("edit-item", info);
        },
        showEditor() {
            this.$emit("show-editor");
        },
    },
};
</script>

<style scoped>
#app {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scrollable-container {
    margin-bottom: 120px;
    overflow-y: auto;
}

.border-bottom {
    border-bottom: 1px solid lightgray;
}

/* Scrollbar related (Chrome) */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #666666ff;
}
</style>
