<template>
    <v-container class="p-0">
        <term-name v-model="termName" />
        <tiptap-editor v-model="textValue" />
    </v-container>
</template>

<script>
import TermName from "@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue";
import TiptapEditor from "@src/components/tiptapEditor/tiptapEditor.vue";
import { defineComponent, ref, watch, onMounted } from "vue";

export default defineComponent({
    name: "FormattedText",
    components: {
        TermName,
        TiptapEditor,
    },
    props: {
        keywordData: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:keywordData"],
    setup(props, { emit }) {
        const termName = ref("This is FormattedText Text");
        const textValue = ref("");

        onMounted(() => {
            if (props.keywordData.text) {
                textValue.value = props.keywordData.text;
            }
        });

        watch(
            () => props.keywordData,
            (newVal) => {
                if (newVal) {
                    termName.value = newVal.term_name || termName.value;
                    textValue.value = newVal.text || textValue.value;
                    let text = textValue.value;
                    if (text === null || text.length === 0) {
                        return;
                    }
                    if (text.indexOf("<p>") >= 0) {
                        return;
                    }
                    if (text.indexOf("\n") > 0) {
                        text = text.split("\n");
                        for (let i = 0; i < text.length; i++) {
                            text[i] = `<p>${text[i]}</p>`;
                        }
                        textValue.value = text.join("");
                    }
                }
            },
            { immediate: true },
        );

        watch(termName, (newVal) => {
            emit("update:keywordData", {
                ...props.keywordData,
                term_name: newVal,
            });
        });

        watch(textValue, (newVal) => {
            emit("update:keywordData", { ...props.keywordData, text: newVal });
        });

        return {
            termName,
            textValue,
        };
    },
});
</script>
