<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <v-avatar
                    :color="getHoverColor()"
                    density="compact"
                    class="mr-3"
                    @mouseenter="hovered = true"
                    @mouseleave="hovered = false"
                >
                    <v-icon size="x-small" @click.stop="editNote(journalData)">
                        {{ getAvatarIcon() }}
                    </v-icon>
                </v-avatar>
                <div class="text-bold user-select-none" :class="fontClass">
                    {{ journalData.data.title }}
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode && !isEmpty()" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div
                        v-for="(keyword, index) in journalData.data.cbm_keyword"
                        :key="index"
                    >
                        <template v-if="hasContent(keyword)">
                            <div class="text-bold">{{ keyword.term_name }}</div>
                            <div class="text-normal m-0 p-0">
                                <readOnlyTipTap
                                    :content="getContent(keyword)"
                                    class="m-0 p-0 mb-n2"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import readOnlyTipTap from "./noteReadOnlyTipTap.vue";
import Owner from "./../owner/owner.vue";

export default {
    components: { readOnlyTipTap, Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    emits: ["edit-item"],
    data() {
        return {
            hovered: false,
            compactMode: false,
        };
    },
    computed: {
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;

        for (let item of this.journalData.data.cbm_keyword) {
            let text = item.text;
            if (text === null || text.length === 0) {
                continue;
            }
            text = item.text;
            if (text.indexOf("<p>") >= 0) {
                continue;
            }
            if (text.indexOf("\n") < 0) {
                item.text = "<p>" + text + "</p>";
            } else {
                text = text.split("\n");
                for (let i = 0; i < text.length; i++) {
                    text[i] = "<p>" + text[i] + "</p>";
                }
                item.text = text.join("");
            }
        }
    },
    methods: {
        isEmpty() {
            const keywords = this.journalData.data.cbm_keyword;
            return keywords.length === 0;
        },
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        getContent(keyword) {
            return keyword.text && keyword.text.length > 0
                ? keyword.text
                : keyword.term_value;
        },
        hasContent(keyword) {
            const val = this.getContent(keyword);
            return val != null && val.length > 0;
        },
        getHoverColor() {
            return this.hovered ? "grey" : "blue";
        },
        getAvatarIcon() {
            return this.hovered ? "mdi-pencil" : "mdi-file";
        },
        editNote(item) {
            const id = item.data.data_row_id;
            this.$emit("edit-item", { id: id, type: "5" });
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
