import { defineStore } from "pinia";

export const useSidebarsStore = defineStore("sidebars", {
    state: () => ({
        showInvoiceSidebar: false,
        showPatientSidebar: false,
        showUserSettingsSidebar: false,
        showJournalSettingsSidebar: false,
        defaultTypes: [2, 3, 4, 5, 7, 9, 16, 18], // should be treated like a constant (13 was removed)
        typesFromJournalStore: [],
        sortFromJournalStore: "desc",
    }),
    actions: {
        // Setter for showInvoiceSidebar
        setShowInvoiceSidebar(value) {
            this.showInvoiceSidebar = value;
        },
        // Setter for showJournalSettingsSidebar
        setShowJournalSettingsSidebar(value) {
            this.showJournalSettingsSidebar = value;
        },
        getJournalSettingsSidebarState() {
            return this.showJournalSettingsSidebar;
        },
        setShowPatientSidebar(value) {
            this.showPatientSidebar = value;
        },
        toggleShowPatientSidebar() {
            this.showPatientSidebar = !this.showPatientSidebar;
        },
        toggleUserSettingsSidebar() {
            this.showUserSettingsSidebar = !this.showUserSettingsSidebar;
        },
        isPatientSidebarIsShown() {
            return this.showPatientSidebar;
        },
        openPatientSidebar() {
            this.showPatientSidebar = true;
        },
        closePatientSidebar() {
            this.showPatientSidebar = false;
        },
        setTypesFromJournalStore(types) {
            this.typesFromJournalStore = types;
        },
        setSortFromJournalStore(value) {
            this.sortFromJournalStore = value;
        },
        resetFilterSettings() {
            this.typesFromJournalStore = this.defaultTypes;
        },
        resetSettings() {
            this.typesFromJournalStore = this.defaultTypes;
            this.sortFromJournalStore = "desc";
            console.log("Settings have been reset:", {
                types: this.typesFromJournalStore,
                sortOrder: this.sortFromJournalStore,
            });
        },
    },
});
