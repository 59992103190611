<template>
    <v-container class="mt-5 py-0 px-2">
        <v-card v-if="showEditor">
            <v-toolbar>
                <!-- MENU BUTTON & MENU -->
                <v-menu :disabled="isLoading" transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                        <v-app-bar-nav-icon
                            v-tooltip:bottom="$t('journal.note-menu.title')"
                            v-bind="props"
                            icon="mdi-alert-circle"
                        />
                    </template>
                    <v-card class="mx-auto" max-width="300">
                        <v-list density="compact">
                            <v-list-subheader class="user-select-none">{{
                                $t("journal.note-menu.title")
                            }}</v-list-subheader>
                            <v-list-item
                                @click="() => handleSaveAttention(true)"
                            >
                                <template #prepend>
                                    <v-icon
                                        color="primary"
                                        icon="mdi-folder-download-outline"
                                        class="mr-n4"
                                    ></v-icon>
                                </template>
                                <v-list-item-title>{{
                                    $t("journal.note-menu.save_as_draft")
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
                <!-- TITLE -->
                <v-toolbar-title
                    v-tooltip:bottom="msg"
                    class="title text-small user-select-none"
                >
                    {{
                        isNewNote
                            ? $t("attention.create")
                            : $t("attention.edit")
                    }}
                </v-toolbar-title>
                <!-- MARKS AS DELETED - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.delete')"
                    :disabled="isLoading"
                    icon
                    @click="deleteAttention()"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- SAVES AS NON DRAFT - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.save')"
                    :disabled="isLoading"
                    icon
                    @click="handleSaveAttention(false)"
                >
                    <v-icon>mdi-floppy</v-icon>
                </v-btn>
                <!-- CLOSE FORM -->
                <!-- <v-btn -->
                <!--     v-if="!isNewEntry()" -->
                <!--     v-tooltip:bottom="$t('journal.note-menu.close')" -->
                <!--     :disabled="isLoading" -->
                <!--     icon -->
                <!--     @click="handleClose" -->
                <!-- > -->
                <!--     <v-icon>mdi-close</v-icon> -->
                <!-- </v-btn> -->
            </v-toolbar>
            <v-container>
                <div
                    v-if="isLoading"
                    class="d-flex justify-content-start align-items-start col-12"
                >
                    <v-skeleton-loader
                        class="skeleton-loader mt-4 col-12"
                        :type="'heading, subtitle, '.repeat(4)"
                    />
                </div>
                <v-form v-else>
                    <v-select
                        v-model="selectedCategory"
                        :label="$t('attention.category')"
                        :items="categories"
                        item-title="description"
                        variant="outlined"
                        density="compact"
                    />
                    <v-text-field
                        v-model="attentionText"
                        :label="$t('attention.text')"
                        variant="outlined"
                        density="compact"
                    />
                    <v-card
                        v-if="selectedItem"
                        prepend-icon="mdi-pill"
                        class="d-flex align-items-center mb-5"
                        color="blue-lighten-5"
                    >
                        <div class="d-flex align-items-center flex-grow-1">
                            <v-chip class="mr-2" size="small" density="compact">
                                {{ selectedItem.atcCode }}
                            </v-chip>
                            <span style="font-weight: bold">
                                {{ selectedItem.description }}
                            </span>
                        </div>
                        <v-icon
                            class="mdi mdi-close-circle mr-3"
                            @click="clearSelectedItem"
                        />
                    </v-card>
                    <!-- <v-text-field v-else v-model="search" label="ATC" @focus="openDialog" @input="filterItems"></v-text-field>
                    <v-dialog v-model="dialog" max-width="400">
                    <v-card>
                    <v-list>
                    <v-list-item v-for="item in filteredItems" :key="item.atcCode" @click="selectItem(item)">
                    <v-list-item-content>
                    <v-list-item-title>
                    <v-chip color="blue">{{ item.atcCode }}</v-chip>
                    {{ item.description }}
                    </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    </v-list>
                    </v-card>
                    </v-dialog> -->
                    <v-autocomplete
                        v-else
                        v-model="selectedItem"
                        label="ATC"
                        variant="outlined"
                        density="compact"
                        placeholder="Search medicine by atc code or name"
                        item-title="description"
                        item-value="atcCode"
                        :items="atcCodes"
                        :loading="atcLoading"
                        no-filter
                        @update:search="getAtcCodes"
                        @update:focus="atcCodes = []"
                    >
                        <template v-slot:item="{ item }">
                            <v-list-item @click="selectItem(item.raw)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-chip
                                            color="blue"
                                            density="compact"
                                            size="small"
                                        >
                                            {{ item.value }}
                                        </v-chip>
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-textarea
                        v-model="comment"
                        :label="$t('attention.comment')"
                        rows="2"
                        variant="outlined"
                        density="compact"
                    />
                </v-form>
            </v-container>
        </v-card>
    </v-container>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import atcCode from "@src/api/atcCode";
import Attention from "@src/api/attention";
import { useStore } from "vuex";
import { useJournalStore } from "@src/stores/journalStore";
import { useSnackbarStore } from "@stores/snackbarStore";
import { useI18n } from "vue-i18n";

const journalStore = useJournalStore();
const snackbarStore = useSnackbarStore();

const props = defineProps({
    dataRowId: {
        type: Number,
        default: null,
    },
    version: {
        type: Number,
        default: null,
    },
});

const showEditor = ref(true);
const emit = defineEmits(["close"]);
const handleClose = () => {
    closeAttentionEditor();
    emit("close"); // emitting the data to the parent component
};

const closeAttentionEditor = () => {
    journalStore.resetEditor();
    showEditor.value = false;
};

const store = useStore();
const dialog = ref(false);
const search = ref("");
const items = ref([]);
const filteredItems = ref([]);
const selectedCategory = ref("A");
const attentionText = ref("");
const comment = ref("");
const dataRowID = ref(props.dataRowId);
const version = ref(props.version);
const isEditMode = ref(true);
const atcCodes = ref([]);
const atcLoading = ref(false);
const isDraft = ref(0);
const autoSaveValue = ref(1);
const isLoading = ref(true);
const isNewNote = ref(true);
const dataDate = ref(null);
const { t } = useI18n();
let timer = null;

const startIdleTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        // No changes detected for 5 seconds
        saveAttention();
    }, 5000);
};

const categories = [
    { value: "A", description: t("attention.allergy") },
    { value: "I", description: t("attention.infection") },
    { value: "B", description: t("attention.blood_infection") },
    { value: "P", description: t("attention.pregnancy") },
    { value: "L", description: t("attention.lactation") },
    { value: "M", description: t("attention.metal_implant") },
    { value: "N", description: t("attention.none") },
];

const selectedItem = ref(null);

const openDialog = () => {
    dialog.value = true;
};

const selectItem = (item) => {
    selectedItem.value = item;
    search.value = `${item.atcCode} - ${item.description}`;
    dialog.value = false;
};

const fetchItems = async () => {
    try {
        const response = await atcCode.index();
        items.value = response.data;
        filteredItems.value = items.value;
    } catch (error) {
        console.error("Failed to fetch items:", error);
    }
};

const getAtcCodes = async (search) => {
    atcLoading.value = true;
    if (search.length >= 1) {
        try {
            const response = await atcCode.index({ search: search });
            atcCodes.value = response.data;
        } catch (error) {
            console.error("Failed to fetch items:", error);
        }
    }
    atcLoading.value = false;
};

/*
const filterItems = () => {
    filteredItems.value = items.value.filter((item) => {
        const atcCode = item.atcCode ? item.atcCode.toLowerCase() : "";
        const description = item.description
            ? item.description.toLowerCase()
            : "";
        const searchQuery = search.value.toLowerCase();
        return (
            atcCode.includes(searchQuery) || description.includes(searchQuery)
        );
    });
};
*/

const clearSelectedItem = () => {
    selectedItem.value = null;
    search.value = "";
};

const patient_id = store.getters.currentPatientID;
const journal_type_id = store.getters.currentJournalTypeID;
const unit_id = store.getters.currentUnitID;
const userDataID = store.getters.currentUserDataID;
const snackbarTriggeredByButton = ref(false);

// manual save
const handleSaveAttention = async (draft) => {
    autoSaveValue.value = 0;
    snackbarTriggeredByButton.value = true;
    draft ? (isDraft.value = 1) : (isDraft.value = 0);
    saveAttention();
    handleClose();
};

const saveAttention = async () => {
    const attentionData = {
        text: attentionText.value,
        atc_code: selectedItem.value ? selectedItem.value.atcCode : null,
        atc_desc: selectedItem.value ? selectedItem.value.description : null,
        category: selectedCategory.value,
        comment: comment.value,
        patient_id: patient_id, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: dataDate.value,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: isDraft.value,
        use_time: 0,
        autosave: autoSaveValue.value,
    };

    try {
        if (!dataRowID.value) {
            const response = await Attention.store(attentionData);
            const newAttention = response;
            dataRowID.value = newAttention.DataRowID;
            journalStore.setEditItem(dataRowID.value, "9");
            version.value = newAttention.Version;
            isLoading.value = false;
        } else {
            // Earlier, attentionData was not correctly handling the case when it contained values during the edit,
            // as saveAttention was called on mount due to autosave.
            const existingData = await Attention.show(dataRowID.value);
            const existingAttention = existingData.data;

            const draft = existingData.data.metadata.draft === "1";
            draft ? (isDraft.value = 1) : (isDraft.value = 0);

            const hasChanges =
                JSON.stringify(existingAttention) !==
                JSON.stringify(attentionData);

            if (hasChanges) {
                await Attention.update(dataRowID.value, attentionData);
                if (snackbarTriggeredByButton.value) {
                    // "Updated successfully!",
                    snackbarStore.activate(
                        t("journal.snackbar.updated"),
                        "success",
                        2000,
                    );
                    snackbarTriggeredByButton.value = false;
                }
            }
        }
    } catch (error) {
        console.error("Failed to save or update attention:", error);
        snackbarStore.activate(t("journal.snackbar.error"), "error", 2000);
    }
};

const formatDataDate = (date) => {
    let formatted = new Date(date);
    return formatted
        .toISOString()
        .replace("Z", "")
        .replace("T", " ")
        .split(".")[0];
};

const deleteAttention = () => {
    journalStore.setDeleteItem(dataRowID.value, "9");
};

// entry is opened from editor
const isNewEntry = () => {
    return journalStore.editor.newEntry != undefined;
};

watch([comment, attentionText, selectedCategory, selectedItem], () => {
    startIdleTimer();
});

onUnmounted(() => {
    if (timer) {
        clearTimeout(timer);
    }
});

onMounted(() => {
    dataRowID.value ? (isNewNote.value = false) : "";
    fetchItems();
    if (dataRowID.value) {
        Attention.show(dataRowID.value)
            .then((response) => {
                const existingAttention = response.data;
                const dataDateValue = existingAttention.metadata.data_date;
                dataDate.value = formatDataDate(dataDateValue);
                attentionText.value = existingAttention.text;
                selectedItem.value = existingAttention.atc_code
                    ? {
                          atcCode: existingAttention.atc_code,
                          description: existingAttention.atc_code_desc,
                      }
                    : null;
                comment.value = existingAttention.comment;
                selectedCategory.value = existingAttention.category;
                isEditMode.value = true;
            })
            .catch((error) => {
                console.error("Failed to fetch existing attention:", error);
            });
        journalStore.setEditItem(dataRowID.value, "9");
        isLoading.value = false;
    } else {
        //to prevent saving attention with empty data
        saveAttention();
    }
});
</script>
