<template>
    <h1 class="mx-auto text-center mb-4">{{ title }}</h1>
    <v-select
        :key="id"
        v-model="editId"
        class="col-6 mx-auto"
        style="margin-top: 50px"
        variant="outlined"
        density="compact"
        label="Smelltu hér til að sjá valmöguleika"
        :items="allSheetNames"
        item-title="name"
        item-value="id"
    />
    <div v-if="uploaded" class="my-4">
        <h3>Skjal: {{ uploaded.filename }}</h3>
        <!-- Sheet id -->
        <div class="my-4">
            <v-text-field
                v-model="kindOfSheet"
                label="Tegund skjals (id)"
                hide-details="auto"
                variant="outlined"
                density="compact"
            />
        </div>
        <div
            v-for="(item, index) in JSON.parse(uploaded.json)"
            :key="index"
            class="d-flex flex-column mx-auto justify-content-between"
            :class="determineBorder(item)"
        >
            <!-- opening tags -->
            <div
                v-if="'opening' in item"
                class="d-flex justify-content-center align-items-center"
            >
                <div class="mr-auto mb-5">{{ item.tag }}</div>
                <div class="d-flex flex-row ml-auto">
                    <div class="d-flex align-items-center ml-auto mt-2">
                        <v-row class="justify-center mr-5">
                            <v-checkbox
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).many
                                "
                            >
                                <template #label>
                                    <div class="mt-2">margir</div>
                                </template>
                            </v-checkbox>
                            <v-checkbox
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).hidden
                                "
                            >
                                <template #label>
                                    <div class="mt-2">falinn</div>
                                </template>
                            </v-checkbox>
                        </v-row>
                    </div>
                    <v-text-field
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .heading
                        "
                        variant="outlined"
                        density="compact"
                        placeholder="Flokkur"
                        class="ml-auto mt-1"
                        style="min-width: 200px; max-width: 200px"
                        @input="updateUserTitleChange(index, $event.target)"
                    />
                </div>
            </div>
            <!-- value tags -->
            <div
                v-if="'datatype' in item"
                class="d-flex justify-content-between"
            >
                <div class="mt-2">{{ item.tag }}</div>
                <div class="d-flex">
                    <v-select
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .datatype
                        "
                        label="Tegund"
                        :items="dataTypes"
                        variant="outlined"
                        density="compact"
                    />
                    <v-select
                        v-if="hasManyTrans(index)"
                        :key="index"
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .translation
                        "
                        class="swh"
                        variant="outlined"
                        density="compact"
                        label="Þýðingar"
                        :items="getTransArr(index)"
                    />
                    <v-text-field
                        v-if="hasManyTrans(index)"
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .translation
                        "
                        variant="outlined"
                        density="compact"
                        placeholder="Þýðing"
                        class="ml-auto mt-0 swh"
                    />
                    <v-text-field
                        v-else
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .translation
                        "
                        variant="outlined"
                        density="compact"
                        placeholder="Þýðing"
                        class="ml-auto mt-0 swh"
                    />

                    <v-select
                        :key="id"
                        v-model="
                            fieldExtras.find((value) => value.index === index)
                                .autofill
                        "
                        class="swh"
                        variant="outlined"
                        density="compact"
                        label="Sjálfvirkt"
                        :items="autoFields"
                        item-title="name"
                        item-value="name"
                    />
                    <div class="d-flex align-items-center mx-4 mt-2">
                        <v-row class="justify-center">
                            <!-- DISABLED -->
                            <v-checkbox
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).disabled
                                "
                            >
                                <template #label>
                                    <div class="mt-2">óvirkur</div>
                                </template>
                            </v-checkbox>
                            <!-- HIDDEN -->
                            <v-checkbox
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).hidden
                                "
                            >
                                <template #label>
                                    <div class="mt-2">falinn</div>
                                </template>
                            </v-checkbox>
                            <!-- HARDCODED -->
                            <v-checkbox
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).hardcoded
                                "
                            >
                                <template #label>
                                    <div class="mt-2">fast gildi</div>
                                </template>
                            </v-checkbox>
                            <div
                                v-if="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).hardcoded
                                "
                            >
                                <v-text-field
                                    v-model="
                                        fieldExtras.find(
                                            (value) => value.index === index,
                                        ).hardcodedValue
                                    "
                                    variant="outlined"
                                    density="compact"
                                    placeholder="Fast gildi"
                                    class="ml-2 mt-1"
                                    style="min-width: 200px; max-width: 200px"
                                    @input="
                                        updateHardCodedValue(
                                            index,
                                            $event.target,
                                        )
                                    "
                                />
                            </div>
                            <!-- MULTI LINE -->
                            <v-checkbox
                                v-if="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).datatype === 'string'
                                "
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).multiline
                                "
                            >
                                <template #label>
                                    <div class="mt-2">margar línur</div>
                                </template>
                            </v-checkbox>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button
                type="button"
                class="btn btn-success btn-lg mx-auto"
                @click="updateEntry"
            >
                Uppfæra
            </button>
        </div>
    </div>
</template>
<script>
import ElectronicSheetsAPI from "@src/api/electronicSheets";
import { useSnackbarStore } from "@stores/snackbarStore";

export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        allSheetNames: {
            type: Array,
            default: () => [],
        },
        autoFields: {
            type: Array,
            default: () => [],
        },
        dataTypes: {
            type: Array,
            default: () => [],
        },
        translations: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            editId: "",
            kindOfSheet: "",
            uploaded: false,
            fieldExtras: [],
            snackbarStore: useSnackbarStore(),
        };
    },
    watch: {
        editId() {
            this.fetchEntry();
        },
    },
    methods: {
        updateHardCodedValue(index, text) {
            this.fieldExtras[index].hardcodedValue = text.value.trim();
        },
        hasManyTrans(index) {
            const item = this.fieldExtras[index];
            return Object.keys(item).indexOf("translations") >= 0;
        },
        getTransArr(index) {
            return this.fieldExtras[index].translations;
        },
        updateUserTitleChange(index, text) {
            this.fieldExtras[index].heading = text.value.trim();
        },
        determineBorder(item) {
            return Object.keys(item).includes("closing") ? "" : "tableBorder";
        },
        getTranslation(tag) {
            let result = this.translations.filter((value) => value.tag === tag);
            if (result.length === 0) {
                return "";
            }
            result = result.map((value) => value.translation);
            return result.length === 1 ? result[0] : result;
        },
        fetchEntry() {
            ElectronicSheetsAPI.getSingleSheet(this.editId)
                .then((res) => {
                    const data = res.data.data[0];
                    this.uploaded = data;

                    this.kindOfSheet = this.uploaded.kind;

                    this.fieldExtras = [];
                    const json = JSON.parse(this.uploaded.json);
                    let index = 0;
                    for (let item of json) {
                        if ("opening" in item) {
                            this.fieldExtras.push({
                                heading: item.heading,
                                many: item.many,
                                hidden: item.hidden,
                                index: index,
                            });
                        } else if ("datatype" in item) {
                            const translation = this.getTranslation(
                                json[index].tag,
                            );
                            const manyTranslations = Array.isArray(translation);
                            let extra = {
                                index: index,
                                datatype: item.datatype,
                                tag: json[index].tag,
                                disabled: item.disabled,
                                hidden: item.hidden,
                                autofill: item.autofill,
                            };

                            if (item.datatype === "string") {
                                extra.multiline = item.multiline;
                            }

                            if (item.hardcoded) {
                                extra.hardcoded = true;
                                extra.hardcodedValue = item.hardcoded;
                            }

                            if (manyTranslations) {
                                extra.translation = item.translation
                                    ? item.translation
                                    : "";
                                extra.translations = translation;
                            } else {
                                extra.translation = translation;
                            }

                            this.fieldExtras.push(extra);
                        } else {
                            this.fieldExtras.push({ index: index });
                        }
                        index += 1;
                    }
                })
                .catch(() => {
                    this.snackbarStore.activate(
                        "Ekki gekk að sækja síðu",
                        "error",
                        2000,
                    );
                });
        },
        updateEntry() {
            if (this.kindOfSheet.length === 0) {
                this.snackbarStore.activate(
                    "Það vantar tegund skjals!",
                    "error",
                    2000,
                );
                return;
            }
            const sid = this.kindOfSheet.split(""),
                numbers = "1234567890".split("");
            const invalid = sid
                .map((value) => numbers.includes(value))
                .includes(false);

            if (invalid) {
                this.snackbarStore.activate(
                    "ID (kind) er ekki gilt",
                    "error",
                    2000,
                );
                return;
            }

            // kind id
            const id = this.uploaded.id;
            let json = JSON.parse(this.uploaded.json);
            const extras = this.fieldExtras;

            if (json.length != extras.length) {
                this.snackbarStore.activate("Villa í gögnum!", "error", 2000);
                return;
            }

            // removing original entries
            const extraFields = [
                "heading",
                "many",
                "count",
                "translation",
                "autofill",
                "hardcoded",
                "multiline",
                "disabled",
                "hidden",
            ];

            for (let entry of json) {
                extraFields.map((field) => delete entry[field]);
            }

            let index = 0;
            for (let entry of json) {
                // open tag heading
                if ("opening" in entry) {
                    if (extras[index].heading) {
                        entry.heading = extras[index].heading;
                    }
                    "many" in extras[index] && extras[index].many
                        ? (entry.many = true)
                        : "";
                    "many" in extras[index] && extras[index].many
                        ? (entry.count = 1)
                        : "";
                }
                // value tag type (default is string)
                if ("datatype" in entry) {
                    entry.datatype = extras[index].datatype;
                    if ("translation" in extras[index]) {
                        const translation = extras[index].translation.trim();
                        if (translation.length > 0) {
                            entry.translation = translation;
                        }
                    }
                    if ("autofill" in extras[index]) {
                        entry.autofill = extras[index].autofill;
                    }

                    const hardCoded =
                        "hardcoded" in extras[index] && extras[index].hardcoded;
                    if (
                        hardCoded &&
                        "hardcodedValue" in extras[index] &&
                        extras[index].hardcodedValue
                    ) {
                        entry.hardcoded = extras[index].hardcodedValue;
                    }

                    const multiLine =
                        "multiline" in extras[index] && extras[index].multiline;
                    if (multiLine && entry.datatype === "string") {
                        entry.multiline = true;
                    }
                }
                // only setting checked disabled fields
                "disabled" in extras[index] && extras[index].disabled
                    ? (entry.disabled = true)
                    : "";
                "hidden" in extras[index] && extras[index].hidden
                    ? (entry.hidden = true)
                    : "";
                index += 1;
            }

            const kindOfSheet = this.kindOfSheet;

            ElectronicSheetsAPI.updateElectronicSheet(id, {
                json: json,
                kind: kindOfSheet,
            })
                .then(() => {
                    this.snackbarStore.activate(
                        "Gögn voru uppfærð",
                        "success",
                        2000,
                    );
                    // window.location.reload();
                })
                .catch((e) => {
                    this.snackbarStore.activate(
                        "Ekki gekk að uppfæra gögn",
                        "error",
                        2000,
                    );
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    margin-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}
.tableBorder {
    padding: 10px;
    margin: 0;
    border: 1px solid rgb(226, 226, 226);
    width: 100%;
    max-height: 60px;
    overflow-x: visible;
}
.swh {
    width: 200px;
    height: 40px;
}
.around {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 10px 20px;
}
</style>
