<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <v-avatar
                :color="hoverColor"
                density="compact"
                class="mr-3"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
            >
                <v-icon
                    v-if="isHovered && journalData.type != '3'"
                    size="x-small"
                    @click="editAttention(journalData)"
                >
                    mdi-pencil
                </v-icon>
                <template v-else>
                    <i
                        v-if="journalData.type === '9'"
                        :class="getCategoryIcon(journalData.data.category)"
                    >
                    </i>
                    <i v-else class="fal fa-exclamation-triangle"></i>
                </template>
            </v-avatar>
            <div class="text-bold user-select-none" :class="fontClass">
                {{
                    journalData.type === "9"
                        ? getTranslatedCategory(journalData.data.category)
                        : $t("Warning")
                }}
            </div>
            <v-chip
                v-if="isDraft()"
                prepend-icon="mdi-progress-alert"
                size="small"
                class="text-0 user-select-none ml-3"
                color="warning"
            >
                {{ $t("journal.view.draft") }}
            </v-chip>
            <v-chip
                v-if="hasAtcCode()"
                class="ml-2 user-select-none"
                size="x-small"
                color="blue"
            >
                {{ journalData.data.atc_code }}
            </v-chip>
            <v-spacer></v-spacer>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode && !isEmpty()" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="text-bold mb-2">
                        {{ journalData.data.text }}
                    </div>
                    <!-- ATC CODE -->
                    <div v-if="hasAtcCode()">
                        <div class="text-bold">
                            {{ $t("journal.view.attention_atc_code") }}
                        </div>
                        <div class="text-normal mb-1">
                            {{ journalData.data.atc_code_desc }}
                        </div>
                    </div>
                    <!-- COMMENT -->
                    <div v-if="hasComment()">
                        <div class="text-bold">
                            {{ $t("journal.view.comment") }}
                        </div>
                        <div class="text-normal mb-1">
                            {{ journalData.data.comment }}
                        </div>
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            attentionItems: [],
            warningItems: [],
            hoverColor: "blue",
            hoverColorwarning: "red",
            isHovered: false,
            isAttentionEditorOpen: false,
            selectedDataRowId: null,
            selectedVersion: null,
            compactMode: false,
            categoryIcons: {
                A: "fal fa-person-dots-from-line",
                I: "fal fa-virus",
                B: "mdi mdi-water-alert",
                N: "fal fa-exclamation-circle",
                P: "fal fa-person-pregnant",
                L: "fal fa-person-breastfeeding",
                M: "mdi mdi-screw-round-top",
            },
        };
    },
    computed: {
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
        this.attentionItems = this.journalData;
        const type3Items = this.journalData;
        this.warningItems = type3Items;
    },
    methods: {
        isEmpty() {
            const data = this.journalData.data;
            const textEmpty =
                data.text === null || data.text.trim().length === 0;
            const atcEmpty =
                data.atc_code_desc === null ||
                data.atc_code_desc.trim().length === 0;
            const commentEmpty =
                data.comment === null || data.comment.trim().length === 0;

            if (textEmpty && atcEmpty && commentEmpty) {
                return true;
            }
            return false;
        },
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        hasAtcCode() {
            const t1 =
                this.journalData.data.atc_code &&
                this.journalData.data.atc_code.trim() != "";
            const t2 =
                this.journalData.data.atc_code_desc &&
                this.journalData.data.atc_code_desc.trim() != "";
            return t1 || t2;
        },
        hasComment() {
            const t1 = this.journalData.data.comment;
            if (t1 === null) {
                return false;
            }
            const t2 = this.journalData.data.comment.trim() != "";
            return t1 && t2;
        },
        handleMouseEnter() {
            this.isHovered = true;
            // warning is not editable
            if (this.journalData.type === "3") {
                return;
            }
            this.hoverColor = "grey";
            this.hoverColorwarning = "grey";
        },
        handleMouseLeave() {
            this.isHovered = false;
            this.hoverColor = "blue";
            this.hoverColorwarning = "red";
        },
        getCategoryIcon(category) {
            return (
                this.categoryIcons[category] || "fal fa-exclamation-triangle"
            );
        },
        editAttention(item) {
            const id = item.data.data_row_id;
            this.$emit("edit-item", { id: id, type: "9" });
        },
        getTranslatedCategory(category) {
            return this.$t(`attention.${category}`);
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
