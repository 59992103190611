<template>
    <div class="d-flex align-items-center owner">
        <v-icon class="mr-1 icon" size="x-small">mdi-doctor</v-icon>
        <div
            class="text-bold mr-4 fs owner user-select-none"
            :class="fontSizeClass"
        >
            {{ name }} {{ getTime() }}
        </div>
        <v-icon
            v-if="compact"
            size="x-small"
            class="text-black font-weight-light icon"
        >
            mdi-chevron-right
        </v-icon>
        <v-icon v-else size="x-small" class="text-black font-weight-light icon">
            mdi-chevron-down
        </v-icon>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: "",
        },
        compact: {
            type: Boolean,
            default: false,
        },
        fontSizeClass: {
            type: String,
            default: "",
        },
        created: {
            type: String,
            default: "",
        },
        useTime: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fontSize: "normal",
        };
    },
    methods: {
        getTime() {
            if (this.useTime) {
                const date = new Date(this.created);
                const hour = date.getHours();
                const min = date.getMinutes();
                return ` - ${hour}:${min}`;
            }
            return "";
        },
    },
};
</script>
