<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <div>
                    <v-avatar color="blue" density="compact" class="mr-3">
                        <v-icon size="x-small">mdi-email-newsletter</v-icon>
                    </v-avatar>
                </div>
                <div class="text-bold user-select-none" :class="fontClass">
                    {{ getHeaderTitle() }}
                </div>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="text-bold">
                        {{ getName() }}
                    </div>
                    <div class="text-normal">
                        {{ getDescription() }}
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            compactMode: false,
        };
    },
    computed: {
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
    },
    methods: {
        getName() {
            return this.journalData.data.name;
        },
        getDescription() {
            return this.journalData.data.description;
        },
        getHeaderTitle() {
            return this.journalData.data.heading;
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
