<template>
    <v-container class="mt-5 p-0 mx-0 mb-3">
        <v-card>
            <v-toolbar
                :class="{
                    'border-info bg-info': !exception,
                    'bg-warning text-white': exception,
                }"
            >
                <i
                    v-if="exception"
                    class="fa-thin fa-prescription-bottle fa-xl ml-4"
                />
                <i v-else class="fa-thin fa-tablets fa-xl ml-4" />
                <v-app-bar-title class="user-select-none">
                    {{
                        exception
                            ? $t("prescription.exemption_drug")
                            : $t("prescription.drug")
                    }}
                </v-app-bar-title>
                <transition name="fade-fast" mode="out-in">
                    <span
                        v-if="removable"
                        class="close pull-right text-white"
                        @click="onCloseClicked"
                    >
                        <v-btn icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </span>
                </transition>
            </v-toolbar>
            <v-container class="pb-0">
                <div class="card-body border-warning p-0">
                    <!-- Search -->
                    <div
                        class="d-flex flex-row justify-content-between flex-wrap"
                    >
                        <search
                            ref="select"
                            class="flex-grow-1 mr-2 mw-200"
                            :error="errors.medicine.value"
                            :tabindex="String(tabIndexStart + 1)"
                            @selected="onSelected"
                            @deselected="onDeSelected"
                        />
                        <div class="d-flex">
                            <button
                                type="button"
                                class="btn btn-outline-secondary text-nowrap mh-40 mr-2"
                                @click="onSynonymSearchClicked"
                            >
                                <i class="fal fa-search ws-normal" />
                                {{ $t("prescription.combination_drug") }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-info ml-auto mh-40 mw-40"
                                disabled
                                @click="onInfoClicked"
                            >
                                <i class="fal fa-info-circle ws-normal" />
                            </button>
                        </div>
                    </div>
                    <!-- Selected Medicine -->
                    <div
                        v-if="selected"
                        class="d-flex justify-content-between my-3"
                    >
                        <MedicineListItem
                            :medicine="selected"
                            class="d-flex align-items-center flex-grow-1 mr-2"
                        />
                        <check-box-button
                            v-model="r"
                            :tabindex="tabIndexStart + 2"
                        >
                            R
                        </check-box-button>
                    </div>
                    <div
                        class="d-flex justify-content-start my-auto flex-wrap pb-0 pt-2"
                    >
                        <div class="d-flex flex-grow-1 mr-2">
                            <!-- Dosage / Skömmtun -->
                            <v-text-field
                                v-model="shortDosage"
                                v-tooltip="
                                    $t(
                                        'prescription.content_will_not_appear_on_prescription',
                                    )
                                "
                                :label="$t('prescription.dosage')"
                                :tabindex="String(tabIndexStart + 3)"
                                :error="
                                    errors.short_dosage.value &&
                                    shortDosage.length > 20
                                "
                                variant="outlined"
                                density="compact"
                                class="mw-120"
                                @key.enter.prevent.stop=""
                                @change="onShortDosageChange()"
                            />
                            <span
                                v-if="shortDosage.length > 20"
                                :class="{ 'text-danger': errors.short_dosage }"
                            >
                                {{ $t("prescription.Max_length_of_dosage") }}
                                ({{ shortDosage.length }})
                            </span>
                        </div>
                        <div>
                            <!-- As needed / Eftir þörfum -->
                            <check-box-button
                                v-model="whenNecessary"
                                :tabindex="tabIndexStart + 4"
                                class="text-nowrap mr-2 mb-4"
                                @change="onWhenNecessary"
                            >
                                {{ $t("prescription.as_needed") }}
                            </check-box-button>
                        </div>
                        <div class="d-flex mw-200">
                            <!-- Number of packages / Fjöldi pakkninga -->
                            <v-number-input
                                v-model="numPackages"
                                required
                                variant="outlined"
                                control-variant="default"
                                density="compact"
                                :min="1"
                                :reverse="false"
                                :tabindex="String(tabIndexStart + 5)"
                                :hide-input="false"
                                :error="errors.number_of_packages.value"
                                @input="clearError('number_of_packages')"
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>
                                        {{ $t("prescription.no_of_packages") }}
                                    </span>
                                </template>
                            </v-number-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- Instruction / Notkunarfyrirmæli -->
                            <v-textarea
                                v-model="instructions"
                                :error="errors.instructions.value"
                                :tabindex="String(tabIndexStart + 6)"
                                rows="1"
                                auto-grow
                                variant="outlined"
                                density="compact"
                                required
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>
                                        {{
                                            $t(
                                                "prescription.usage_descriptions",
                                            )
                                        }}
                                    </span>
                                </template>
                            </v-textarea>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start flex-wrap">
                        <div class="flex-grow-1 mr-2 mw-180">
                            <!-- Used for / Lyfið er notað við -->
                            <v-text-field
                                v-model="indication"
                                :error="errors.indication.value"
                                required
                                :tabindex="String(tabIndexStart + 7)"
                                variant="outlined"
                                density="compact"
                                @input="clearError('indication')"
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>{{
                                        $t("prescription.drug_used_for")
                                    }}</span>
                                </template>
                            </v-text-field>
                        </div>
                        <div class="d-flex mr-2 mw-200">
                            <!-- Days between / Dagar milli skammta -->
                            <v-number-input
                                v-model="days_between_dispensations"
                                required
                                variant="outlined"
                                control-variant="default"
                                density="compact"
                                :min="1"
                                :tabindex="String(tabIndexStart + 8)"
                                :hide-input="false"
                                :error="errors.days_between_dispensations.value"
                                @update:value="
                                    days_between_dispensations = $event
                                "
                                @input="
                                    clearError('days_between_dispensations')
                                "
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>
                                        {{
                                            $t(
                                                "prescription.no_of_days_between_dose",
                                            )
                                        }}
                                    </span>
                                </template>
                            </v-number-input>
                        </div>
                        <div>
                            <!-- Box / Askja -->
                            <check-box-button
                                v-model="dosebox"
                                class="w-100 text-nowrap mb-4"
                                :tabindex="String(tabIndexStart + 9)"
                            >
                                {{ $t("prescription.dispensed") }}
                            </check-box-button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="flex-grow-1 mr-2 mw-200">
                            <!-- Max daily dose / Hamarks skammtur a dag -->
                            <v-text-field
                                v-show="medicineIsAddictive"
                                v-model="daily_max_dosage"
                                v-tooltip="
                                    $t('prescription.field_must_be_filled_out')
                                "
                                required
                                :tabindex="String(tabIndexStart + 13)"
                                :error="errors.daily_max_dosage.value"
                                variant="outlined"
                                density="compact"
                                @input="clearError('daily_max_dosage')"
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>
                                        {{ $t("prescription.max_daily_dose") }}
                                    </span>
                                </template>
                            </v-text-field>
                        </div>
                        <div v-if="medicineIsAddictive" class="mw-200">
                            <!-- Max dose unit / Hamarks skammtaeining -->
                            <v-text-field
                                v-show="medicineIsAddictive"
                                v-model="daily_max_dosage_unit"
                                required
                                :tabindex="String(tabIndexStart + 14)"
                                variant="outlined"
                                density="compact"
                            >
                                <template #label>
                                    <span class="mr-1 cr">*</span>
                                    <span>
                                        {{ $t("prescription.max_dose_unit") }}
                                    </span>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <!-- Comment / Athugasemd -->
                            <v-textarea
                                v-model="comment"
                                :label="
                                    $t('prescription.comment_for_pharmacist')
                                "
                                variant="outlined"
                                rows="1"
                                auto-grow
                                :tabindex="String(tabIndexStart + 10)"
                                density="compact"
                            />
                        </div>
                        <!-- Dates / Dagsetningar -->
                        <div
                            class="d-flex col-md-6 justify-content-between date-container"
                        >
                            <v-date-input
                                v-model="startDate"
                                :label="$t('prescription.takes_effect')"
                                :language="is"
                                format="d. MMM yyyy"
                                :max="endDate"
                                bootstrap-styling
                                variant="outlined"
                                prepend-icon=""
                                prepend-inner-icon=""
                                density="compact"
                                :tabindex="String(tabIndexStart + 11)"
                                @selected="updateDate"
                            />
                            <v-date-input
                                v-model="endDate"
                                :label="$t('prescription.valid_until')"
                                :language="is"
                                format="d. MMM yyyy"
                                :min="startDate"
                                bootstrap-styling
                                variant="outlined"
                                prepend-icon=""
                                prepend-inner-icon=""
                                density="compact"
                                :tabindex="String(tabIndexStart + 12)"
                            />
                        </div>
                    </div>
                    <div
                        v-if="exception"
                        class="card-body bg-warning mb-3"
                        style="border-radius: 5px"
                    >
                        <div class="row">
                            <div class="col-md-12">
                                <text-input-area
                                    v-model="argument"
                                    required
                                    :label="
                                        $t('prescription.reason_for_exemption')
                                    "
                                    :error="errors.arguments.value"
                                    :tabindex="String(tabIndexStart + 15)"
                                    @input="clearError('arguments')"
                                />
                                <check-box
                                    v-model="terms"
                                    required
                                    :error="errors.conditions.value"
                                    :value="terms"
                                    @input="clearError('conditions')"
                                >
                                    {{ $t("prescription.prescriber_of_drug") }}
                                </check-box>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </v-card>
        <!-- Medicine not found modal -->
        <simple-modal
            v-if="showMedicineNotFoundModal"
            size="large"
            @close="showMedicineNotFoundModal = false"
        >
            <template slot="header">Lyf fannst ekki</template>
            <p class="mb-2">
                Lyf fannst ekki í sérlyfjaskrá en það gæti verið til undir öðru
                auðkenni
            </p>
            <search
                ref="modalSearch"
                :error="errors.medicine"
                :tabindex="tabIndexStart + 1"
                @selected="onSelected"
                @deselected="onDeSelected"
            />
            <div class="form-group col-3 mx-auto">
                <button
                    class="btn btn-block btn-primary"
                    @click="showMedicineNotFoundModal = false"
                >
                    Ok
                </button>
            </div>
        </simple-modal>
    </v-container>
</template>

<script>
import {
    defineComponent,
    ref,
    computed,
    toRef,
    defineExpose,
    onMounted,
} from "vue";
import localService from "@api/local.js";
import Search from "./search";
import TextInputArea from "@components/textInputArea";
import CheckBox from "@components/checkBox";
import CheckBoxButton from "@components/checkBoxButton";
import ShortDosageParser from "@src/classes/shortDosageParser";
import SimpleModal from "@components/modalSimple";
import { VNumberInput } from "vuetify/labs/VNumberInput";

let now = new Date();

import MedicineListItem from "./search/medicineList/medicineListItem";

export default defineComponent({
    components: {
        Search,
        TextInputArea,
        CheckBox,
        CheckBoxButton,
        SimpleModal,
        VNumberInput,
        MedicineListItem,
    },
    props: {
        // eslint-disable-next-line
        preSelected: {
            type: Object,
            required: false,
        },
        removable: {
            type: Boolean,
            required: false,
            default: true,
        },
        forms: {
            type: Array,
            required: false,
            default: () => [],
        },
        expressions: {
            type: Array,
            required: false,
            default: () => [],
        },
        tabIndexStart: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ["close", "help"],
    setup(props, { emit }) {
        const selected = ref(undefined);
        const select = ref(null);
        const indication = ref("");
        const numPackages = ref(0);
        const shortDosage = ref("");
        const whenNecessary = ref(false);
        const dosebox = ref(false);
        const r = ref(false);
        const instructions = ref("");
        const argument = ref("");
        const comment = ref("");
        const terms = ref(false);
        const days_between_dispensations = ref(1);
        const daily_max_dosage = ref(null);
        const daily_max_dosage_unit = ref("");
        const showMedicineNotFoundModal = ref(false);
        const startDate = ref(now);
        const endDate = ref(now);
        const is = is;

        onMounted(() => {
            let date = new Date();
            date.setDate(date.getDate() + 364);
            endDate.value = date;
        });

        const errors = {
            medicine: ref(false),
            instructions: ref(false),
            number_of_packages: ref(false),
            conditions: ref(false),
            arguments: ref(false),
            short_dosage: ref(false),
            days_between_dispensations: ref(false),
            daily_max_dosage: ref(false),
            indication: ref(false),
        };

        const expressions = toRef(props, "expressions");

        const exception = computed(
            () =>
                selected.value !== undefined &&
                selected.value.exception === "1",
        );

        const nordic_id = computed(() =>
            selected.value !== undefined ? selected.value.nordic_id : "",
        );

        const updateDate = (date) => {
            endDate.value = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        };

        const requestData = computed(() => {
            let request = {
                medicine: nordic_id.value,
                r: r.value,
                short_dosage: shortDosage.value,
                number_of_packages: numPackages.value,
                when_necessary: whenNecessary.value, // as_needed
                dosebox: dosebox.value, // boolean dosebox
                instructions: instructions.value,
                comment: comment.value,
                indication: indication.value, // drug_used_for
                days_between_dispensations: days_between_dispensations.value, // no_of_days_between_dose
                daily_max_dosage: daily_max_dosage.value,
                daily_max_dosage_unit: daily_max_dosage_unit.value,

                start_date: startDate.value.toISOString().split("T")[0],
                end_date: endDate.value.toISOString().split("T")[0],

                // These were not defined in the props or data
                // iterations: props.iterations, // no clue what this is
                // interval: props.interval, // no clue what this is
            };

            if (exception.value) {
                request.exception = true;
                request.arguments = argument.value;
                request.conditions = terms.value;
            }

            return request;
        });

        const medicineIsAddictive = computed(
            () => selected.value !== undefined && selected.value.addictive != 0,
        );

        const prescribedAmount = computed(() => {
            if (selected.value.amount > 0 && numPackages.value > 0) {
                let amount = selected.value.amount * numPackages.value;
                return `${amount} ${selected.value.amount_unit}`;
            } else {
                return "0";
            }
        });

        const onCloseClicked = () => {
            emit("close");
        };

        const onShortDosageChange = () => {
            // Add error if short dosage is empty
            const parser = new ShortDosageParser(
                expressions.value,
                props.forms,
            );
            instructions.value = parser.parse(
                shortDosage.value,
                selected.value.form,
            );

            if (instructions.value.trim().length === 0) {
                clearError("instructions");
            }

            if (shortDosage.value.length <= 20) {
                clearError("short_dosage");
            }

            if (whenNecessary.value) {
                instructions.value =
                    instructions.value.toString() + " eftir þörfum";
            }
            // Make sure instructions are updated
            console.log(whenNecessary.value, "check__________");
        };

        const onSelected = (item) => {
            clearError("medicine");
            selected.value = item;
            numPackages.value = parseInt(item.number_of_packages);
            daily_max_dosage_unit.value = item.form;
        };

        const onDeSelected = () => {
            selected.value = undefined;
            numPackages.value = 0;
        };

        const onWhenNecessary = () => {
            whenNecessary.value = !whenNecessary.value; // Toggle the value of whenNecessary

            if (whenNecessary.value) {
                // Append "eftir þörfum" to instructions when the checkbox is checked
                instructions.value += " eftir þörfum";
            } else {
                // Remove "eftir þörfum" from instructions when the checkbox is unchecked
                instructions.value = instructions.value
                    .replace(/eftir þörfum/gi, "")
                    .trim();
            }
        };

        const onSynonymSearchClicked = (e) => {
            e.stopPropagation();
            select.value.synonymSearch();
        };

        const onInfoClicked = () => {
            if (selected.value !== undefined) {
                emit("help", selected.value.atc);
            } else {
                emit("help");
            }
        };

        const setError = (error) => {
            console.log("setting error to true: ", error);
            errors[error].value = true;
        };

        const clearError = (error) => {
            errors[error].value = false;
        };

        defineExpose({
            requestData,
            setError,
            clearError,
        });

        return {
            selected,
            indication,
            numPackages,
            shortDosage,
            whenNecessary,
            dosebox,
            r,
            instructions,
            argument,
            comment,
            terms,
            days_between_dispensations,
            daily_max_dosage,
            daily_max_dosage_unit,
            errors,
            exception,
            nordic_id,
            requestData,
            medicineIsAddictive,
            prescribedAmount,
            onCloseClicked,
            onShortDosageChange,
            onSelected,
            onDeSelected,
            onWhenNecessary,
            onSynonymSearchClicked,
            onInfoClicked,
            setError,
            clearError,
            select,
            updateDate,
            startDate,
            endDate,
            is,
            showMedicineNotFoundModal,
            hardcodedValue: "123",
        };
    },
    mounted() {
        if (this.preSelected) {
            const id = this.preSelected.medicine.nordic_id;
            localService
                .getMedicene(id)
                .then((response) => {
                    this.r = this.preSelected.r;
                    this.shortDosage = this.preSelected.short_dosage;
                    this.numPackages = parseInt(
                        this.preSelected.number_of_packages,
                    );
                    this.whenNecessary = this.preSelected.when_necessary;
                    this.dosebox = this.preSelected.dosebox;
                    this.instructions = this.preSelected.instruction;
                    // this.comment = this.preSelected.comment; // "Sent rafrænt úr eGátt. ([ ACTUAL COMMENT ],Skammtaaskja)" the comment needs to be stripped or fetch correct comment in the controller
                    // this.indication = this.indication;
                    this.days_between_dispensations =
                        this.preSelected.days_between_dispensations;
                    this.selected = response.data;

                    if (this.preSelected.exception) {
                        this.argument = this.preSelected.argument;
                        this.terms = this.preSelected.terms;
                    }

                    if (this.selected.addictive === "1") {
                        this.daily_max_dosage =
                            this.preSelected.daily_max_dosage;
                        this.daily_max_dosage_unit =
                            this.preSelected.daily_max_dosage_unit;
                    }
                })
                .catch(() => {
                    // trying to set what can be set
                    const keys = Object.keys(this.preSelected);
                    if (keys.indexOf("instruction") >= 0) {
                        this.instructions = this.preSelected.instruction;
                    }
                    if (keys.indexOf("number_of_packages") >= 0) {
                        this.numPackages = this.preSelected.number_of_packages;
                    }
                    if (keys.indexOf("days_between_dispensations") >= 0) {
                        this.days_between_dispensations =
                            this.preSelected.days_between_dispensations;
                    }
                    if (keys.indexOf("daily_max_dosage") >= 0) {
                        this.daily_max_dosage =
                            this.preSelected.daily_max_dosage;
                    }
                    if (keys.indexOf("daily_max_dosage_unit") >= 0) {
                        this.daily_max_dosage_unit =
                            this.preSelected.daily_max_dosage_unit;
                    }
                    if (keys.indexOf("exception") >= 0) {
                        this.exception = this.preSelected.exception;
                        if (keys.indexOf("argument") >= 0) {
                            this.argument = this.preSelected.argument;
                        }
                        if (keys.indexOf("terms") >= 0) {
                            this.terms = this.preSelected.terms;
                        }
                    }

                    // opening modal and injecting atc_code
                    this.showMedicineNotFoundModal = true;
                    if (keys.indexOf("atc_code") >= 0) {
                        const atc_code = this.preSelected.atc_code;
                        if (atc_code.length > 0) {
                            setTimeout(() => {
                                this.$refs.modalSearch.atcSearch(atc_code);
                            }, 100);
                        }
                    }
                });
        }
    },
});
</script>
<style scoped>
.cr {
    color: red;
}
.mh-40 {
    max-height: 40px;
}
.mw-40 {
    max-width: 40px;
}
.mw-120 {
    min-width: 120px;
}
.mw-180 {
    min-width: 180px;
}
.mw-200 {
    min-width: 200px;
}
.date-container {
    max-height: 40px;
    gap: 2rem;
}
</style>
