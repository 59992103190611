<template>
	<v-card flat>
		<v-toolbar color="transparent">
			<v-app-bar-nav-icon @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave()">
				<v-avatar :color="getHoverColor(index)">
					<v-icon v-if="hoveredItemIndex === index" @click="editDiagnose(journalData)">mdi-pencil</v-icon>
					<v-icon v-else :class="getAvatarIconClass(index)"></v-icon>
				</v-avatar>
			</v-app-bar-nav-icon>

			<v-toolbar-title>
				<strong>{{ journalData.data.code }}</strong> - {{ journalData.data.name }}
				<v-chip class="ml-3">{{ journalData.data.dia_list_name }}</v-chip>
			</v-toolbar-title>
			<v-chip prepend-icon="mdi-doctor" class="text-0" size="small">
				{{ journalData.user }}
			</v-chip>
		</v-toolbar>

		<confirmationModal v-model:isModalOpen="isModalOpen" @confirm="confirmEdit(journalData)" @cancel="cancelEdit()">
		</confirmationModal>

	</v-card>

</template>

<script>
import DiagnoseEditor from '../../journalEditor/diagnoseEditor/diagnoseEditor.vue';
import confirmationModal from '@components/journal/journalview/confirmationModal/confirmationModal.vue';
import { useJournalStore } from '@src/stores/journalStore';

export default {
	components: {
		DiagnoseEditor,
		confirmationModal
	},
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			isModalOpen: false,
			diagnoseItems: [],
			hoveredItemIndex: null,
			isDiagnoseEditorOpen: false,
			selectedDataRowId: null,
			selectedVersion: null,
		};
	},
	computed: {
	},
	mounted() {
		// this.diagnoseItems = this.journalData;
	},
	methods: {
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave() {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			return this.hoveredItemIndex === index ? '#EEEEEE' : 'teal-lighten-2';
		},
		getAvatarIconClass(index) {
			return this.hoveredItemIndex !== index ? 'fat fa-person-dots-from-line' : '';
		},
		editDiagnose(item) {


			console.log("journal data is", this.journalData);
			this.$emit('edit-diagnose-item', item);

			const journalStore = useJournalStore();
			// journalStore.setDiagnoseItem(item); 

			// currently temporaray dta is also being saved , need to modify logic 
			if (journalStore.editor.dataRowId === undefined && journalStore.editor.type === undefined) {
				this.setEditor(item); // Proceed directly if both are undefined
			} else {
				this.isModalOpen = true;

				console.log("editor vslue os", this.isModalOpen);
			}

			// const journalStore = useJournalStore();
			// journalStore.setDiagnoseItem(item); 

			// if (this.isDiagnoseEditorOpen && this.selectedDataRowId === item.data_row_id) {
			// 	this.closeDiagnoseEditor();
			// } else {
			// 	this.selectedDataRowId = item.data_row_id;
			// 	this.selectedVersion = item.version;
			// 	this.isDiagnoseEditorOpen = true;
			// }
		},
		confirmEdit(item) {

			this.$emit('edit-diagnose-item', item);

			const journalStore = useJournalStore();

			journalStore.setEditor({
				dataRowId: this.journalData.data.data_row_id,
				type: this.journalData.data.metadata.module_id,
				templateId: undefined,
			});

			console.log(" confirm edit case  --->", journalStore.editor);


			console.log("confirm editor --->", journalStore.editor);


			journalStore.showEditor = true; // Show the editor
			this.isModalOpen = false; // Close the modal

		},
		cancelEdit() {

			// Reset the editor properties
			const journalStore = useJournalStore();
			journalStore.resetEditor();

			this.isModalOpen = false; // Close the modal without making changes

		},
		setEditor(item) {
			const journalStore = useJournalStore();

			console.log('journal data', this.journalData);

			// Set editor properties
			journalStore.setEditor({
				dataRowId: this.journalData.data.data_row_id,
				type: this.journalData.data.metadata.module_id,
				version: this.journalData.data.version

			});

			console.log("set editor ", journalStore.editor);

			journalStore.showEditor = true; // Show the editor
		},



		// closeDiagnoseEditor() {
		// 	this.isDiagnoseEditorOpen = false;
		// 	this.selectedDataRowId = null;
		// 	this.selectedVersion = null;
		// },
		// diagnoseItem() {
		// 	const journalStore = useJournalStore();
		// 	return journalStore.getDiagnoseItem();
		// },

	}
};
</script>