import UserSettingsApi from "@src/api/userSettings";
import localApi from "@src/api/local";
import { defineStore } from "pinia";

export const useUserSettingStore = defineStore("userSetting", {
    state: () => ({
        allSettings: [],
        diagnosis_editor_default_dialist_id: "",
        api: localApi,
    }),
    getters: {
        getAllUserSettings() {
            return this.allSettings;
        },
    },
    actions: {
        initialize() {
            return new Promise((resolve, reject) => {
                UserSettingsApi.index()
                    .then(({ data }) => {
                        console.info("User settings initialized");
                        this.allSettings = data;
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getJournalFontSize() {
            const size = this.allSettings.find(
                (obj) => obj.slug === "journal_font_size",
            );
            if (size === undefined) {
                return -1;
            }
            return parseInt(size.value);
        },
        getDefaultUnit() {
            return this.allSettings.find(
                (obj) => obj.slug === "login_default_unit",
            ).value;
        },
        getDefaultJournalTypeId() {
            const setting = this.allSettings.find(
                (obj) => obj.slug === "default_journal_type_id",
            );

            return setting ? setting.value : null; // Or a default fallback value
        },
        getDefaultDialistId() {
            const setting = this.allSettings.find(
                (obj) => obj.slug === "diagnosis_editor_default_dia_list_id",
            );
            return setting ? setting.value : null;
        },
        getDefaultAutoLetterID() {
            const id = this.allSettings.find(
                (obj) => obj.slug === "autoletter_default_id",
            ).value;
            return id ? parseInt(id) : -1;
        },
        getJournalSettingsCompactSettings() {
            const days = this.allSettings.find(
                (obj) => obj.slug === "journal_days_compact_mode",
            );
            const notes = this.allSettings.find(
                (obj) => obj.slug === "journal_notes_compact_mode",
            );
            if (!days && !notes) {
                // do run UserSettingSeeder
                return { days: false, notes: false };
            }
            return { days: days.value === "1", notes: notes.value === "1" };
        },
        updateValue(slug, value) {
            const slugindex = this.allSettings.findIndex(
                (setting) => setting.slug === slug,
            );
            if (slugindex !== -1) {
                this.allSettings[slugindex].value = value;
            } else {
                // TODO fetch only the setting that was being updated
                // maybe return in update?
                this.initialize();
            }
        },
        slugExists(slug) {
            // Check if a slug exists in the settings
            return this.allSettings.some((setting) => setting.slug === slug);
        },
        getSettingBySlug(slug) {
            const requiredSlug = this.allSettings.find(
                (setting) => setting.slug === slug,
            );
            return requiredSlug ? requiredSlug.value : null;
        },
        async setSettingBySlug(slug, slugValue) {
            this.api
                .updateUserSetting(slug, slugValue.toString())
                .then(() => {
                    this.updateValue(slug, slugValue);
                })
                .catch((err) => console.log({ error: err }));
        },
    },
});
