import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i,
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    const languages = ["en", "is"];
    const keys = Object.keys(messages);
    for (let key of keys) {
        const lang = key.substring(0, 2);
        const check1 = languages.indexOf(lang) < 0;
        const check2 = languages.indexOf(key) >= 0;
        if (check1 || check2) {
            continue;
        }
        messages[lang][key.substring(3)] = messages[key][key.substring(3)];
        delete messages[key];
    }
    return messages;
}

const i18n = createI18n({
    legacy: false,
    locale: "en", // Temporary default locale
    fallbackLocale: "en",
    messages: loadLocaleMessages(),
});

export default i18n;
