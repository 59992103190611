<template>
    <v-container class="mt-5 p-0 px-2">
        <v-card>
            <v-toolbar>
                <v-menu :disabled="isLoading" transition="slide-y-transition">
                    <template #activator="{ props }">
                        <v-app-bar-nav-icon
                            v-tooltip:bottom="$t('journal.note-menu.title')"
                            v-bind="props"
                        >
                            <i
                                v-if="icpcMode"
                                class="fa-solid fa-hand-dots fa-lg"
                            />
                            <i
                                v-else
                                class="fa-solid fa-person-dots-from-line fa-lg"
                            />
                        </v-app-bar-nav-icon>
                    </template>
                </v-menu>
                <v-app-bar-title v-if="icpcMode">
                    {{ $t("diagnose.diagnosis_icpc") }}
                </v-app-bar-title>
                <v-app-bar-title v-else>
                    {{ $t("diagnose.diagnosis") }}
                </v-app-bar-title>
            </v-toolbar>
            <v-container>
                <h4>
                    {{ $t("diagnose.select_default_diagnose_type") }}
                </h4>
                <h6 class="mb-4">
                    ({{ $t("diagnose.default_can_be_changed") }})
                </h6>
                <v-row class="align-items-center mt-4">
                    <v-select
                        v-model="selected"
                        :label="$t('diagnose.select')"
                        :items="diaLists"
                        item-title="name"
                        item-value="id"
                        prepend-icon="mdi-shape-outline"
                    />
                    <v-btn
                        :disabled="!selected"
                        prepend-icon="mdi-floppy"
                        class="ml-4 mb-4 mr-4"
                        @click="submit()"
                    >
                        {{ $t("diagnose.save") }}
                    </v-btn>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>
<script>
export default {
    props: {
        icpcMode: {
            type: Boolean,
            default: false,
        },
        diaLists: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["setDefaultDialist"],
    data() {
        return {
            selected: "",
        };
    },
    watch: {
        diaLists() {
            if (!this.selected && this.diaLists.length > 0) {
                this.selected = this.diaLists[0].id;
            }
        },
    },
    mounted() {
        if (this.diaLists.length > 0) {
            this.selected = this.diaLists[0].id;
        }
    },
    methods: {
        submit() {
            const setDefault = true;
            this.$emit("setDefaultDialist", this.selected, setDefault);
        },
    },
};
</script>
