import axios from "axios";

export default class ApiBase {
    constructor(resourceUrl) {
        if (!resourceUrl) {
            this.resourceUrl = "";
        }
        this.resourceUrl = resourceUrl;
    }

    /**
     * Get request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    get(url, params) {
        return axios.get(url, { params: params || {} });
    }

    /**
     * Post request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    post(url, params) {
        return axios.post(url, params || {});
    }

    /**
     * Put request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    put(url, params) {
        return axios.put(url, params || {});
    }

    /**
     * Delete request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    delete(url, params) {
        return axios.delete(url, params);
    }

    /**
     * Patch request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    patch(url, params) {
        return axios.patch(url, params || {});
    }

    /**
     * Get a Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    getData(url, params) {
        return this.get(url, params).then((response) => response.data);
    }

    /**
     * Post a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    postData(url, params) {
        return this.post(url, params).then((res) => res.data);
    }

    /**
     * Put a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    putData(url, params) {
        return this.put(url, params).then((res) => res.data);
    }

    /**
     * Patch a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    patchData(url, params) {
        return this.patch(url, params).then((res) => res.data);
    }

    // ---------------------------------------------------------------------
    //     Resource methods
    // ---------------------------------------------------------------------

    /**
     * Index method to get all resources
     * @param {Object} params
     * @returns {Promise}
     */
    index(params) {
        return this.getData(`${this.resourceUrl}`, params);
    }

    /**
     * Show method to get a single resource
     * @param {Number} id
     * @param {Object} params
     * @returns {Promise}
     */
    show(id, params) {
        return this.getData(`${this.resourceUrl}/${id}`, params);
    }

    /**
     * Store method to create a new resource
     * @param {Object} params
     * @returns {Promise}
     */
    store(params) {
        return this.postData(`${this.resourceUrl}`, params);
    }

    /**
     * Update method to update a resource
     * @param {Number} id
     * @param {Object} params
     * @returns {Promise}
     */
    update(id, params) {
        return this.putData(`${this.resourceUrl}/${id}`, params);
    }

    /**
     * Destroy method to delete a resource
     * @param {Number} id
     * @returns {Promise}
     */
    destroy(id) {
        return this.delete(`${this.resourceUrl}/${id}`);
    }
}
