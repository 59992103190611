<template>
  <div class="journal">
    <div class="sidebar bg-light overflow-y-auto scroll" style="min-width:230px">
      <JournalSettings v-model="settings" @update-sort-order="handleSorting" @search-query="handleSearchQuery"
        @hide-notes="toggleNotesTypeFilter" @hide-diagnoses="toggleDiagnosesTypeFilter"
        @hide-measure="toggleMeasureTypeFilter" @hide-prescription="togglePrescriptionTypeFilter"
        @hide-vaccines="toggleVaccineTypeFilter" @hide-attentions="toggleAttentionTypeFilter"
        @hide-warning="toggleWarningTypeFilter" @hide-lab="togglelabResultTypeFilter" />
    </div>

    <div v-if="showJournalView" class="journal-view scroll">
      <JournalView :journalData="originalJournalData"
             :sortOrder="sortOrder"
             :IsEditorCollapsed="isEditorCollapsed"
             @show-editor="toggleEditor"
             @edit-diagnose-item="handleEditDiagnoseItem"
             @edit-attention-item="handleEditAttentionItem"
             @edit-note-item="handleEditNoteItem"
             @edit-lab-item="handleEditlabResultItem"
             @fetch-more-data="onFetchMoreData"
             @no-more-data="onNoMoreData"/>

    </div>

    <transition name="slide" class="vh-110" ref="top">
      <div class="journal-view-editor scroll" :style="{ width: isEditorCollapsed ? '0' : '50%' }"
        v-if="!isEditorCollapsed">
        <JournalEditor @close="closeEditor()" :isCollapsed="isEditorCollapsed" :indexValue="indexValue" />
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, watch, onBeforeUnmount } from 'vue';
import JournalSettings from './journalSetting/journalsetting.vue';
import JournalView from './journalview/journalview.vue';
import JournalEditor from './journalEditor/journalEditor.vue';


import { useStore } from 'vuex';
import journalApi from '@src/api/journalApi';

import journalAccessLogApi from '@src/api/journalAccessLog';

export default {
  components: {
    JournalSettings,
    JournalView,
    JournalEditor,
  },

  setup(_, { emit }) {
    const store = useStore();
    const settings = ref({});
    const journalData = ref([]);
    const dataChunkSize = 10;
    const currentChunkIndex = ref(0);
    const originalJournalData = ref([]);
    const storedJournalData = ref([]);
    const datesArray = ref([]);
    const datesLoaded = ref(0);
    const dateChunkSize = 10;
    const apiCount = ref(0);
    const selectedEntry = ref(null);
    const isEditorCollapsed = ref(true);
    const indexValue = ref(0)
    const isFirstClass = ref(true);
    const searchQuery = ref('');
    const hiddenItems = ref([]);
    const filterbyNote = ref(false);
    const filterbyDiagnose = ref(false);
    const filterbyMeasure = ref(false);
    const filterbyPrescription = ref(false);
    const filterbyVaccination = ref(false);
    const filterbyAttention = ref(false);
    const filterbyWarning = ref(false);
    const filterbylabResult = ref(false);
    const showJournalView = ref(true);
    const journalSettingsWidth = 230;
    const journalViewMinWidth = 400;
    const totalMinWidth = 630;


    const sortOrder = ref('asc');



    watch(filterbyNote, () => {
      JournalData();
    });
    watch(filterbyDiagnose, () => {
      JournalData();
    });
    watch(filterbyMeasure, () => {
      JournalData();
    });
    watch(filterbyPrescription, () => {
      JournalData();
    });
    watch(filterbyVaccination, () => {
      JournalData();
    });
    watch(filterbyAttention, () => {
      JournalData();
    });
    watch(filterbyWarning, () => {
      JournalData();
    });
    watch(filterbylabResult, () => {
      JournalData();
    });


    const currentPatientID = store.getters.currentPatientID;
    const currentUnitID = store.getters.currentUnitID;
    const currentJournalID = store.getters.currentJournalTypeID;

    const accessLogId = ref(null);
    const logEntryData = ref({});

    const logJournalAccess = async () => {
      try {
        const response = await journalAccessLogApi.store({
          patient_id: currentPatientID,
          unit_id: currentUnitID,
          journal_type_id: currentJournalID,
          access_date_time: new Date().toISOString(),
        });


        logEntryData.value = response.data;

        if (response.data && response.data.access_id) {
          accessLogId.value = response.data.access_id; // Store the log ID for fetching later
        } else {
          console.error('Access log ID not found in response');
        }

      } catch (error) {
        console.error('Failed to log journal access:', error);
      }
    };


    const updateJournalCloseLog = async () => {
      if (accessLogId.value && logEntryData.value) {
        try {
          await journalAccessLogApi.update(accessLogId.value, {
            ...logEntryData.value,
            close_date_time: new Date().toISOString(), // here only updating close time whenever moved away from journal page
          });
        } catch (error) {
          console.error('Failed to update journal close log:', error);
        }
      } else {
        console.log('Access log ID or log entry data not available for update.');
      }
    };

    const checkWidth = () => {
      const availableWidth = window.innerWidth;
      const journalViewWidth = availableWidth - journalSettingsWidth - (isEditorCollapsed.value ? 0 : (availableWidth * 0.5));

      showJournalView.value = journalViewWidth >= journalViewMinWidth && availableWidth >= totalMinWidth;
    };

    const fetchAllJournalData = async () => {
      try {
        const params = {
          patient_id: currentPatientID,
          unit_id: currentUnitID,
          journal_type_id: currentJournalID,
        };

        const response = await journalApi.fetchJournalEntries(params);
        const transformedData = transformData(response.data);
        originalJournalData.value = transformedData;

        loadNextChunk();
      } catch (error) {
        // console.error('Error fetching journal data:', error);
      }
    };
    const loadNextChunk = () => {
      const start = currentChunkIndex.value * dataChunkSize;
      const end = start + dataChunkSize;
      journalData.value.push(...originalJournalData.value.slice(start, end));
      currentChunkIndex.value++;
    };

    const onFetchMoreData = async () => {
      await loadNextDateChunk();
    };

    const transformData = (data) => {
      // Check if the data is an array
      if (!Array.isArray(data)) {
        console.error('Data is not an array:', data);
        return [];
      }

      // Transform the data and filter out null or undefined items
      return data.map(item => {
        // Check if item or its `data` or `metadata` is null/undefined
        if (!item || !item.data || !item.data.metadata) {
          console.warn('Invalid or missing data for an item:', item);
          return null;  // Filter out this entry
        }

        // Safely extract metadata properties and provide default values if they're missing
        return {
          date: item.data.metadata.data_date || 'N/A',
          user: item.data.metadata.owner_name || 'N/A',
          unit: item.data.metadata.unit_name || 'N/A',
          type: item.data.metadata.module_id || 'N/A',
          title: item.data.title || 'N/A',
          data: item.data,
        };
      }).filter(item => item !== null);  // Filter out null entries
    };


    const toggleNotesTypeFilter = () => {
      filterbyNote.value = !filterbyNote.value;
    };
    const toggleDiagnosesTypeFilter = () => {
      filterbyDiagnose.value = !filterbyDiagnose.value;
    };
    const toggleMeasureTypeFilter = () => {
      filterbyMeasure.value = !filterbyMeasure.value;
    };
    const togglePrescriptionTypeFilter = () => {
      filterbyPrescription.value = !filterbyPrescription.value;
    };
    const toggleVaccineTypeFilter = () => {
      filterbyVaccination.value = !filterbyVaccination.value;
    };
    const toggleAttentionTypeFilter = () => {
      filterbyAttention.value = !filterbyAttention.value;
    };
    const toggleWarningTypeFilter = () => {
      filterbyWarning.value = !filterbyWarning.value;
    };
    const togglelabResultTypeFilter = () => {
      filterbylabResult.value = !filterbylabResult.value;
    };

    const JournalData = async () => {
      try {


        const newParamsforDays = {
          unit_id: currentUnitID,
          journal_type_id: currentJournalID,
          patient_id: currentPatientID,
          modules: [2, 3, 4, 5, 7, 9, 13, 16, 18],
          direction: 'desc'
        };

        const ShowJournalDates = await journalApi.index(newParamsforDays);


        datesArray.value = ShowJournalDates.map(entry => entry.date);

        await loadNextDateChunk();

      } catch (error) {
        // console.error('Error fetching journal data:', error);
        return [];
      }
    };




    const loadNextDateChunk = async () => {
      if (datesLoaded.value < datesArray.value.length) {


        const datesToFetch = datesArray.value.slice(datesLoaded.value, datesLoaded.value + dateChunkSize);
        datesLoaded.value += dateChunkSize;

        const requestData = {
          patient_id: currentPatientID,
          unit_id: currentUnitID,
          journal_type_id: currentJournalID,
          modules: [2, 3, 5, 7, 9, 13, 16, 18],
          dates: datesToFetch,
          direction: 'desc'
        };

        const ShowJournalDataResponse = await journalApi.show(currentJournalID, requestData);
        const transformedData = transformData(ShowJournalDataResponse.data);
        originalJournalData.value.push(...transformedData);
        journalData.value.push(...transformedData);
        apiCount.value = apiCount.value + 1;
        if (Math.ceil(datesArray.value.length / 10) == apiCount.value) {

          //no more data left
          console.log('No more data left');
          emit('no-more-data');
        
        }
      }
    };


    onMounted(async () => {

      logJournalAccess(); 


      window.addEventListener('resize', checkWidth);
      checkWidth();

      await fetchAllJournalData();

      const data = await JournalData();

      if (Array.isArray(data)) {
        journalData.value = data;
      } else {
        // console.error('Journal data is not an array:', data);
      }
    });

    onBeforeUnmount(() => {

      updateJournalCloseLog(); // Update log with close time before unmounting

      window.removeEventListener('resize', checkWidth);
    });

    const handleSorting = (order) => {
      sortOrder.value = order;
    };

    const handleSearchQuery = (searchQuery) => {
      if (!searchQuery) {
        originalJournalData.value = [...storedJournalData.value];
      } else {
        const filteredData = storedJournalData.value.filter(item => {
          if (
            item.data &&
            (
              (item.data.title && item.data.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
              (item.data.name && item.data.name.toLowerCase().includes(searchQuery.toLowerCase()))
            )
          ) {
            return true;
          }
          return false;
        });

        originalJournalData.value = filteredData;
      }
    };

    const closeEditor = () => {
      isEditorCollapsed.value = 1;
    };

    const toggleEditor = () => {
      indexValue.value = 0;
      isEditorCollapsed.value = !isEditorCollapsed.value;

    };

    const toggleClasses = () => {
      isFirstClass.value = !isFirstClass.value;
    };

    const handleEditDiagnoseItem = (index) => {
      indexValue.value = 1;
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditAttentionItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditNoteItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    const handleEditlabResultItem = (item) => {
      isEditorCollapsed.value = !isEditorCollapsed.value;
    };

    return { settings, handleEditDiagnoseItem, handleEditAttentionItem, handleEditNoteItem, handleEditlabResultItem, journalData, selectedEntry,apiCount, isEditorCollapsed, indexValue, closeEditor, toggleEditor, toggleClasses, isFirstClass, originalJournalData, handleSearchQuery, handleSorting, hiddenItems, toggleNotesTypeFilter, toggleDiagnosesTypeFilter, toggleMeasureTypeFilter, togglePrescriptionTypeFilter, toggleVaccineTypeFilter, toggleAttentionTypeFilter, toggleWarningTypeFilter, togglelabResultTypeFilter, sortOrder, showJournalView, onFetchMoreData, logJournalAccess, updateJournalCloseLog };
  },

  computed: {
    visibleJournalData() {
      if (!searchQuery.value) {
        return this.originalJournalData.filter(item => !this.hiddenItems.includes(item.title));
      } else {
        return journalData.value.filter(item => !this.hiddenItems.includes(item.title));
      }
    },
  },


}
</script>

<style scoped>
.journal {
  display: flex;
  height: 92vh;
  width: 100%;
}

.sidebar {
  width: 30%;
  flex: 0.40;
  padding: 20px;
  overflow: auto;
}


.journal-view {
  flex: 2;
  overflow-y: auto;
}

.journal-view-editor {
  overflow-y: auto;
}


.scroll::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scroll::-webkit-scrollbar-track {
  background: #bdbdbd;
}

.scroll::-webkit-scrollbar-thumb {
  background: #8e8d8d;
  border-radius: 10px;
}
</style>