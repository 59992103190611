<template>
    <div class="container">
      <marquee>
        <h1 style="margin-top:200px">HELLO FROM SIGNAL DASHBOARD</h1>
      </marquee>
    <div style="margin-top:50px"></div>

    <h4>v-text-field</h4>
    <v-text-field v-model="tf" v-signal-auto-correct="tf" label="Test Autocomplete" class="editing-field" />
    <h4>v-text-area</h4>
    <v-textarea
      v-model="ta"
      v-signal-auto-correct="ta"
      rows="2"
      label="Test Autocomplete "
      outlined
    />



    </div>
</template>
<script>
export default {
  data() {
    return {
      tf: '',
      ta: ''
    }
  },

};
</script>
