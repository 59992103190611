<template>
    <v-list-item class="pb-0">
        <v-list-subheader>
            <div class="text-bold" :class="fontClass">{{ unitName }}</div>
        </v-list-subheader>
        <journal-component
            :journal-data="journalData"
            :font-class="fontClass"
            @edit-item="handleEditItem"
        />
    </v-list-item>
</template>
<script>
import JournalComponent from "@components/journal/journalview/journalComponent/journalComponent.vue";

export default {
    components: {
        JournalComponent,
    },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        fontClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        ownerName() {
            return this.journalData.owner_name;
        },
        unitName() {
            return this.journalData.unit_name;
        },
    },
    methods: {
        handleEditItem(info) {
            this.$emit("edit-item", info);
        },
    },
};
</script>
<style scoped>
.unit-name {
    font-size: 18px;
    margin-left: 0;
    color: black;
}
</style>
