const InvoiceCreate = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/create');
const SignalInvoicePrint = () => import(/* webpackChunkName: "group-invoice" */ '@components/invoice/print/signalprint');

import { GUARD_BILLING as access } from "@src/router/middleware/accessControl";

export default [
    {
        path: '/invoice/create', 
        component: InvoiceCreate, 
        name: 'invoice-create'
    },
    {
        path: '/invoice/:id/print',
        component: SignalInvoicePrint,
        name: 'invoice-print',
        props: true
    }
].map(route => ({ ...route, meta: { access }}))
