// Vuex store configuration
import { createApp } from "vue";
import { createStore } from "vuex";

// Import store properties
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import plugins from "./plugins";

// Import modules
import doctor from "./modules/doctor";
import session from "./modules/session";
import notification from "./modules/notification";

const app = createApp({});

// Register Vuex with Vue
const store = createStore({
    state() {
        return {
            user: {},
            unit: {},
            patient: {},
            journalType: {},
            availableUnits: [],
            print: false,
            client: false,
            debug: false,
            pos: {},
            signatureType: "topaz",
            lang: "is",
            journal: {
                settings: {},
                view: {},
                editor: {},
            },
        };
    },
    getters,
    mutations,
    actions,
    plugins,
    modules: {
        doctor,
        session,
        notification,
    },
});

export default store;
