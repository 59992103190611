<template>
    <div class="row mx-0 px-0">
        <div
            class="col-12 p-0 mx-0"
            @submit.prevent.stop=""
            @keydown.ctrl.83.prevent="onSend"
        >
            <div class="card mb-2 border-primary" style="display: none">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {{ $t("prescription.patient_name") }}
                                </label>
                                <div class="form-control non-form-info">
                                    {{ $store.state.patient.name }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t("invoice_create.ssn") }}</label>
                                <div class="form-control non-form-info">
                                    {{ $store.state.patient.codenumber || "" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>&nbsp;</label><br />
                                <!-- <button :disabled="$route.params.presc" class="btn btn-outline-secondary btn-block ws-normal"
                    @click="displayOldPrescriptions = true"> -->
                                <button
                                    :disabled="$route.params.presc"
                                    class="btn btn-outline-secondary btn-block ws-normal"
                                    @click.stop="
                                        openModel($store.state.patient.id)
                                    "
                                >
                                    <i class="fal fa-repeat fa-fw" />
                                    {{
                                        $t(
                                            "prescription.renew_older_prescription",
                                        )
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <transition-group name="slide-fade-fast">
                <template
                    v-for="(med_id, index) in medicinesList"
                    :key="med_id"
                >
                    <medicine
                        ref="medicines"
                        :removable="medicinesList.length > 1"
                        :forms="forms"
                        :expressions="expressions"
                        :tab-index-start="index * 9"
                        :pre-selected="onPreSelectedMedicine(index)"
                        @close="removeMedicine(med_id)"
                        @help="onMedicineHelp(atc)"
                    />
                </template>
            </transition-group>
            <div class="row">
                <div class="col-md-12">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-presc my-0"
                        @click="addMedicine"
                    >
                        <i class="fal fa-plus" />
                        {{ $t("prescription.add_another_drug") }}
                    </button>
                </div>
            </div>
            <pharmacy ref="pharmacy" />
            <div class="row">
                <div class="col-md-12">
                    <p
                        v-if="!currentPatientID"
                        class="text-warning text-center user-select-none"
                    >
                        {{ $t("patient.no_patient_selected") }}
                    </p>
                    <div class="d-flex ml-auto mb-2 justify-content-center">
                        <button
                            v-tooltip="'CTRL+S'"
                            type="button send-button"
                            class="btn btn-success btn-lg mr-2"
                            :tabindex="String(medicinesList.length * 9 + 3)"
                            :disabled="!currentPatientID"
                            @click.stop="onSend"
                        >
                            <i class="fal fa-paper-plane" />
                            {{ $t("prescription.send_prescription") }}
                        </button>
                        <button
                            v-tooltip="Loka"
                            type="button"
                            class="btn btn-outline-secondary btn-lg"
                            @click="$emit('close')"
                        >
                            <i class="fal fa-times"></i>
                            {{ $t("prescription.cancel") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-simple
            v-if="displayOldPrescriptions"
            size="large"
            @close="closeOldPrescriptionsModal"
        >
            <template #header>
                <i class="fal fa-repeat fa-fw" />
                {{ $t("prescription.renew_older_prescription") }}
            </template>
            <div v-if="loadingOldPrescriptions" class="text-center">
                <i class="fal fa-spinner fa-spin" />
                {{ $t("prescription.fetch_old_prescription") }}
            </div>
            <div
                v-if="!loadingOldPrescriptions && oldPrescriptions.length > 0"
                class="scroller"
            >
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{ $t("prescription.date") }}</th>
                            <th>{{ $t("prescription.drug_name") }}</th>
                            <th class="text-center">
                                {{ $t("prescription.no_of_packages") }}
                            </th>
                            <th class="text-center">
                                {{ $t("prescription.dispensations") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in oldPrescriptions"
                            :key="item.id"
                            class="clickable"
                            @click="onOldPrescriptionSelect(index)"
                        >
                            <td>{{ formatDate(item.date_created) }}</td>
                            <td>
                                {{ item.medicine.name }}
                                {{ item.medicine.strength }}
                                {{ item.medicine.form }}
                            </td>
                            <td class="text-center">
                                {{ item.number_of_packages }}
                            </td>
                            <td class="text-center">{{ item.iteration }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                v-if="!loadingOldPrescriptions && oldPrescriptions.length < 1"
                class="text-center"
            >
                Engir lyfseðlar fundust
            </div>

            <template #footer>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="closeOldPrescriptionsModal"
                >
                    <i class="fal fa-times"></i> {{ $t("prescription.close") }}
                </button>
            </template>
        </modal-simple>
    </div>
</template>

<script>
import { ref, watch, onMounted, getCurrentInstance } from "vue";
import { useStore, mapGetters } from "vuex";
import Medicine from "./medicine";
import Pharmacy from "./pharmacy";
import localService from "../../../api/local.js";
import ModalSimple from "../../modalSimple/";

export default {
    components: {
        Medicine,
        Pharmacy,
        ModalSimple,
    },
    props: {
        errors: {
            required: false,
            type: Object,
            default: () => ({}),
        },
    },
    emits: ["lookup", "send", "close"],
    setup(props, context) {
        const store = useStore();
        // const currentPatientID = ref(null);
        const currentPatientName = ref("");
        const currentPatientCodeNumber = ref("");
        const currentUnitID = ref(null);
        const id = ref(1);
        const medicinesList = ref([0]);
        const medicines = ref(null);
        const pharmacy = ref(null);
        const forms = ref({});
        const expressions = ref([]);
        const loadingOldPrescriptions = ref(false);
        const displayOldPrescriptions = ref(false);
        const oldPrescriptions = ref([]);
        const selectedOldPrescription = ref(-1);
        const $route = getCurrentInstance().proxy.$route;

        const addMedicine = () => {
            try {
                medicinesList.value.push(id.value++);
            } catch (error) {
                console.log(error);
            }
        };

        const onMedicineHelp = (atc) => {
            context.emit("lookup", lgnUrl(atc));
        };

        const lgnUrl = (atc) => {
            if (atc !== undefined) {
                return `https://www.serlyfjaskra.is/ShowResult.aspx?d=1&p=1&n=0&i=1&t=0&a=0&at=1&m=0&q=${atc}`;
            } else {
                return "https://www.serlyfjaskra.is/";
            }
        };

        const mapErrorsToSubComponents = () => {
            for (const key in props.errors) {
                if (key.includes("prescriptions")) {
                    const split = key.split(".");
                    if (split.length === 3) {
                        medicines.value[split[1]].setError(split[2]);
                    }
                } else if (key.includes("delivery")) {
                    const split = key.split(".");
                    if (split.length === 2) {
                        pharmacy.setError(split[1]);
                    }
                }
            }
        };

        const getMedicineForms = () => {
            localService
                .getMedicineForms()
                .then((res) => {
                    forms.value = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const getShortDosageExpressions = () => {
            localService
                .getShortDosageExpressions()
                .then((res) => {
                    expressions.value = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const removeMedicine = (id) => {
            const index = medicinesList.value.indexOf(id);
            if (index !== -1) {
                medicinesList.value.splice(index, 1);
            }
        };

        const openModel = (id) => {
            displayOldPrescriptions.value = true;
            // currentPatientID.value = id;
            // getOldPrescriptions();
        };

        const closeOldPrescriptionsModal = () => {
            displayOldPrescriptions.value = !displayOldPrescriptions.value;
        };

        const newPrescriptionRequestParams = () => {
            const pharmacyData = pharmacy.value.requestData;
            const prescriptions = medicines.value.map(
                (medicine) => medicine.requestData,
            );
            return {
                ...pharmacyData,
                prescriptions,
                patient_id: store.state.patient.id,
                unit_id: store.state.unit.id,
            };
        };

        const onSend = () => {
            context.emit("send", newPrescriptionRequestParams());
        };

        const onPreSelectedMedicine = (index) => {
            if ($route.params.presc && index === 0) {
                return $route.params.presc;
            }
            if (selectedOldPrescription.value >= 0 && index === 0) {
                return oldPrescriptions.value[selectedOldPrescription.value];
            }
            return null;
        };

        /*
        const getOldPrescriptions = () => {
            loadingOldPrescriptions.value = true;
            if (
                currentPatientID.value !== undefined &&
                currentPatientID.value !== null
            ) {
                localService
                    .getPatientPrescriptions(store.state.patient.id)
                    .then((response) => {
                        oldPrescriptions.value = response.data;
                    })
                    .catch((error) => {
                        // Handle error
                    })
                    .finally(() => {
                        loadingOldPrescriptions.value = false;
                    });
            }
        };
        */

        const onOldPrescriptionSelect = (index) => {
            selectedOldPrescription.value = index;
            medicinesList.value = [index];
            displayOldPrescriptions.value = false;
        };

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        watch(() => props.errors, mapErrorsToSubComponents);
        // watch(currentPatientID, getOldPrescriptions);
        onMounted(() => {
            getMedicineForms();
            getShortDosageExpressions();
            // getOldPrescriptions();
        });

        return {
            // currentPatientID,
            currentPatientName,
            currentPatientCodeNumber,
            currentUnitID,
            id,
            medicinesList,
            medicines,
            pharmacy,
            forms,
            expressions,
            loadingOldPrescriptions,
            displayOldPrescriptions,
            oldPrescriptions,
            selectedOldPrescription,
            addMedicine,
            onMedicineHelp,
            removeMedicine,
            onSend,
            onPreSelectedMedicine,
            onOldPrescriptionSelect,
            openModel,
            closeOldPrescriptionsModal,
            formatDate,
        };
    },
    computed: {
        ...mapGetters(["currentPatientID"]),
    },
};
</script>
<style scoped>
.send-button[disabled]:hover {
    cursor: default;
}
</style>
