<template>
    <v-dialog v-model="internalModalState" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Edit</span>
            </v-card-title>
            <v-card-text>
                Another item is being edited. Do you want to continue and lose unsaved data?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="handleConfirm">Continue</v-btn>
                <v-btn color="red darken-1" text @click="handleCancel">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isModalOpen: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            internalModalState: this.isModalOpen,
        };
    },
    watch: {
        isModalOpen(val) {
            this.internalModalState = val;
        },
        internalModalState(val) {
            this.$emit('update:isModalOpen', val);
        },
    },
    methods: {
        handleConfirm() {
            this.$emit('confirm'); // Emit confirm event
        },
        handleCancel() {
            this.$emit('cancel'); // Emit cancel event
        },
    },
};
</script>
