<template>
    <v-container class="mt-5 p-0 px-2">
        <v-card v-if="showEditor">
            <v-toolbar color="blue">
                <!-- MENU BUTTON & MENU -->
                <v-menu :disabled="isLoading" transition="slide-y-transition">
                    <template #activator="{ props }">
                        <v-app-bar-nav-icon
                            v-tooltip:bottom="$t('journal.note-menu.title')"
                            v-bind="props"
                            icon="mdi-file"
                            color="white"
                        />
                    </template>
                    <v-card class="mx-auto" max-width="300">
                        <v-list density="compact">
                            <v-list-subheader class="user-select-none">
                                {{ $t("journal.note-menu.title") }}
                            </v-list-subheader>
                            <v-list-item
                                v-for="(item, index) in menuOptions"
                                :key="index"
                                @click="menuButtonClicked(item.operation)"
                            >
                                <template #prepend>
                                    <v-icon
                                        color="primary"
                                        :icon="item.icon"
                                        class="mr-n4"
                                    />
                                </template>
                                <v-list-item-title>
                                    {{ $t(item.text) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
                <!-- TITLE -->
                <v-toolbar-title
                    v-if="!editTitle"
                    v-tooltip:bottom="msg"
                    class="title text-small user-select-none"
                >
                    {{ msg }}
                </v-toolbar-title>
                <v-text-field
                    v-else
                    ref="noteTiteInput"
                    v-model="msg"
                    density="compact"
                    :label="$t('journal.note-menu.change_title')"
                    variant="outlined"
                    append-icon="mdi-check"
                    hide-details
                    @blur="editTitle = false"
                    @keydown.enter="editTitle = false"
                    @click:append="editTitle = false"
                />
                <!-- MARKS AS DELETED - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.delete')"
                    :disabled="isLoading"
                    icon
                    @click="deleteNote()"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- SAVES AS NON DRAFT - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.save')"
                    :disabled="isLoading"
                    icon
                    @click="handleSaveNote(false)"
                >
                    <v-icon>mdi-floppy</v-icon>
                </v-btn>
                <!-- CLOSE FORM -->
                <!-- <v-btn -->
                <!--     v-if="!isNewEntry()" -->
                <!--     v-tooltip:bottom="$t('journal.note-menu.close')" -->
                <!--     :disabled="isLoading" -->
                <!--     icon -->
                <!--     @click="CloseNoteEditor(false)" -->
                <!-- > -->
                <!--     <v-icon>mdi-close</v-icon> -->
                <!-- </v-btn> -->
            </v-toolbar>
            <!-- FORM CONTENT -->
            <v-card-text class="px-0 my-0 py-0">
                <div
                    v-if="isLoading"
                    class="d-flex justify-content-start align-items-start col-12"
                >
                    <v-skeleton-loader
                        class="skeleton-loader mt-4"
                        :type="'heading, subtitle, '.repeat(10)"
                    />
                </div>
                <v-form
                    v-else
                    class="mb-0 pb-0"
                    :style="{ height: getFormHeight }"
                >
                    <v-container
                        class="overflow-y-scroll"
                        :style="{ height: getFormHeight }"
                    >
                        <div ref="form">
                            <component
                                :is="getComponentType(keyword)"
                                v-for="(keyword, index) in sortedKeywords"
                                :key="index"
                                :keyword-data="keyword"
                                @update:keyword-data="
                                    onKeywordDataUpdate(index, $event)
                                "
                            />
                        </div>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import {
    ref,
    onMounted,
    defineComponent,
    computed,
    onUnmounted,
    watch,
    nextTick,
} from "vue";
import DateValue from "@src/components/journal/journalEditor/noteEditor/DateValue/DateValue.vue";
import MultipleValues from "@src/components/journal/journalEditor/noteEditor/MultipleValues/MultipleValues.vue";
import SingleValue from "@src/components/journal/journalEditor/noteEditor/SingleValue/SingleValue.vue";
import Numeric from "@src/components/journal/journalEditor/noteEditor/Numeric/Numeric.vue";
import UnformattedText from "@src/components/journal/journalEditor/noteEditor/UnformattedText/UnformattedText.vue";
import RegExValue from "@src/components/journal/journalEditor/noteEditor/RegExValue/RegExValue.vue";
import FormattedText from "@src/components/journal/journalEditor/noteEditor/FormattedText/FormattedText.vue";
import Note from "@src/api/note";
import { useStore } from "vuex";
import { useJournalStore } from "@src/stores/journalStore";
import { useSnackbarStore } from "@stores/snackbarStore";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "noteEditor",
    components: {
        DateValue,
        MultipleValues,
        SingleValue,
        Numeric,
        UnformattedText,
        RegExValue,
        FormattedText,
    },
    props: {
        dataRowId: {
            type: String,
            default: null,
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const journalStore = useJournalStore();
        const snackbarStore = useSnackbarStore();
        const showEditor = ref(true);
        const msg = ref("Note");
        const editTitle = ref(false);
        const cbmKeywords = ref([]);
        const dataRowId = ref(props.dataRowId);
        const store = useStore();
        const patient_id = store.getters.currentPatientID;
        const journal_type_id = store.getters.currentJournalTypeID;
        const unit_id = store.getters.currentUnitID;
        const userDataID = store.getters.currentUserDataID;
        const autoSaveValue = ref(1);
        const snackbarTriggeredByButton = ref(false);
        const noteTiteInput = ref(null);
        const dataDate = ref(null);
        let isDraft = ref(true);
        let isLoading = ref(true);
        const { t } = useI18n();

        const handleSaveNote = (draft) => {
            autoSaveValue.value = 0;
            snackbarTriggeredByButton.value = true;
            isDraft.value = draft;
            saveNotes();
            handleClose();
        };

        // entry is opened from editor
        const isNewEntry = () => {
            return journalStore.editor.newEntry != undefined;
        };

        const deleteNote = () => {
            journalStore.setDeleteItem(dataRowId.value, "5");
        };

        let timer = null;

        const startIdleTimer = () => {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                saveNotes();
            }, 1000);
        };

        const CloseNoteEditor = (draft) => {
            isDraft.value = draft;
            handleClose();
        };

        const handleClose = () => {
            journalStore.resetEditor();
            showEditor.value = false;
            emit("close");
        };

        const saveNotes = async () => {
            const apiPayload = journalStore.editor.isFreeText
                ? createFreeTextPayload()
                : snackbarTriggeredByButton.value
                  ? createFilteredApiPayload()
                  : createApiPayload();

            const NotesData = {
                title: msg.value || "Note",
                version_update: 0,
                patient_id: patient_id,
                journal_type_id: journal_type_id,
                unit_id: unit_id,
                deleted: 0,
                sign_wanted: 0,
                private: 0,
                data_row_origin_id: 1,
                created_by: userDataID,
                owned_by: userDataID,
                data_date: dataDate.value,
                signed_by: null,
                signed_date: null,
                counter_signed_by: null,
                counter_signed_date: null,
                draft: isDraft.value,
                use_time: 0,
                cbm_keyword: apiPayload.cbm_keyword,
                autosave: autoSaveValue.value,
            };

            // for PMO to display correctly
            updateTags(apiPayload);

            try {
                if (!dataRowId.value) {
                    const response = await Note.store(NotesData);
                    dataRowId.value = response.DataRowID;
                    journalStore.setEditItem(dataRowId.value, "5");
                    await loadNoteData();
                } else {
                    await Note.update(dataRowId.value, NotesData);
                    if (snackbarTriggeredByButton.value) {
                        // "Note updated successfully!",
                        snackbarStore.activate(
                            t("journal.snackbar.updated"),
                            "success",
                            2000,
                        );
                        snackbarTriggeredByButton.value = false;
                    }
                }
            } catch (error) {
                console.error("Failed to save or update note:", error);
                snackbarStore.activate(
                    t("journal.snackbar.error"),
                    "error",
                    2000,
                );
            }
        };

        const formatDataDate = (date) => {
            let formatted = new Date(date);
            return formatted
                .toISOString()
                .replace("Z", "")
                .replace("T", " ")
                .split(".")[0];
        };

        const editTitleButtonPressed = () => {
            editTitle.value = true;
            nextTick(() => {
                noteTiteInput.value.focus();
            });
        };

        const loadNoteData = async () => {
            if (dataRowId.value) {
                try {
                    const response = await Note.show(dataRowId.value, {
                        data_row_id: dataRowId.value,
                    });
                    if (response.data && response.data[0]) {
                        isDraft.value = response.data[0].metadata.draft === "1";
                        const dataDateValue =
                            response.data[0].metadata.data_date;
                        dataDate.value = formatDataDate(dataDateValue);
                        msg.value = response.data[0].title;
                        cbmKeywords.value = response.data[0].cbm_keyword.map(
                            (keyword) => ({
                                ...keyword,
                                term_value:
                                    keyword.term_value || keyword.text || "",
                            }),
                        );
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                const templateId = localStorage.getItem("templateId");
                if (templateId) {
                    try {
                        const response = await fetch(
                            `/note/template/${templateId}`,
                        );
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        const result = await response.json();
                        msg.value = result.data.template_name;
                        cbmKeywords.value = result.data.template_terms;
                        saveNotes();
                    } catch (error) {
                        console.error("Error fetching template data:", error);
                    }
                }
            }
        };

        watch(
            [msg],
            () => {
                startIdleTimer();
            },
            { deep: true },
        );

        onUnmounted(() => {
            if (timer) {
                clearTimeout(timer);
            }
        });

        onMounted(() => {
            if (journalStore.editor.isFreeText) {
                cbmKeywords.value = journalStore.editor.cbm_keyword
                    ? [journalStore.editor.cbm_keyword]
                    : [];
                if (dataRowId) {
                    journalStore.setEditItem(dataRowId.value, "5");
                    isLoading.value = false;
                }
            } else {
                init();
            }
        });

        const init = async () => {
            isLoading.value = true;
            await loadNoteData();
            if (dataRowId) {
                journalStore.setEditItem(dataRowId.value, "5");
                isLoading.value = false;
            }
        };

        const sortedKeywords = computed(() => {
            return cbmKeywords.value
                .slice()
                .sort((a, b) => a.sort_order - b.sort_order);
        });

        const getComponentType = (keyword) => {
            if (dataRowId.value != null) {
                if (!keyword.keyword_valid) {
                    return "UnformattedText";
                } else {
                    switch (keyword.keyword_valid.input_type) {
                        case "N":
                            return "Numeric";
                        case "S":
                            return "SingleValue";
                        case "M":
                            return "MultipleValues";
                        case "R":
                            return "RegExValue";
                        case "D":
                            return "DateValue";
                        case "T":
                            return "FormattedText";
                        default:
                            return "UnformattedText";
                    }
                }
            } else {
                if (!keyword.input_type) {
                    return "UnformattedText";
                } else {
                    switch (keyword.input_type) {
                        case "N":
                            return "Numeric";
                        case "S":
                            return "SingleValue";
                        case "M":
                            return "MultipleValues";
                        case "R":
                            return "RegExValue";
                        case "D":
                            return "DateValue";
                        case "T":
                            return "FormattedText";
                        default:
                            return "UnformattedText";
                    }
                }
            }
        };

        // used in manual save
        const createFilteredApiPayload = () => {
            return {
                cbm_keyword: cbmKeywords.value
                    .filter(
                        (keyword) =>
                            keyword.term_value?.trim() ||
                            (keyword.text?.trim() && keyword.text != "<p></p>"),
                    )
                    .map((keyword) => ({
                        sort_order: keyword.sort_order,
                        parent_cbm_key_id: dataRowId.value
                            ? keyword.parent_cbm_key_id
                            : keyword.parent_tterm_id,
                        term_id: keyword.term_id,
                        term_name: keyword.term_name,
                        cbm_key_id: dataRowId.value
                            ? keyword.cbm_key_id
                            : keyword.tterm_id,
                        term_value: [
                            "UnformattedText",
                            "FormattedText",
                        ].includes(getComponentType(keyword))
                            ? ""
                            : keyword.term_value,
                        text: keyword.text,
                        input_type: getComponentType(keyword),
                    })),
            };
        };

        // used in auto save (please do comment!)
        const createApiPayload = () => {
            return {
                cbm_keyword: cbmKeywords.value.map((keyword) => ({
                    sort_order: keyword.sort_order,
                    parent_cbm_key_id: dataRowId.value
                        ? keyword.parent_cbm_key_id
                        : keyword.parent_tterm_id,
                    term_id: keyword.term_id,
                    term_name: keyword.term_name,
                    cbm_key_id: dataRowId.value
                        ? keyword.cbm_key_id
                        : keyword.tterm_id,
                    term_value: ["UnformattedText", "FormattedText"].includes(
                        getComponentType(keyword),
                    )
                        ? ""
                        : keyword.term_value,
                    text: keyword.text,
                    input_type: getComponentType(keyword),
                })),
            };
        };

        const updateTags = (apiPayload) => {
            // only formatted text is updated
            for (let item of apiPayload.cbm_keyword) {
                const formatted =
                    "input_type" in item && item.input_type === "FormattedText";
                if (!formatted) {
                    continue;
                }

                let text = item.text;

                if (!text) {
                    item.text = "";
                    continue;
                }

                text = text.replaceAll("<p>", "");
                text = text.replaceAll("</p>", "\n");
                text = text.replaceAll("<s>", "<strike>");
                text = text.replaceAll("</s>", "</strike>");
                text = text.replaceAll(
                    ' style="color: black; background-color: orange; font-style: normal;"',
                    "",
                );

                item.term_value = "";
                item.text = text;

                if (item.text.replace("\n").trim() === "") {
                    item.text = "";
                }
            }
        };

        const staticFreeTextData = ref([
            {
                sort_order: 0,
                parent_cbm_key_id: "",
                term_id: "",
                term_name: "",
                cbm_key_id: 0,
                term_value: "",
                text: "",
            },
        ]);

        const createFreeTextPayload = () => {
            return {
                cbm_keyword: staticFreeTextData.value.map((data) => ({
                    sort_order: data.sort_order,
                    parent_cbm_key_id: data.parent_cbm_key_id,
                    term_id: data.term_id,
                    term_name: data.term_name,
                    cbm_key_id: data.cbm_key_id,
                    term_value: data.term_value,
                    text: data.text,
                })),
            };
        };

        const onKeywordDataUpdate = (index, updatedData) => {
            cbmKeywords.value[index] = updatedData;
            const componentType = getComponentType(cbmKeywords.value[index]);
            if (
                componentType === "FormattedText" ||
                componentType === "UnformattedText"
            ) {
                staticFreeTextData.value[0].text = updatedData.text;
                staticFreeTextData.value[0].term_value = "";
                updatedData.term_value = "";
            } else {
                staticFreeTextData.value[0].term_value = updatedData.term_value;
            }
            startIdleTimer();
        };

        return {
            msg,
            editTitle,
            cbmKeywords,
            sortedKeywords,
            getComponentType,
            deleteNote,
            saveNotes,
            onKeywordDataUpdate,
            handleClose,
            showEditor,
            dataRowId, // same name as the prop
            handleSaveNote,
            snackbarTriggeredByButton,
            isLoading,
            editTitleButtonPressed,
            noteTiteInput,
            isDraft,
            isNewEntry,
            CloseNoteEditor,
            formatDataDate,
            t,
        };
    },

    data() {
        return {
            menuOptions: [
                {
                    text: "journal.note-menu.change_title",
                    operation: "change_title",
                    icon: "mdi-pencil",
                },
                {
                    text: "journal.note-menu.save_as_draft",
                    operation: "save_draft",
                    icon: "mdi-folder-download-outline",
                },
            ],
            formHeight: 0,
        };
    },
    computed: {
        getFormHeight() {
            return this.formHeight === 0
                ? "auto"
                : this.formHeight.toString() + "px!important";
        },
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            const form = this.$refs.form;
            if (!form) {
                // still loading
                setTimeout(() => {
                    this.handleResize();
                }, 500);
                return;
            }

            const totalHeight = window.screen.height;
            const contentHeight = form.getBoundingClientRect().height;
            const everythingAboveForm = 210;
            const available = totalHeight - everythingAboveForm;
            contentHeight > available
                ? (this.formHeight = available)
                : (this.formHeight = 0);
        },
        menuButtonClicked(type) {
            if (type === "change_title") {
                this.editTitleButtonPressed();
            }
            if (type === "save_draft") {
                const draft = true;
                this.handleSaveNote(draft);
            }
        },
    },
});
</script>
<style scoped>
.title {
    display: flex;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
