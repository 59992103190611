import ApiBase from "./apibase";

export default new (class Kiosk extends ApiBase {
    /**
     * Find patient by codenumber
     *
     * @param {String} codenumber
     * @returns {Promise}
     */
    getPatientByCodenumber(codenumber) {
        return this.getData(`patient/codenumber/${codenumber}`);
    }

    /**
     * Find patient by Id
     *
     * @param {Integer} id
     * @returns {Promise}
     */
    getPatientById(id) {
        return this.getData(`patient/${id}`);
    }

    /**
     * Get all patients that match query
     * Uses pagination (hence page parameter)
     * @param {String} query
     * @param {Boolean} codenumber
     * @param {String} page
     * @returns {Promise}
     */
    filterPatients(query) {
        return this.getData("patient/filter", query);
    }

    /**
     * Get recent patients from audit table
     * Uses pagination (hence page parameter)
     * @param {String} query
     * @param {Boolean} codenumber
     * @param {String} page
     * @returns {Promise}
     */
    getRecentPatients(count) {
        return this.getData("patient/recent");
    }

    /**
     * Get all patients that have a booking today
     * Uses pagination (hence page parameter)
     * @param {String} query
     * @param {Boolean} codenumber
     * @param {String} page
     * @returns {Promise}
     */
    getTodaysPatients() {
        return this.getData("patient/today");
    }
})();
