import ApiBase from "./apibase";

export default new (class Invoice extends ApiBase {
    /**
     * Get Invoice Details by ID
     *
     * @param {Number} invoice
     * @returns {Promise}
     */
    getInvoiceDetails(invoice) {
        return this.getData(`invoice/${invoice}`, { eager: 1 });
    }

    /**
     * Get Invoice PDF as base64 string
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getInvoicePDF(id) {
        return this.getData(`invoice/${id}/pdf`);
    }

    /**
     * Mark an invoice as printed
     *
     * @param {Number} id
     */
    setInvoicePrinted(id) {
        return this.patch(`invoice/${id}/print`);
    }

    /**
     * Mail an Invoice to Patient
     *
     * @param {Number} invoice_id
     * @param {String} email
     */
    mailPatientInvoice(invoice_id, email) {
        return this.postData(`mail/bill/${invoice_id}`, { email });
    }
})();
