<template>
    <div class="text-center pa-4">
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="1000">
            <v-card >
                <v-toolbar class="bg-primary">
                    <v-btn color="black" icon="mdi-close" @click="dialog = false">
                        <i class="fa-solid fa-x text-white"></i>
                    </v-btn>
                    <v-toolbar-title class="user-select-none">{{ $t('autoletter.modal.title') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items class="d-flex align-items-center">
                        <v-btn variant="text" @click="onSendClick()" class="justify-content-center align-items-center">
                            <i class="fa-light fa-paper-plane fa-xl"></i>
                            <h6 class="ml-3 my-auto">{{ $t('autoletter.modal.send') }}</h6>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list lines="two" class="d-flex flex-column p-4" subheader>
                    <div v-if="error" class="mx-auto alert alert-danger fitc">
                        <strong>{{ $t('autoletter.error') }}</strong> {{ error }}
                    </div>
                    <div class="d-flex flex-column col-12 col-md-8 mx-auto">
                        <div class="d-flex justify-content-center mx-auto mb-1 invalid-feedback" v-if="formErrors.addressEid" >
                            <small>{{ formErrors.addressEid }}</small>
                        </div>
                        <v-autocomplete
                            v-model="address"
                            :items="addresses.map(val => val.name)"
                            :label="$t('autoletter.modal.send_to')"
                            variant="solo"
                            prepend-icon=""
                            :no-data-text="$t('autoletter.modal.no_data_found')"
                            class="mb-0"
                            clearable
                        />
                        <div class="d-flex justify-content-center mx-auto mb-1 invalid-feedback" v-if="formErrors.receiverName" >
                            <small>{{ formErrors.receiverName }}</small>
                        </div>
                        <div class="d-flex justify-content-center mx-auto mb-1 invalid-feedback" v-if="formErrors.receiverId" >
                            <small>{{ formErrors.receiverId }}</small>
                        </div>

                        <div class="d-flex justify-content-between">
                            <v-text-field variant="solo" class="p-0" :label="$t('autoletter.modal.recipient')" v-model="receiverName" />
                            <v-text-field variant="solo" class="p-0" :label="$t('autoletter.modal.doctor_id')" v-model="receiverId" />
                        </div>
                        <v-textarea 
                            :label="$t('autoletter.modal.text_content')"
                            v-model="textContent"
                            v-signal-auto-correct="textContent"
                            variant="solo"
                        />  
                    </div>
                    <div v-if="doctorWarning" class="d-flex mx-auto mt-3 col-5 justify-content-center text-nowrap">
                        <div class="alert alert-warning mx-auto" style="user-select:none">
                            {{ $t('autoletter.modal.no_registered_doctor') }}
                        </div>
                    </div>
                    <!-- IPITEM MODE ER MEÐ LEGUR, STOFUNÓTUR OG GREININGAR -->
                    <!-- TMITEM MODE ER MEÐ STOFUNÓTUR OG GREININGAR -->
                    <!-- CASEBOOK MODE ER MEÐ STOFUNÓTUR OG GREININGAR -->
                    <!-- CASE MODE ER EKKI MEÐ NEITT -->
                    <div v-if="mode != 'case'">
                        <div class="col-12">
                            <div v-if="mode === 'ipitem'">
                                <h4>{{ $t('autoletter.modal.admissions') }}</h4>
                                <ul v-if="this.selected && this.selected.title">
                                    <li class="ml-3">{{ this.selected.title ? this.selected.title : '' }}</li>
                                    <li class="ml-3">Innskrift: {{ this.selected.inDate ? this.selected.inDate.format('d.m.Y') : ''  }}</li>
                                    <li class="ml-3">Útskrift: {{ this.selected.outDate ? this.selected.outDate.format('d.m.Y') : '' }}</li>
                                </ul>
                                <h6 v-else class="ml-4 font-weight-bold" >{{ $t('autoletter.modal.no_admissions') }}</h6>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h4>{{ $t('autoletter.modal.casebooks') }}</h4>
                                <i v-if="editMode" @click="editMode = false" class="fa-solid fa-pencil-slash fa-lg text-gray"></i>
                                <i v-else @click="editMode = true" class="fa-solid fa-pencil fa-lg text-gray"></i>
                            </div>
                            <div v-if="casebooks.length > 0">
                                <ul v-for="(casebook, index) in casebooks" :key="index">
                                    <li>
                                        <div class="d-flex col-12 justify-content-start align-items-center mb-2 mx-auto">
                                            <span v-if="editMode" @click="removeCasebook(index)" class="d-flex justify-content-center align-items-center circle mr-4 tc">
                                                <i class="fa-solid fa-xmark text-grey"></i>
                                            </span>  
                                            <span v-else class="d-flex justify-content-center align-items-center circle mr-4">
                                            </span>  
                                            <h5 class="my-auto">{{ casebook.title || 'Nóta' }}</h5>
                                            <hr>
                                        </div>
                                        <ul>
                                            <li class="ml-4" v-for="(keyword, i) in casebook.keywords" :key="i">
                                                <div class="d-flex">
                                                    <span class="circle tc mr-4"></span>  
                                                    <div class="d-flex flex-column">
                                                        <strong>{{ keyword.termName || 'Texti' }}</strong>
                                                        <div class="mb-3">{{ keyword.computedValue }}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <hr>
                                </ul>
                            </div>
                            <h6 v-else class="ml-4 font-weight-bold">{{ $t('autoletter.modal.no_casebooks') }}</h6>
                            <h4>{{ $t('autoletter.modal.diagnoses') }}</h4>
                            <div v-if="diagnoses.length > 0">
                                <ul>
                                    <li class="ml-4" v-for="(diagnosis, index) in diagnoses" :key="index">
                                        <div class="d-flex">
                                            <div v-if="editMode" class="d-flex mr-3 my-auto" @click="removeDiagnoses(index)">
                                                <i class="fa-solid fa-xmark text-grey"></i>
                                            </div>
                                            <div> 
                                                {{ diagnosis }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <h6 v-else class="ml-4 font-weight-bold">{{ $t('autoletter.modal.no_diagnoses') }}</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'case'">
                        <!-- NOTHING TO SEE HERE -->
                    </div>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
    components: {
        EditorContent,
    },
    watch: {
        dialog() {
            if(!this.dialog) {
                this.resetModal();
                this.$emit('closeModal');
                this.defaultDisabled = false;
            }
        },
        attendance() {
            if(this.defaultDisabled) {
                return;
            }
            const noAttendance = Object.keys(this.attendance).length === 0;
            if(!noAttendance) {
                this.textContent = this.generateContent();
            }
        },
        selected() {
            if(this.selected === null) {
                return;
            }

            const noSetting = Object.keys(this.setting).length === 0;
            if(noSetting) {
                this.textContent = this.generateContent();
                return;
            }

            this.editor = new Editor({
                content: this.setting,
                extensions: [StarterKit]
            });

            // parsing text using editor
            const text = this.editor.getText().replace(/\n\n/g,'\n');
            this.textContent = text;
            this.overwritePlaceholders();
            this.defaultDisabled = true; // prevent listener from overwriting
        },
    },
    props: {
        addresses: {
            required: false,
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            required: false,
            default: ''
        },
        selected: {
            type: Object,
            required: false,
            default: () => {}
        },
        casebooks: {
            type: Object,
            required: false,
            default: () => {}
        },
        attendance: {
            type: Object,
            required: false,
            default: () => {}
        },
        diagnoses: {
            type: Array,
            required: false,
            default: () => []
        },
        setting: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            dialog: false,
            address: '',
            receiverName: '',
            receiverId: '',
            textContent: '',
            error: null,
            formErrors: {},
            doctorWarning: false,

            defaultDisabled: false,
            editMode: false // for removing diagnoses, casebooks etc,
        };
    },
    computed: {
        addressOpts() {
            return _.uniqBy(this.addresses, 'name');
        }
    },
    methods: {
        generateContent() {
            if(!this.selected) return;
            const selected = this.selected;
            const dateIn = new Date(selected.inDate).format('d.m.Y');
            const dateOut = selected.dateoutDate ? new Date(selected.outDate).format('d.m.Y') : '';
            if(this.mode === 'casebook') {
                return `${selected.name} kt. ${selected.codenumber} stofunóta, dagsetning ${dateIn}`;
            }
            if (this.mode === 'tmitem') {
                return `${selected.name} kt. ${selected.codenumber}  -  ${dateIn}`;
            }
            if (this.mode === 'case') {
                return this.getCaseContent();
            }
            if (this.mode === 'ipitem') {
                return this.getIpItemContent();
            }
            return '';
        },
        getCaseContent() {
            const patientName = this.selected.name;
            const attendance = this.attendance;
            const caseName = attendance.caseName;
            const unitName = attendance.unitName ? attendance.unitName : 'göngudeild SÁÁ';
            const dateFrom = attendance.caseEnd ? Date.fromCarbonIE(attendance.caseEnd).format('d.M') : '';
            const dateTo = attendance.caseStart ? Date.fromCarbonIE(attendance.caseStart).format('d.M') : '';

            // percentage
            const timesAttended = attendance.countAttendance;
            const timesAbsent = attendance.countAbsent;
            const total = timesAttended + timesAbsent;
            const percentage = total === 0 ? 0 : Math.round((((timesAttended / total) * 100) + Number.EPSILON) * 100) / 100;

            return `${patientName} sótti þjónustu ${caseName} á ${unitName} dagana ${dateFrom} - ${dateTo} 
og mætti ${percentage}% (${timesAttended} sinnum af ${timesAttended + timesAbsent})`;
        },
        getIpItemContent() {
            const patientName = this.selected.name;
            const codenumber = this.selected.codenumber;

            const dateIn = this.selected.inDate ? this.selected.inDate.format('d.m.Y') : '';
            const dateOut = this.selected.outDate ? this.selected.outDate.format('d.m.Y') : '';

            let fixed = `${patientName} kt. ${codenumber} lagðist inn á Sjúkrahúsið Vogi frá ${dateIn}`;

            if (dateOut) {
                fixed = `${fixed} til ${dateOut}`;
            }

            return `${fixed} og hlaut þar meðferð`;
        },
        overwritePlaceholders() {
            const types = ['name', 'ssn', 'from', 'to', 'company'];
            for(let type of types) {
                const placeHolder = '<'+type+'>';
                while(this.textContent.indexOf(placeHolder) >= 0) {
                    const repValue = this.getPlaceholderValues(type);
                    this.textContent = this.textContent.replace(placeHolder, ' '+repValue+' ');
                }
            } 
        },
        getPlaceholderValues(type) {
            if(type === 'name') {
                return this.selected[type]
            }
            if(type === 'ssn') {
                return this.formatCodenumber(this.selected['codenumber']);
            }
            if(type === 'from') {
                const inDate = this.selected['inDate'] ? this.selected['inDate'] : ''; 
                if(inDate) {
                    return this.selected['inDate'] ? new Date(inDate).format('Y-m-d H:i') : '';
                }
                return inDate;
            }
            if(type === 'to') {
                const outDate = this.selected['outDate'] ? this.selected['outDate'] : ''; 
                if(outDate) {
                    return this.selected['outDate'] ? new Date(outDate).format('Y-m-d H:i') : '';
                }
                return outDate;
            }
            if(type === 'company') {
                if('title' in this.selected) {
                    return this.selected['title']
                }
                return '';
            }
        },
        formatCodenumber(cn) {
            if(cn.length === 10) {
                let str = cn.toString()
                let firstHalf = str.substring(0, 6);
                let secondHalf = str.substring(6);
                return firstHalf + "-" + secondHalf;
            }
            return cn;
        },
        resetModal() {
            this.address = '';
            this.receiverName = '';
            this.receiverId = '';
            this.textContent = '';
            this.error = null;
            this.formErrors = {};
            this.doctorWarning = false;
        },
        removeCasebook(index) {
            this.casebooks.splice(index, 1);
        },
        removeDiagnoses(index) {
            this.diagnoses.splice(index, 1);
        },
        setError(error, formErrors) {
            this.error = error;
            this.formErrors = formErrors ? formErrors : {};
        },
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        },
        setDoctorWarning() {
            this.doctorWarning = true;
        },
        setReceiver(id, name) {
            this.receiverId = id;
            this.receiverName = name;
        },
        onSendClick() {
            this.error = null;
            this.formErrors = {};

            const address = this.addressOpts.find(({ name }) => name == this.address);
            // front end validation
            if (address === undefined) {
                this.formErrors.addressEid = this.$t('autoletter.modal.error_send_to');
                return;
            }

            if (this.receiverName === '') {
                this.formErrors.receiverName = this.$t('autoletter.modal.error_recipient');
                return;
            }

            if (this.receiverId.length > 0) {
                let isInteger = !isNaN(parseInt(this.receiverId)) && Number.isInteger(parseFloat(this.receiverId));
                if(!isInteger) {
                    this.formErrors.receiverId = this.$t('autoletter.modal.error_doctor');
                    return;
                }
            }

            this.$emit('send', {
                address:        address,
                receiverName:   this.receiverName,
                receiverId:     this.receiverId,
                textContent:    this.textContent,
            });
        }
    }
};
</script>
<style scoped>
ul {
    list-style-type: none;
}
.fitc {
    width: fit-content;
}
.circle {
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    background-color: rgba(24,103,192, 0.5);
    border-radius: 50%;
    display: inline-block;
}
.fa-xmark:hover, .fa-pencil:hover, .fa-pencil-slash:hover {
    cursor: pointer
}
.tc {
    background-color: transparent;
}
hr {
    height: 1px;
    background-color: grey;
    border: none;
}
</style>
