export default {
    // Client getters
    client: (state) => {
        return state.client;
    },

    // Current user getters
    userExists: (state) => {
        return (
            Object.keys(state.user).length > 0 &&
            state.user.constructor === Object
        );
    },
    userDataExists: (state, getters) => {
        return getters.userExists && state.user.userdata instanceof Object;
    },
    userCodesExists: (state, getters) => {
        return getters.userExists && state.user.usercodes instanceof Object;
    },
    currentUserName: (state, getters) => {
        return getters.userDataExists ? state.user.userdata.name : undefined;
    },
    currentUserEmail: (state) => {
        return state.user.email;
    },
    currentUserID: (state) => {
        return state.user.id;
    },
    currentUserTitle: (state, getters) => {
        return getters.userDataExists ? state.user.userdata.title : undefined;
    },
    currentUserDataID: (state, getters) => {
        return getters.userDataExists ? state.user.userdata.id : undefined;
    },
    currentUserUsername: (state) => {
        return state.user.username;
    },
    currentUserRoles: (state) => {
        return state.user.roles || [];
    },
    currentUserCodenumber: (state, getters) => {
        return getters.userCodesExists
            ? state.user.usercodes.userCodenumber
            : undefined;
    },
    currentUserAccess: (state, getters) => {
        return getters.currentUserRoles.reduce((acc, role) => {
            role.features.forEach((feature) => (acc[feature.name] = true));
            return acc;
        }, {});
    },
    currentUserCanAccess: (state, getters) => (feature) => {
        return getters.currentUserRoles.some((r) =>
            r.features.some((f) => f.name === feature),
        );
    },

    // Current unit getters
    unitExists: (state) => {
        return (
            Object.keys(state.unit).length > 0 &&
            state.unit.constructor === Object
        );
    },
    availableUnits: (state) => {
        return state.availableUnits;
    },
    currentUnitID: (state) => {
        return state.unit.id;
    },
    currentUnitDepartment: (state) => {
        return state.unit.department;
    },
    currentUnitInstitute: (state) => {
        return state.unit.institute;
    },
    currentUnitAddress: (state) => {
        return state.unit.address;
    },
    currentUnitCodenumber: (state) => {
        return state.unit.codenumber;
    },
    // Current patient getters
    patientExists: (state) => {
        return (
            Object.keys(state.patient).length > 0 &&
            state.patient.constructor === Object
        );
    },
    patientAddressExists: (state, getters) => {
        return getters.patientExists && state.patient.address instanceof Object;
    },
    currentPatientID: (state) => {
        return state.patient.id;
    },
    currentPatientName: (state) => {
        return state.patient.name;
    },
    currentPatientCodenumber: (state) => {
        return state.patient.codenumber;
    },
    currentPatientAge: (state) => {
        return state.patient.age;
    },
    currentPatientAddressFull: (state, getters) => {
        return getters.patientAddressExists
            ? state.patient.address.full
            : "Heimilisfang er ekki skráð";
    },
    currentPatientAddress: (state, getters) => {
        return getters.patientAddressExists
            ? state.patient.address.address1
            : undefined;
    },
    currentPatientAddressZip: (state, getters) => {
        return getters.patientAddressExists
            ? state.patient.address.zip
            : undefined;
    },
    currentPatientAddressCity: (state, getters) => {
        return getters.patientAddressExists
            ? state.patient.address.city
            : undefined;
    },
    currentPatientSex: (state) => {
        return state.patient.sex ? state.patient.sex : undefined;
    },
    currentPatientBirthDate: (state) => {
        return state.patient.birth_date ? state.patient.birth_date : undefined;
    },
    currentPatientPhoneNumber: (state) => {
        return state.patient.phone_number
            ? state.patient.phone_number
            : undefined;
    },
    currentPatientWorkPhone: (state) => {
        return state.patient.work_phone ? state.patient.work_phone : undefined;
    },
    currentPatientPhoto: (state) => {
        return state.patient.photo ? state.patient.photo : undefined;
    },

    // Current Journal Type getters
    journalTypeExists: (state) => {
        return (
            Object.keys(state.journalType).length > 0 &&
            state.journalType.constructor === Object
        );
    },
    currentJournalTypeID: (state) => {
        return state.journalType.id;
    },
    currentJournalTypeName: (state) => {
        return state.journalType.name;
    },
    currentJournalType: (state) => {
        return state.journalType;
    },
    // Session Pos getters
    sessionPos: (state) => {
        return state.pos;
    },
    signatureType: (state) => {
        return state.signatureType;
    },
    currentTime() {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${hours}:${minutes}`;
    },
    currentDate() {
        const now = new Date();
        let day = now.getDate();
        let month = now.getMonth() + 1;
        let year = now.getFullYear();
        day = day < 10 ? `0${day}` : day;
        month = month < 10 ? `0${month}` : month;
        return `${day}.${month}.${year}`;
    },
    // includes both T and Z
    // e.g. 2025-03-14T12:03:03.726Z
    currentDateISO() {
        const now = new Date();
        return now.toISOString();
    },
};
