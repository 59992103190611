<template>
    <SelectDefaultDialist
        v-if="defaultDialistIdMissing"
        :icpc-mode="icpcMode"
        :dia-lists="diaLists"
        @set-default-dialist="updateSelectedDiaList"
    />
    <v-container v-else class="mt-5 p-0 px-2">
        <v-card>
            <v-toolbar>
                <!-- MENU BUTTON & MENU -->
                <v-menu :disabled="isLoading" transition="slide-y-transition">
                    <template #activator="{ props }">
                        <v-app-bar-nav-icon
                            v-tooltip:bottom="$t('journal.note-menu.title')"
                            v-bind="props"
                        >
                            <i
                                v-if="icpcMode"
                                class="fa-solid fa-hand-dots fa-lg"
                            />
                            <i
                                v-else
                                class="fa-solid fa-person-dots-from-line fa-lg"
                            />
                        </v-app-bar-nav-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="() => handleSaveDiagnosis(true)">
                            <template #prepend>
                                <v-icon
                                    color="primary"
                                    icon="mdi-folder-download-outline"
                                    class="mr-n4"
                                />
                            </template>
                            <v-list-item-title>
                                {{ $t("journal.note-menu.save_as_draft") }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="!icpcMode" link>
                            <v-list-item-title>
                                {{ $t("journal.note-menu.set_default") }}
                            </v-list-item-title>
                            <template #append>
                                <v-icon icon="mdi-menu-right" size="x-small" />
                            </template>
                            <template #prepend>
                                <v-icon
                                    color="primary"
                                    icon="mdi-star-outline"
                                    class="mr-n4"
                                />
                            </template>
                            <v-menu
                                :open-on-focus="false"
                                activator="parent"
                                open-on-hover
                                submenu
                            >
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in diaLists"
                                        :key="item.id || index"
                                        :active="selectedDiaListId === item.id"
                                        :active-class="'v-list-item--active'"
                                        @click="
                                            updateSelectedDiaList(item.id, true)
                                        "
                                    >
                                        <v-list-item-title>
                                            {{ item.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- TITLE -->
                <v-app-bar-title v-if="icpcMode">{{
                    $t("diagnose.diagnosis_icpc")
                }}</v-app-bar-title>
                <v-app-bar-title v-else>{{
                    $t("diagnose.diagnosis")
                }}</v-app-bar-title>
                <!-- MARKS AS DELETED - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.delete')"
                    :disabled="isLoading"
                    icon
                    @click="deleteDiagnosis()"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- SAVES AS NON DRAFT - CLOSES FORM -->
                <v-btn
                    v-tooltip:bottom="$t('journal.note-menu.save')"
                    :disabled="isLoading"
                    icon
                    @click="handleSaveDiagnosis(false)"
                >
                    <v-icon>mdi-floppy</v-icon>
                </v-btn>
                <!-- CLOSE FORM -->
                <!-- <v-btn -->
                <!--     v-if="!isNewEntry()" -->
                <!--     v-tooltip:bottom="$t('journal.note-menu.close')" -->
                <!--     :disabled="isLoading" -->
                <!--     icon -->
                <!--     @click="handleClose" -->
                <!-- > -->
                <!--     <v-icon>mdi-close</v-icon> -->
                <!-- </v-btn> -->
            </v-toolbar>
            <v-container>
                <div
                    v-if="isLoading"
                    class="d-flex justify-content-start align-items-start col-12"
                >
                    <v-skeleton-loader
                        class="skeleton-loader mt-4 col-12"
                        :type="'heading, subtitle, '.repeat(4)"
                    />
                </div>
                <div v-else>
                    <v-row v-if="showSearch">
                        <v-col>
                            <v-autocomplete
                                ref="autocomplete"
                                v-model="selectedItem"
                                autofocus
                                :label="$t('diagnose.search_diagnosis')"
                                density="compact"
                                :placeholder="$t('diagnose.search_diagnosis')"
                                :items="diaItems"
                                item-title="name"
                                item-id="dia_item_id"
                                item-dia_list_id="dia_list_id"
                                item-value="code"
                                no-filter
                                hide-no-data
                                @update:search="onSearch"
                            >
                                <template #item="{ item }">
                                    <v-list-item
                                        focus
                                        @click="OnClickItems(item)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-chip
                                                    color="orange"
                                                    density="compact"
                                                    size="small"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                {{
                                                    item.title.length > 71
                                                        ? item.title.slice(
                                                              0,
                                                              71,
                                                          ) + "..."
                                                        : item.title
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template #prepend>
                                    <v-menu>
                                        <template #activator="{ props }">
                                            <v-btn
                                                v-bind="props"
                                                variant="flat"
                                                append-icon="mdi-chevron-down"
                                                prepend-icon="mdi-shape-outline"
                                                size="small"
                                            >
                                                {{
                                                    selectedDiaListName ||
                                                    $t(
                                                        "diagnose.select_dialist",
                                                    )
                                                }}
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(
                                                    item, index
                                                ) in diaLists"
                                                :key="index"
                                                :value="index"
                                                @click="
                                                    updateSelectedDiaList(
                                                        item.id,
                                                        false,
                                                    )
                                                "
                                            >
                                                {{ item.name }}
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-if="!showSearch">
                        <v-col>
                            <v-form>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="diaCode"
                                            :label="$t('diagnose.code')"
                                        />
                                    </v-col>
                                    <v-col cols="9">
                                        <v-text-field
                                            v-model="diaName"
                                            :label="$t('diagnose.name')"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="diaComment"
                                            rows="2"
                                            :label="$t('diagnose.comment')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="selectedType"
                                            :label="
                                                $t(
                                                    'diagnose.select_diagnose_type',
                                                )
                                            "
                                            item-title="text"
                                            item-value="value"
                                            :items="[
                                                {
                                                    text: $t(
                                                        'diagnose.arrival',
                                                    ),
                                                    value: 'None',
                                                },
                                                {
                                                    text: $t(
                                                        'diagnose.chronic',
                                                    ),
                                                    value: 'Chronic',
                                                },
                                                {
                                                    text: $t('diagnose.main'),
                                                    value: 'Main',
                                                },
                                                {
                                                    text: $t(
                                                        'diagnose.secondary',
                                                    ),
                                                    value: 'Secondary',
                                                },
                                            ]"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col
                                        v-if="selectedType === 'Chronic'"
                                        cols="6"
                                    >
                                        <v-select
                                            v-model="selectedYear"
                                            :items="years"
                                            :label="$t('diagnose.since')"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-card>
    </v-container>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import dialist from "@src/api/dialist";
import diaItem from "@src/api/diaItem";
import diagnose from "@src/api/diagnose";
const store = useStore();
import { useStore } from "vuex";
import { useJournalStore } from "@src/stores/journalStore";
import { useSnackbarStore } from "@stores/snackbarStore";
import { useUserSettingStore } from "@src/stores/userSettingStore";
import SelectDefaultDialist from "./selectDefaultDialist.vue";
const journalStore = useJournalStore();
const snackbarStore = useSnackbarStore();
const userSettingStore = useUserSettingStore();
import { useI18n } from "vue-i18n";

const props = defineProps({
    dataRowId: {
        type: Number,
        default: null,
    },
    version: {
        type: Number,
        default: null,
    },
    icpcMode: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["close"]);

const isDraft = ref(true);
const showSearch = ref(true);
const diaLists = ref([]);
const selectedItem = ref("");
const selectedDiaListName = ref("");
const selectedDiaListId = ref("");
const diaItems = ref([]);
const diaName = ref("");
const diaCode = ref("");
const diaComment = ref("");
const diaItemId = ref("");
const diaListId = ref("");
const selectedType = ref("None");
const selectedYear = ref("");
const dataRowID = ref(props.dataRowId);
const autoSaveValue = ref(1);
const snackbarTriggeredByButton = ref(false);
const patient_id = store.getters.currentPatientID;
const journal_type_id = store.getters.currentJournalTypeID;
const unit_id = store.getters.currentUnitID;
const userDataID = store.getters.currentUserDataID;
const icpcMode = ref(props.icpcMode);
const isLoading = ref(true);
const dataDate = ref(null);
const mostUsed = ref({});

// used when default value needs to be set
const defaultDialistIdMissing = ref(false);
const { t } = useI18n();

let timer = null;

const startIdleTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        autoSaveValue.value = 1;
        saveDiagnosis();
    }, 5000);
};

// click from buttons
const handleSaveDiagnosis = (draft) => {
    autoSaveValue.value = 0;
    snackbarTriggeredByButton.value = true;
    isDraft.value = draft;
    saveDiagnosis();
    handleClose();
};

const handleClose = () => {
    closeAttentionEditor();
    emit("close"); // emitting the data to the parent component
};

const closeAttentionEditor = () => {
    journalStore.resetEditor();
};

const fetchDataWithParams = async (api, params) => {
    return await api.index(params);
};

// For fetching the Dialist.
const GetDiaListData = async () => {
    try {
        const [dialistData] = await Promise.all([
            fetchDataWithParams(dialist).catch((error) => {
                console.error("Error fetching dialist data:", error);
                return [];
            }),
        ]);
        diaLists.value = dialistData.data.map((item) => ({
            name: item.name,
            id: item.dia_list_id,
        }));

        getDefaultDialistId();
    } catch (error) {
        console.error("Error fetching data:", error);
        return { dialistData: [] };
    }
};

const setMostUsedItems = async () => {
    return diaItem
        .mostUsed()
        .then((res) => {
            const data = res.data.result;
            return data;
        })
        .catch(() => {
            return {};
        });
};

const setDiaItemsToMostUsed = (diaListId) => {
    diaListId = diaListId.toString();
    const keys = Object.keys(mostUsed.value);
    if (keys.includes(diaListId)) {
        diaItems.value = mostUsed.value[diaListId];
    }
};

const updateSelectedDiaList = async (diaListId, setDefault) => {
    selectedDiaListId.value = diaListId;

    setDiaItemsToMostUsed(diaListId);

    // only when setting default (no id present) or from button menu
    if (setDefault) {
        userSettingStore.setSettingBySlug(
            "diagnosis_editor_default_dia_list_id",
            diaListId,
        );
        defaultDialistIdMissing.value = false;
    }
};

let searchTimeout = null;

const onSearch = (search) => {
    search = search.trim();
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
        if (search.length === 0) {
            setDiaItemsToMostUsed(selectedDiaListId.value);
        } else {
            getDiaListItems(search);
        }
    }, 500);
};

// For fetching the Dia items when we select a Dia-list
const getDiaListItems = async (search) => {
    if (search.length >= 1) {
        try {
            const response = await diaItem.index({
                dia_list_id: selectedDiaListId.value,
                search: search,
            });
            diaItems.value = response.data;
        } catch (error) {
            console.error("Failed to fetch items:", error);
        }
    }
};

const OnClickItems = (item) => {
    showSearch.value = !showSearch.value;
    diaName.value = item.raw.name;
    diaCode.value = item.raw.code;
    diaItemId.value = item.raw.dia_item_id;
    diaListId.value = item.raw.dia_list_id;
    saveDiagnosis();
};

// For creating and editing diagnose.
const saveDiagnosis = async () => {
    const diagnoseData = {
        code: diaCode.value,
        name: diaName.value,
        chronic_dia: selectedType.value == "Chronic" ? "1" : "0",
        main_dia: selectedType.value == "Main" ? "1" : "0",
        sec_dia: selectedType.value == "Secondary" ? "1" : "0",
        comment: diaComment.value,
        chronic_year: selectedType.value == "Chronic" ? selectedYear.value : "",
        dia_list_id: diaListId.value,
        dia_item_id: diaItemId.value,
        patient_id: patient_id, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: dataDate.value,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: isDraft.value,
        use_time: 0,
        autosave: autoSaveValue.value,
    };

    try {
        if (!dataRowID.value) {
            const response = await diagnose.store(diagnoseData);
            dataRowID.value = response.DataRowID;
            // why set datarow inside this if?
            if (response.Code) {
                dataRowID.value = response.DataRowID;
            }
        } else {
            await diagnose.update(dataRowID.value, diagnoseData);
            if (snackbarTriggeredByButton.value) {
                // "Diagnose edited successfully !",
                snackbarStore.activate(
                    t("journal.snackbar.updated"),
                    "success",
                    2000,
                );
                snackbarTriggeredByButton.value = false;
            }
        }
    } catch (error) {
        console.error("Failed to save or update diagnose:", error);
        snackbarStore.activate(t("journal.snackbar.error"), "error", 2000);
    }
};

const formatDataDate = (date) => {
    let formatted = new Date(date);
    return formatted
        .toISOString()
        .replace("Z", "")
        .replace("T", " ")
        .split(".")[0];
};

const deleteDiagnosis = () => {
    journalStore.setDeleteItem(dataRowID.value, "2");
};

const ShowDiagnoseData = async () => {
    try {
        const response = await diagnose.show(journalStore.editor.dataRowId, {
            data_row_id: journalStore.editor.dataRowId,
            version: journalStore.editor.version,
        });

        const data = response.data[0];
        dataDate.value = formatDataDate(data.metadata.data_date);
        diaCode.value = data.code;
        diaName.value = data.name;
        diaComment.value = data.comment;
        selectedYear.value = data.chronic_year;
        selectedDiaListId.value = data.dia_list_id;
        diaListId.value = data.dia_list_id;
        diaItemId.value = data.dia_item_id;
        dataRowID.value = data.data_row_id;

        if (data.chronic_dia === "1") {
            selectedType.value = "Chronic";
        } else if (data.main_dia === "1") {
            selectedType.value = "Main";
        } else if (data.sec_dia === "1") {
            selectedType.value = "Secondary";
        }
    } catch (error) {
        console.error("Failed to fetch existing diagnose:", error);
    }
};

watch([diaName, diaCode, diaComment, selectedType, selectedYear], () => {
    startIdleTimer();
});

watch([selectedDiaListId], () => {
    const id = selectedDiaListId.value;
    if (id) {
        selectedDiaListName.value = diaLists.value.filter(
            (dl) => dl.id.toString() === id.toString(),
        )[0].name;
    }
});

// watching for a new diagnosis
watch(dataRowID, (newValue, oldValue) => {
    let newDiagnosis = oldValue === null && newValue > 0;
    if (newDiagnosis) {
        journalStore.setEditItem(newValue, "2");
        isLoading.value = false;
    }
});

// This function is for getting the default DiaListID.
const getDefaultDialistId = async () => {
    const slugValue = userSettingStore.getSettingBySlug(
        "diagnosis_editor_default_dia_list_id",
    );
    setDefaultDialistIdAndName(slugValue);
};

// This function is for setting the default DiaListID and Name in the select drop down.
const setDefaultDialistIdAndName = (slugValue) => {
    const defaultDialist = diaLists.value.find(
        (diaList) => diaList.id == slugValue,
    );
    if (defaultDialist == null) {
        return;
    }
    selectedDiaListId.value = defaultDialist.id;
};

const getICPCDialistID = () => {
    const target = "ICPC-2";
    let item = diaLists.value.filter((obj) => obj.name === target);
    if (item.length === 1) {
        return item[0].id;
    }
};

// entry is opened from editor
/*
const isNewEntry = () => {
    return journalStore.editor.newEntry != undefined;
};
*/

const init = async () => {
    await GetDiaListData();
    mostUsed.value = await setMostUsedItems();
    const defaultDialistId = userSettingStore.getDefaultDialistId();

    if (!defaultDialistId) {
        defaultDialistIdMissing.value = true;
    }

    // edit mode
    if (journalStore.editor.dataRowId != undefined) {
        icpcMode.value = false;
        dataRowID.value = journalStore.editor.dataRowId;
        journalStore.setEditItem(dataRowID.value, "2");
        isLoading.value = false;
        showSearch.value = !showSearch.value;
        //For populating Diagnose Form while opening it from Journal View
        ShowDiagnoseData();
        return;
    }

    // create mode
    // datarow has to be in place for
    // isLoading to be set to false
    // therefore save has to be performed when mounted

    const isIcpcMode = icpcMode.value;

    if (isIcpcMode) {
        const icpcId = getICPCDialistID();
        updateSelectedDiaList(icpcId, false);
        saveDiagnosis();
        return;
    }

    if (defaultDialistId) {
        updateSelectedDiaList(defaultDialistId, false);
        saveDiagnosis();
    }
};

onMounted(() => {
    init();
});

const years = ref(
    Array.from({ length: new Date().getFullYear() - 1900 }, (_, i) =>
        (new Date().getFullYear() - i).toString(),
    ),
);
</script>
