<template lang="html">
    <div v-if="active && isSignal">
        <div
            class="d-flex justify-content-center align-items-center egatt-loading-wheel-wrapper"
        >
            <v-progress-circular
                color="primary"
                indeterminate
                size="250"
                style="user-select: none"
            >
                Augnablik
            </v-progress-circular>
        </div>
    </div>
    <div v-if="active && !isSignal" class="egatt-loading-wheel-wrapper">
        <div class="egatt-loading-wheel">
            <i class="fas fa-spin fa-spinner fa-7x"></i> <br />
            <p>
                Augnablik <br />
                <small v-show="message">{{ message }}</small>
            </p>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    props: {
        message: { type: String, required: false, default: "" },
        active: { type: Boolean, required: true, default: false },
        isSignal: { type: Boolean, default: false },
    },
};
</script>
