<template>
    <div v-if="display" class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-start col-8">
            <div class="mr-2 pb-4 my-auto">
                {{ $t('autoletter.config.fetch_data') }}
            </div>
            <v-number-input
                controlVariant="default"
                label=""
                :hideInput="false"
                :inset="false"
                variant="solo"
                min-width="150px"
                max-width="150px"
                :reverse="false"
                v-model="number"
            ></v-number-input>
            <v-select
                class="mr-2"
                label=""
                variant="solo"
                v-model="selectedType"
                min-width="150px"
                max-width="150px"
                :items="dateTypes"
                item-title="name"
            ></v-select>
            <div class="mr-2 pb-4 my-auto">
                {{ $t('autoletter.config.in_past') }}
            </div>
        </div>
    </div>
</template>
<script>

import { VNumberInput } from 'vuetify/labs/VNumberInput'

export default {
    components: {
        VNumberInput
    },
    props: {
        dtype: {
            type: String,
            required: false,
            default: ''
        },
        display: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            number: 2,
            dateTypes: [],
            selectedType: ''
        };
    },
    mounted() {
        this.dateTypes = this.getDateTypes();
        this.selectedType = this.dateTypes[0].name;
    },
    watch: {
        number() {
            let isInteger = !isNaN(parseInt(this.number)) && Number.isInteger(parseFloat(this.number));
            if(!isInteger) {
                this.number = 1;
            }
            let n = Number(this.number);
            if(n < 1) {
                this.number = 1;
            }

            if(this.selectedType) {
                this.updateParent();
            }
        },
        selectedType() {
            this.updateParent();
        }
    },
    methods: {
        getDateTypes() {
            const d = this.$t('autoletter.config.date_types.day');
            const w = this.$t('autoletter.config.date_types.week');
            const m = this.$t('autoletter.config.date_types.month');
            const y = this.$t('autoletter.config.date_types.year');
            return [
                {"type":"D","name":d},
                {"type":"W","name":w},
                {"type":"M","name":m},
                {"type":"Y","name":y}
            ];
        },
        updateParent() {
            const dt = this.getDateTypes().filter(x => x.name === this.selectedType)[0];
            const dateItem = {
                "type": this.dtype,
                "number": this.number,
                "dateType": dt.type
            }
            this.$emit('updateDates', dateItem);
        }
    }
};
</script>
<style scoped>
.v-btn {
height: 56px;
}
</style>
