<template>
  <div  class="vh-100 col-8 mx-auto mt-4 pt-4 mb-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <v-text-field v-model="searchQuery" variant="underlined" :label="$t('journal.editor-menu.search')" clearable
        @click:clear="clearSearch" prepend-icon="mdi-magnify" hide-details></v-text-field>
    </div>
    <h6 class="mb-2 font-weight-bold favorites">Favorites</h6>
    <div class="d-flex flex-column">
      <div class="pointer-cursor px-0" v-for="editor in filteredEditors" :key="editor.type"
        @click="selectEditor(editor.type)">
        <div class="d-flex flex-row justify-content-between py-1">
          <div class="d-flex justify-content-start">
            <v-icon class="icon" v-if="editor.icon.startsWith('mdi')" :color="editor.color">{{ editor.icon }}</v-icon>
            <i v-else class="icon-strange" :class="editor.icon" :style="{ color: getColor(editor.color) }"></i>
            <span class="ml-2">{{ editor.name }}</span>
          </div>
          <i class="fa-regular fa-star favourite-icon ml-auto"></i>
        </div>
      </div>
    </div>
    <div 
      class="pointer-cursor px-0 ml-3 mb-3" v-for="item in cbmData" :key="item.template_id"
      @click="item.isFreeText ? openFreeTextNoteEditor() : navigateToNoteEditor(item.template_id)">
      <v-icon class="icon" color="primary">mdi-file</v-icon>
      <span class="ml-2">{{ item.template_name }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useJournalStore } from '@src/stores/journalStore';
import AttentionEditor from '@src/components/journal/journalEditor/attentionEditor/attentionEditor.vue';
import DiagnoseEditor from '@src/components/journal/journalEditor/diagnoseEditor/diagnoseEditor.vue';
import NoteEditor from '@src/components/journal/journalEditor/noteEditor/noteEditor.vue';
import labResultEditor from '@src/components/journal/journalEditor/labResultEditor/labResultEditor.vue';
import cbmtemplate from '@src/api/cbmtemplate';

export default {
  components: {
    AttentionEditor, DiagnoseEditor, NoteEditor, labResultEditor
  },
  data() {
    return {
      searchQuery: '',
      editors: [
        { type: '16', name: this.$t('journal.editor-menu.prescription'), icon: 'mdi-prescription', color: 'primary' },
        { type: '2', name: this.$t('journal.editor-menu.diagnose'), icon: 'fa fa-solid fa-person-dots-from-line', color: 'primary' },
        { type: '9', name: this.$t('journal.editor-menu.attention'), icon: 'mdi-alert-circle', color: 'primary' },
        { type: '4', name: this.$t('journal.editor-menu.lab'), icon: 'mdi-flask', color: 'primary' },
        { type: '5', name: this.$t('journal.editor-menu.note'), icon: 'mdi-file', color: 'primary' },
      ],
      cbmData: [
        { template_id: null, template_name: 'free text', isFreeText: true },
      ],
    };
  },
  computed: {
    ...mapState(useJournalStore, ['editor']),
    filteredEditors() {
      return this.editors.filter(editor =>
        editor.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      try {
        const response = await this.fetchDataWithParams(cbmtemplate);
        this.cbmData = [...this.cbmData, ...response.data]; // Append the fetched data to cbmData
      } catch (error) {
        console.error('Error initializing component:', error);
      }
    },

    async fetchDataWithParams(api, params = {}) {
      try {
        return await api.index(params);
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    },

    selectEditor(editorType) {
      if (editorType === '5') {
        return;
      }

      this.$emit('select-editor', editorType);
      const journalStore = useJournalStore();

      journalStore.setEditor({
        type: editorType,
      });
    },

    navigateToNoteEditor(templateId) {
      this.$emit('select-editor', '5');
      localStorage.setItem('templateId', templateId);

      const journalStore = useJournalStore();
      journalStore.setEditor({
        type: '5',
        templateId: templateId,
      });
    },

    openFreeTextNoteEditor() {
      this.$emit('select-editor', '5');
      const journalStore = useJournalStore();
      journalStore.setEditor({
        type: '5', // Note Editor type
        templateId: null, // No template for free text
        isFreeText: true, // Flag for free text mode
         cbm_keyword : "ahbkbkjb",
      });
    },

    clearSearch() { 
      this.searchQuery = '';
    },

    getColor(color) {
      const colorMap = {
        primary: '#1976d2',
        secondary: '#009688',
      };
      return colorMap[color] || color;
    },
  },
};
</script>

<style scoped>
.favorites {
  margin-top: 50px;
}

.pointer-cursor {
  cursor: pointer;
}

.icon {
  width: 25px;
}

/* because icon is different */
.icon-strange {
  width: 18px;
  margin: 5px 5px 0 3px;
}
</style>
