<template>
    <v-container class="p-0">
        <term-name v-model="termName" />
        <v-textarea
            v-model="textValue"
            v-signal-auto-correct="textValue"
            density="compact"
            variant="outlined"
            rows="1"
            auto-grow
            hide-details
            @update:model-value="onTextValueChange"
        />
    </v-container>
</template>

<script>
import TermName from "@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue";
import { defineComponent, ref, watch, onMounted } from "vue";

export default defineComponent({
    name: "UnformattedText",
    components: {
        TermName,
    },
    props: {
        keywordData: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:keywordData"],
    setup(props, { emit }) {
        const termName = ref("");
        const textValue = ref("");

        onMounted(() => {
            if (props.keywordData.text) {
                textValue.value = props.keywordData.text;
            }
        });

        watch(
            () => props.keywordData,
            (newVal) => {
                if (newVal) {
                    termName.value = newVal.term_name || termName.value;
                }
            },
            { immediate: true },
        );

        watch(termName, (newVal) => {
            emit("update:keywordData", {
                ...props.keywordData,
                term_name: newVal,
            });
        });

        const onTextValueChange = (newValue) => {
            emit("update:keywordData", {
                ...props.keywordData,
                text: newValue,
            });
        };

        return {
            termName,
            textValue,
            onTextValueChange,
        };
    },
});
</script>
