import axios from "axios";

// DO NOT ADD ANY NEW CODE TO THIS FILE!!!!
// DO NOT ADD ANY NEW CODE TO THIS FILE!!!!
// DO NOT ADD ANY NEW CODE TO THIS FILE!!!!
// DO NOT ADD ANY NEW CODE TO THIS FILE!!!!
// DO NOT ADD ANY NEW CODE TO THIS FILE!!!!

// TODO: We intend to break this file into smaller files

export default new (class LocalService {
    /**
     * Add an InvoiceAttachment to an Invoice
     *
     * @param {Number} id
     * @param {String} type
     * @param {String} attachment
     */
    addInvoiceAttachment(id, type, attachment) {
        return this.post(`invoice/${id}/attachments`, { type, attachment });
    }

    /**
     * Add a rate to MyRates
     *
     * @param {Number} id
     * @returns {Promise}
     */
    addMyRate(id) {
        return this.post("rate/my", { id: id });
    }

    /**
     *
     * @param {String} feature
     * @returns {Promise}
     */
    checkAuthUserAccess(feature) {
        return this.get(`auth/user/access/${feature}`);
    }

    /**
     * Download CashRegister excel
     *
     * @param {Number} id
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    downloadCashRegisterExcel(id, start, end) {
        return axios.get(`cr/${id}/excel`, {
            params: { start, end },
            responseType: "blob",
        });
    }

    /**
     * Download CashRegister pdf
     *
     * @param {Number} id
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    downloadCashRegisterPdf(id, start, end) {
        return axios.get(`cr/${id}/pdf`, {
            params: { start, end },
            responseType: "blob",
        });
    }

    /**
     * Get all the access level features
     *
     * @returns {Promise}
     */
    getAllFeatures() {
        return this.getData("feature");
    }

    /**
     * Get all the User Roles from DB
     *
     * @returns {Promise}
     */
    getAllRoles() {
        return this.getData("role");
    }

    /**
     * Get all available users
     *
     * @returns {Promise}
     */
    getAllUsers() {
        return this.getData("user/all");
    }

    /**
     * Get all available units
     *
     * @returns {Promise}
     */
    getAllUnits() {
        return this.getData("unit");
    }

    /**
     * Get authenticated user
     *
     * @returns {Promise}
     */
    getAuthUser() {
        return this.getData("user");
    }

    /**
     * Get Available Payment Types
     *
     * @returns {Promise}
     */
    getAvailablePaymentTypes() {
        return this.getData("invoice/types");
    }

    /**
     * Get available pharmacies
     *
     * @returns {Promise}
     */
    getAvailablePharmacies() {
        return this.getData("presc/pharmacies");
    }

    /**
     * Get Cash Register balance
     *
     * @param {Number} id
     * @param {String} from
     * @param {String} to
     * @returns {Promise}
     */
    getCashRegisterBalance(id, from, to) {
        return this.getData(`cr/date/${id}`, { from, to });
    }

    /**
     * Get Cash Register by ID
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getCashRegisterByID(id) {
        return this.getData(`cr/${id}`);
    }
    getCashRegisters() {
        return this.getData("cr");
    }
    /**
     * Get Cash Registers by Unit
     *
     * @param {Number} unit
     * @returns {Promise}
     */
    getCashRegistersByUnit(unit) {
        return this.getData(`cr/unit/${unit}`);
    }

    /**
     * Get Cash Registers by User
     *
     * @param {Number} user
     * @returns {Promise}
     */
    getCashRegistersByUser(user) {
        return this.getData(`cr/user/${user}`);
    }

    /**
     * Get Cash Registers By User And Unit
     *
     * @param {Number} user
     * @param {Number} unit
     * @returns {Promise}
     */
    getCashRegistersByUserAndUnit(user, unit) {
        return this.getData(`cr/user/${user}/unit/${unit}`);
    }

    /**
     * Get current patient
     *
     * @returns {Promise}
     */
    getCurrentPatient() {
        return this.getData("patient/current");
    }

    /**
     * Get current unit
     *
     * @returns {Promise}
     */
    getCurrentUnit() {
        return this.getData("unit/current");
    }

    /**
     * Get doctors by unit
     *
     * @param {Number} unit
     * @returns {Promise}
     */
    getDoctorsByUnit(unit) {
        if (unit) {
            return this.getData(`user/doctors?unit_id=${unit}`);
        } else {
            return this.getData("user/doctors");
        }
    }

    /**
     * Get Doctors Invoices issued today
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getDoctorsInvoicesToday(id) {
        return this.getData(`invoice/today/${id}`);
    }

    /**
     * Get Doctors recently issued prescriptions
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getDoctorsRecentPrescriptions(id) {
        return this.getData(`presc/recent/${id}`);
    }

    /**
     * Get Invoices by Cash Register
     *
     * @param {Number} cash_register_id
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    getInvoicesByCashRegister(cash_register_id, start, end) {
        return this.getData(`invoice/cr/${cash_register_id}`, { start, end });
    }

    getAllSettlementsByCashRegister(
        cash_register_id,
        doctors,
        start,
        end,
        include_o,
    ) {
        return this.getData(`invoice/sum/${cash_register_id}`, {
            doctors,
            start,
            end,
            include_o,
        });
    }

    /**
     * Get Invoices by Cash Register sped up Im working here
     *
     * @param {Number} cash_register_id
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    getInvoicesByCashRegisters(crs, start, end) {
        return this.getData(`invoice/cr`, { crs, start, end });
    }

    /**
     * Get all of a patients invoices
     * @param {int} id the patients id
     * @return {Promise}
     */
    getInvoicesByPatientId(id) {
        return this.getData(`invoice/patient/${id}`);
    }

    /**
     * Get session variable for is_client
     *
     * @returns {Promise}
     */
    getIsClient() {
        return this.getData("session/is_client");
    }

    /**
     * Get a single medicine
     *
     * @param {Number} nordic_id id of the medicine
     * @returns {Promise}
     */
    getMedicene(nordic_id) {
        return this.getData(`medicine/${nordic_id}`);
    }

    /**
     * Get a single medicine by ATC code
     *
     * @param {Number} atc ATC code for the medicine
     * @returns {Promise}
     */
    getMedicineByATC(atc) {
        return this.getData(`medicine/atc/${atc}`);
    }

    /**
     * Get Medicine forms table
     *
     * @returns {Promise}
     */
    getMedicineForms() {
        return this.getData("presc/forms");
    }

    /**
     * Get Rates by CashRegister and Type
     *
     * @param {Number} cr
     * @param {String} type
     * @returns {Promise}
     */
    getMyRatesByCashRegister(cr, type) {
        return this.get(`myrate/cr/${cr}?type=${type}`);
    }

    /**
     * Get Available Medical Data from Patient Index Hekla service
     *
     * @param {String} codenumber
     * @returns {Promise}
     */
    getPatientIndexInstituteData(codenumber) {
        return this.getData(`pindex/institutes/${codenumber}`);
    }

    /**
     * Get PatientIndex Measurement lookup.
     *
     * @returns {Promise}
     */
    getPatientIndexMeasurements() {
        return this.getData("pindex/measurements");
    }

    /**
     * Get get a patient's invoices
     *
     * @param {String} patient
     * @param {Bolean} eager
     * @returns {Promise}
     */
    getPatientInvoices(patient, eager) {
        return this.getData(`invoice/patient/${patient}?eager=${eager}`);
    }

    /**
     * Get a patient's prescriptions
     *
     * @param {Number} patient The Patient's id
     * @returns {Promise}
     */
    getPatientPrescriptions(patient) {
        return this.getData(`presc/patient/${patient}`);
    }

    /**
     * Get Patient Prescription Registry History from Hekla GW
     *
     * @param {String} codenumber
     * @returns {Promise}
     */
    getPrescriptionRegistryHistory(codenumber) {
        return this.getData(`prescreg/history/${codenumber}`);
    }

    /**
     * Gets a role by the id from DB.
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getRoleById(id) {
        return this.getData(`role/${id}`);
    }

    /**
     * Gets all Settings
     *
     * @returns {Promise}
     */
    getSettings() {
        return this.getData("setting");
    }

    /**
     * Get settings by category
     *
     * @param {String} category
     * @returns {Promise}
     */
    getSettingsByCategory(category) {
        return this.getData(`setting/${category}`);
    }

    /**
     * get settings value by settings ID
     */
    getSettingsValueBySlug(slug) {
        return this.getData(`setting/user/value/${slug}`);
    }

    /**
     * Get all settings for user (Pinia)
     */
    getAllSettings() {
        return this.getData(`setting/user/all`, { type: "user" });
    }

    /**
     * Get all autocorrections for user
     */
    getAllAutoCorrections() {
        return this.getData("autocorrections");
    }

    /**
     * Update default unit
     *
     * @param {String} slug
     * @param {String} value
     * @returns {Promise}
     */
    updateDefaultUnit(slug, value) {
        return this.post("setting/user/update", { slug, value });
    }

    /**
     * Update user setting
     *
     * @param {String} slug
     * @param {String} value
     * @returns {Promise}
     */
    updateUserSetting(slug, value) {
        return this.put("settings/user/update", { slug, value });
    }

    /**
     *  toggle mute settings
     */
    toggleBoolSetting(slug, value) {
        return this.patch(`setting/user/toggle`, { slug, value });
    }

    /**
     * Get prescription short dosage expressions
     *
     * @returns {Promise}
     */
    getShortDosageExpressions() {
        return this.getData("presc/exprs");
    }

    /**
     * Get all specialist catiegories.
     * @returns {Promise}
     */
    getSpecialistCategories() {
        return this.getData("specialist");
    }

    /**
     * Get a SpecialistCategory by id.
     * @param {Number} id
     * @returns {Promise}
     */
    getSpecialistCategory(id) {
        return this.getData(`specialist/${id}`);
    }

    /**
     * Get User Appointments
     *
     * @param {Number} user_id
     * @param {Number} unit_id
     * @param {String} from
     * @param {String} to
     * @returns {Promise}
     */
    getUserAppointments(user_id, unit_id, from, to) {
        return this.getData(`user/${user_id}/appointments`, {
            unit_id,
            from,
            to,
        });
    }

    /**
     * Get user data by user id
     *
     * @returns {Promise}
     */
    getUserData(userid) {
        return this.getData(`user/${userid}`);
    }

    /**
     * Get User invoices
     *
     * @param {Number} user
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    getUserInvoices(user, start, end) {
        return this.getData(`invoice/user/${user}`, { start, end });
    }

    /**
     * Get User invoices (light version)
     *
     * @param {Number} user
     * @param {String} start
     * @param {String} end
     * @returns {Promise}
     */
    getUserInvoicesLight(user, date) {
        return this.getData(`invoice/light/user/${user}`, { date });
    }

    /**
     * Get Users by Cash Register with invoices, filtered by invoice issue date
     *
     * @param {Number} cash_register_id
     * @param {String} from
     * @param {String} to
     * @returns {Promise}
     */
    getUsersByCashRegister(cash_register_id, from, to) {
        return this.getData(`user/cr/${cash_register_id}`, { from, to });
    }

    /**
     * Get User Units
     *
     * @param {Number} id
     * @returns {Promise}
     */
    getUserUnits(id) {
        return this.getData(`unit/user/${id}`);
    }

    /**
     * Get Authenticated User's prescriptions
     *
     * @returns {Promise}
     */
    getUsersPrescriptions() {
        return this.getData("svala");
    }

    /**
     * Post a log message
     *
     * @param {String} level emergency, error, critical, debug etc
     * @param {String} message
     * @returns {Promise}
     */
    log(level, message) {
        return this.post(`logs/${level}`, { message });
    }

    /**
     * Attempt EID login
     *
     * @param {String} username
     * @returns {Promise}
     */
    loginEid(username) {
        return this.postData("auth/eid", { username });
    }

    /**
     * Log out user
     *
     * @returns {Promise}
     */
    logout() {
        return this.get("auth/logout");
    }

    /**
     * Attempt password login
     *
     * @param {String} username
     * @param {String} password
     * @returns {Promise}
     */
    passwordLogin(username, password) {
        return this.postData("auth/login", { username, password });
    }

    /**
     * Peek for Prescription response
     *
     * @param {Number} id
     * @returns {Promise}
     */
    peekPrescriptionResponse(id) {
        return this.getData(`svala/peek/${id}`);
    }

    /**
     * Post new Invoice
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postInvoice(params) {
        return this.postData("invoice", params);
    }

    /**
     * Post a Request to mail CashRegister balance to doctors
     *
     * @param {Number} cr
     * @param {String} date
     * @returns {Promise}
     */
    postMailBalanceRequest(cr, date) {
        return this.postData(`mail/balance/${cr}`, { date });
    }

    /**
     * Send a MedicalDataRequest to Hekla GW
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postMedicalDataRequest(params) {
        return this.postData("pindex/data", params);
    }

    /**
     * Post a Prescription
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postPrescription(params) {
        return this.postData("lag", params);
    }

    /**
     * Post a new role to DB
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postRole(params) {
        return this.postData("role", params);
    }

    /**
     * Post new telemedicine data.
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postTelemedicine(params) {
        return this.postData("telemedicine", params);
    }

    /**
     * Send a TextViewRequest to Hekla GW
     *
     * @param {Object} params
     * @returns {Promise}
     */
    postTextViewRequest(params) {
        return this.post("pindex/textview", params);
    }

    /**
     * Update specified role in database
     *
     * @param {Number} id
     * @param {Object} params
     * @returns {Promise}
     */
    putRole(id, params) {
        return this.putData(`role/${id}`, params);
    }

    /**
     * Update telemedicine data.
     *
     * @param {Number} id
     * @param {Object} params
     * @returns {Promise}
     */
    putTelemedicine(id, params) {
        return this.putData(`telemedicine/${id}`, params);
    }

    /**
     * Send a delete request to resource controller.
     *
     * @param {Number} id
     * @returns {Promise}
     */
    removeCashRegister(id) {
        return this.post(`cr/${id}`, { _method: "delete" });
    }

    /**
     * Removes a rate from MyRates
     *
     * @param {Number} id
     * @returns {Promise}
     */
    removeMyRate(id) {
        return this.post(`rate/my/${id}`, { _method: "delete" });
    }

    /**
     * Removes a role from database
     *
     * @param {Number} id
     * @returns {Promise}
     */
    removeRole(id) {
        return this.post(`role/${id}`, { _method: "delete" });
    }

    /**
     * Search for Medicine
     *
     * @param {String} term
     * @returns {Promise}
     */
    searchMedicine(term) {
        return this.getData(`medicine/search?term=${term}`);
    }

    /**
     * Get next set of medicines using pagination link
     *
     * @param {String} link
     * @param {String} term
     * @returns {Promise}
     */
    searchNextMedicine(link, term) {
        return this.getData(`${link}&term=${term}`);
    }

    /**
     * Post invoice lines to be calculated by SÍ
     *
     * @param {object} params
     * @returns {Promise}
     */
    siCalculateLines(params) {
        return this.postData("si/calc", params);
    }

    /**
     * Get person's SÍ status
     *
     * @param {String} codenumber
     */
    siStatus(codenumber) {
        return this.getData(`si/status/${codenumber}`);
    }

    /**
     * Store a cashregister in DB
     *
     * @param {Object} params
     * @returns {Promise}
     */
    storeCashRegister(params) {
        return this.postData("cr", params);
    }

    /**
     * Switch unit in Session
     *
     * @param {Number} unit
     * @returns {Promise}
     */
    switchUnit(unit) {
        return this.get(`unit/switch/${unit}`);
    }

    /**
     * Update authenticated user's email
     *
     * @param {String} email
     * @returns {Promise}
     */
    updateAuthUserEmail(email) {
        return this.putData("user", { email });
    }

    /**
     * Update a cashregister with the specified id
     *
     * @param {Integer} id
     * @param {Object} params
     * @returns {Promise}
     */
    updateCashRegister(id, params) {
        return this.putData(`cr/${id}`, params);
    }

    /**
     * Patch a CashRegister appointment fee setting with the specified ID
     *
     * @param {Number} id
     * @param {Boolean} appointment_fee
     * @param {String} appointment_fee_rate_id
     */
    patchCashRegisterAppointmentFee(
        id,
        appointment_fee,
        appointment_fee_rate_id,
    ) {
        if (appointment_fee) {
            return this.patch(`cr/${id}/appointmentFee`, {
                appointment_fee,
                appointment_fee_rate_id,
            });
        } else {
            return this.patch(`cr/${id}/appointmentFee`, { appointment_fee });
        }
    }

    /**
     * Update Invoice Payment Type
     *
     * @param {Number} invoice_id
     * @param {Number} payment_type_id
     * @returns {Promise}
     */
    updateInvoicePaymentType(invoice_id, payment_type_id) {
        return this.putData(`invoice/${invoice_id}`, { payment_type_id });
    }

    /**
     * Get a Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    getData(url, params) {
        return this.get(url, params).then((response) => response.data);
    }

    /**
     * Post a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    postData(url, params) {
        return this.post(url, params).then((res) => res.data);
    }

    /**
     * Put a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    putData(url, params) {
        return this.put(url, params).then((res) => res.data);
    }

    /**
     * Patch a request and get Collection response normalized
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    patchData(url, params) {
        return this.patch(url, params).then((res) => res.data);
    }

    /**
     * Get request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    get(url, params) {
        return axios.get(url, { params: params || {} });
    }

    /**
     * Post request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    post(url, params) {
        return axios.post(url, params || {});
    }

    /**
     * Put request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    put(url, params) {
        return axios.put(url, params || {});
    }

    /**
     * Delete request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    delete(url, params) {
        return axios.delete(url, params);
    }

    /**
     * Patch request to local service
     *
     * @param {String} url
     * @param {Object} params
     * @returns {Promise}
     */
    patch(url, params) {
        return axios.patch(url, params || {});
    }
})();
