<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <!-- read only for now -->
                <!-- <div @mouseover="hovered = true" @mouseleave="hovered = false"> -->
                <v-avatar
                    :color="getAvatarColor(journalData.data.lab.Priority)"
                    density="compact"
                    class="mr-3"
                >
                    <!-- read only for now -->
                    <!-- <v-icon @click="editLabResult(journalData.data)" size="small"> -->
                    <v-icon size="x-small">{{
                        hovered ? "mdi-pencil" : "mdi-microscope"
                    }}</v-icon>
                </v-avatar>
                <div class="text-bold" :class="fontClass">
                    {{ $t("journal.editor.labresult.lab_result") }}
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
                <v-chip
                    v-if="hasStatus()"
                    size="x-small"
                    color="orange"
                    class="ml-2"
                >
                    {{ journalData.data.status }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode" class="content">
            <v-scroll-y-transition appear leave>
                <div class="pt-4">
                    <v-card-text
                        v-if="hasComment()"
                        class="journal-indent pt-0 text-grey-darken"
                    >
                        <p class="text-grey mb-2">
                            {{ journalData.data.observer_comment }}
                        </p>
                    </v-card-text>
                    <v-card-text
                        v-for="sample in journalData.data.lab_result_group"
                        :key="sample.specimen"
                        class="pt-0 text-normal"
                    >
                        <div
                            class="d-flex align-items-center journal-indent"
                            :class="fontClass"
                        >
                            <div
                                v-if="hasSpecimen(sample)"
                                class="text-bold my-auto"
                            >
                                {{ sample.specimen }}
                            </div>
                            <v-chip
                                size="small"
                                class="ml-3 text-caption user-select-none"
                            >
                                {{ formatDate(sample.sample_time) }}
                            </v-chip>
                        </div>
                        <p
                            v-if="hasGroupComment(sample)"
                            class="text-grey mb-2"
                        >
                            {{ sample.group_comment }}
                        </p>
                        <v-table density="compact" class="table-indent mt-2">
                            <thead>
                                <tr :class="fontClass">
                                    <th class="text-bold">
                                        {{
                                            $t(
                                                "journal.editor.labresult.analysis",
                                            )
                                        }}
                                    </th>
                                    <th class="text-bold">
                                        {{
                                            $t(
                                                "journal.editor.labresult.result",
                                            )
                                        }}
                                    </th>
                                    <th class="text-bold">
                                        {{
                                            $t("journal.editor.labresult.range")
                                        }}
                                    </th>
                                    <th class="text-bold">
                                        {{
                                            $t(
                                                "journal.editor.labresult.comment",
                                            )
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        resultItem, resultIndex
                                    ) in sample.items"
                                    :key="resultIndex"
                                    class="text-normal"
                                    :class="fontClass"
                                >
                                    <td>{{ resultItem.analysis }}</td>
                                    <td>
                                        {{ resultItem.result }}
                                        {{ resultItem.unit }}
                                    </td>
                                    <td>{{ resultItem.range }}</td>
                                    <td>{{ resultItem.analysis_comment }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hovered: false,
            compactMode: false,
        };
    },
    computed: {
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
    },
    methods: {
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        hasGroupComment(sample) {
            return sample.group_comment && sample.group_comment !== "";
        },
        hasSpecimen(sample) {
            return sample.specimen && sample.specimen !== "";
        },
        hasComment() {
            const t1 = this.journalData.data.observer_comment;
            if (t1 === null) {
                return false;
            }
            const t2 = this.journalData.data.observer_comment !== "";
            return t1 && t2;
        },
        hasStatus() {
            return (
                this.journalData.data.status &&
                this.journalData.data.status !== ""
            );
        },
        getAvatarColor(priority) {
            return this.hovered
                ? "grey"
                : priority === "S"
                  ? "orange"
                  : "primary";
        },
        editLabResult() {},
        formatDate(value) {
            const locale = this.$t("language.locale_name");
            return new Date(value).toLocaleDateString(locale);
        },
        translatedStatus(status) {
            return this.$t(`labresult.${status.trim()}`);
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
<style scoped>
.journal-indent {
    margin-left: 40px;
}
.table-indent {
    margin-left: 25px;
}
.content {
    margin-left: 0px;
}
</style>
