<template>
    <div class="pa-4 text-center">
        <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="600" persistent>
            <v-card prepend-icon="mdi-content-duplicate" title="Tvítekin gögn">
                <v-card-text>
                    <div v-for="id in duplicates" :key="id">
                        <div>kind:{{ id }}</div>
                    </div>
                    <h3 class="text-center">Viltu yfirskrifa eða hætta við?</h3>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text="Yfirskrifa"
                        variant="outlined" 
                        density="compact"
                        :class="no-upper"
                        @click="continueImport"
                    />
                    <v-btn
                        text="Hætta við"
                        variant="outlined" 
                        density="compact"
                        :class="no-upper"
                        @click="abortImport"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    props: {
        duplicates: {
            type: Array,
            required: false,
            default:() => []
        }
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        },
        abortImport() {
            this.$emit('abortImport');
            this.closeModal();
        },
        continueImport() {
            this.$emit('continueImport')
            this.closeModal();
        }
    }
};
</script>
<style scoped>
.v-btn {
height: 35px;
}
.no-upper {
text-transform: unset !important;
}
</style>
