import ApiBase from "./apibase";

export default new (class Pos extends ApiBase {
    /**
     * Get all the Pos resources.
     * @returns Promise
     */
    all() {
        return this.getData("pos");
    }

    /**
     * Find a Pos resource by Id.
     * @param {integer} id Id of the Pos resource to be found.
     * @returns {Promise}
     */
    find(id) {
        return this.getData(`pos/${id}`);
    }

    /**
     * Create a new Pos resource.
     * @param {object} params Parameters for the new Pos resource
     * @returns {Promise}
     */
    create(params) {
        return this.postData("pos", params);
    }

    /**
     * Update a specific Pos by Id.
     * @param {integer} id Id of the Pos being updated.
     * @param {object} params Parameters for the Pos do be updated with.
     * @returns {Promise}
     */
    edit(id, params) {
        return this.putData(`pos/${id}`, params);
    }

    /**
     * Delete a specific Pos by Id.
     * @param {integer} id id of the pos to be deleted.
     * @returns {Promise}
     */
    destroy(id) {
        return this.delete(`pos/${id}`);
    }

    /**
     * Pair with a specific Pos by id.
     * @param {integer} id Id of the Pos to be Paired
     * @param {string} pairingCode Six digit code to pair terminal.
     * @returns {Promise}
     */
    pair(id, pairingCode) {
        return this.get(`pos/${id}/pair`, { pairing_code: pairingCode });
    }

    separate(id) {
        return this.get(`pos/${id}/separate`);
    }

    postTransaction(id, params) {
        return this.postData(`pos/${id}/transaction`, params);
    }
})();
