<template>
    <div class="tip-tap" @click="setFocus">
        <div v-if="editor">
            <!-- MARKS -->
            <div class="btn-group mr-2 mb-2">
                <!--Use type="button" to stop the page from reloading when clicking the button -->
                <button
                    v-tooltip="'Highlight'"
                    type="button"
                    :class="{
                        'btn btn-outline-primary': true,
                        active: editor.isActive('italic'),
                    }"
                    @click="editor.chain().focus().toggleItalic().run()"
                >
                    <i class="fas fa-highlighter" />
                </button>
                <button
                    v-tooltip="'Superscript'"
                    type="button"
                    :disabled="true"
                    :class="{
                        'btn btn-outline-primary': true,
                        active: editor.isActive('superscript'),
                    }"
                    @click="editor.chain().focus().toggleSuperscript().run()"
                >
                    <i class="fas fa-superscript" />
                </button>
                <button
                    v-tooltip="'Subscript'"
                    type="button"
                    :disabled="true"
                    :class="{
                        'btn btn-outline-primary': true,
                        active: editor.isActive('subscript'),
                    }"
                    @click="editor.chain().focus().toggleSubscript().run()"
                >
                    <i class="fas fa-subscript" />
                </button>
                <button
                    v-tooltip="'Strikethrough'"
                    type="button"
                    :disabled="true"
                    :class="{
                        'btn btn-outline-primary': true,
                        active: editor.isActive('strike'),
                    }"
                    @click="editor.chain().focus().toggleStrike().run()"
                >
                    <i class="fas fa-strikethrough" />
                </button>
            </div>
            <!-- HISTORY -->
            <div class="btn-group mr-2 mb-2">
                <button
                    v-tooltip="'Undo'"
                    type="button"
                    class="btn btn-outline-primary"
                    @click="editor.chain().focus().undo().run()"
                >
                    <i class="fal fa-undo" />
                </button>
                <button
                    v-tooltip="'Redo'"
                    type="button"
                    class="btn btn-outline-primary"
                    @click="editor.chain().focus().redo().run()"
                >
                    <i class="fal fa-redo" />
                </button>
            </div>
        </div>
        <editor-content :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Italic from "@tiptap/extension-italic";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";

const CustomItalic = Italic.extend({
    addAttributes() {
        return {
            color: {
                default: null,
                renderHTML: () => {
                    return {
                        style: "color: black; background-color: orange; font-style: normal;",
                    };
                },
            },
        };
    },
});

export default {
    components: {
        EditorContent,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            editor: null,
        };
    },
    watch: {
        modelValue(newValue) {
            if (this.editor && this.editor.getHTML() !== newValue) {
                this.editor.commands.setContent(newValue, false);
            }
        },
    },
    mounted() {
        this.initializeEditor();
    },
    beforeUnmount() {
        if (this.editor) {
            this.editor.destroy();
        }
    },
    methods: {
        setFocus() {
            this.editor.commands.focus();
        },
        initializeEditor() {
            try {
                this.editor = new Editor({
                    content: this.modelValue,
                    extensions: [
                        CustomItalic,
                        Superscript,
                        Subscript,
                        StarterKit.configure({
                            italic: false, // Disabled default Italic extension
                        }),
                    ],
                    onUpdate: ({ editor }) => {
                        this.$emit("update:modelValue", editor.getHTML());
                    },
                });
            } catch (error) {
                console.error("Editor initialization error:", error);
            }
        },
    },
};
</script>

<style scoped>
.tip-tap {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    min-height: 200px;
}
.tip-tap:hover {
    border: 1px solid black;
    cursor: text;
}
</style>
