<template>
    <div class="container col-12">
        <!-- Mode Selector -->
        <div class="d-flex justify-content-between around">
            <v-radio-group
                v-model="mode"
                class="mx-auto"
                label="Valin hamur"
                inline
            >
                <v-radio
                    v-for="key in Object.keys(modeText)"
                    :key="key"
                    :value="key"
                >
                    <template #label>
                        <div class="mt-2">{{ modeText[key] }}</div>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
        <hr />
        <!-- Import Mode -->
        <div v-if="mode === 'import'">
            <h1 class="mx-auto text-center mb-4">{{ modeText[mode] }}</h1>
            <DropZone
                style="max-width: 300px; margin: 50px auto"
                icon-class="fa-regular fa-cloud-arrow-up fa-xl mb-4 mx-auto"
                :around="aroundDZ"
                :around-drag="aroundDragDZ"
                drop-text="Hægt er að draga .json skjal hingað (eða smella til að velja)"
                supported-extensions="json"
                @files-selected="JSONImport"
            />
            <!-- <pre>{{ formDataJSON }}/pre> -->
            <ImportChoice
                ref="importChoice"
                :duplicates="duplicates"
                @continue-import="continueImport"
                @abort-import="abortImport"
            />
        </div>
        <!-- Export Mode -->
        <div
            v-if="mode === 'export'"
            class="d-flex flex-column mx-auto justify-content-center col-6"
        >
            <h1 class="mx-auto text-center mb-4">{{ modeText[mode] }}</h1>
            <v-select
                v-model="exportSelectedIDs"
                :items="allSheetNames"
                variant="outlined"
                density="compact"
                item-title="name"
                item-value="id"
                label="Veldu skjöl hér"
                multiple
                persistent-hint
            />
            <v-checkbox
                v-model="exportAll"
                class="mt-n2 mr-1"
                @change="exportAllCheckmarkChange()"
            >
                <template #label>
                    <div class="d-flex mt-2 flex-nowrap">Allt er valið</div>
                </template>
            </v-checkbox>

            <p v-if="exportCount === 1" class="text-center">1 skjal valið</p>
            <p v-if="exportCount > 1" class="text-center">
                {{ exportCount }} skjöl valin
            </p>
            <button
                v-if="exportCount > 0"
                type="button"
                class="btn btn-success btn-lg mx-auto text-center"
                @click="exportJSON"
            >
                Hlaða niður völdum
            </button>
        </div>
        <!-- Preview Mode -->
        <div v-if="mode === 'preview'">
            <h1 class="mx-auto text-center mb-4">{{ modeText[mode] }}</h1>
            <v-select
                :key="id"
                v-model="selectedId"
                class="col-6 mx-auto"
                style="margin-top: 50px"
                variant="outlined"
                density="compact"
                label="Smelltu hér til að sjá valmöguleika"
                :items="allSheetNames"
                item-title="name"
                item-value="id"
            />
            <div v-if="selectedId" class="d-flex">
                <v-radio-group
                    v-model="previewMode"
                    class="mx-auto"
                    label="Skoða"
                    inline
                >
                    <v-radio
                        v-for="(rval, index) in pModeTypes"
                        :key="index"
                        :value="rval"
                    >
                        <template #label>
                            <div class="mt-2">{{ rval }}</div>
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-btn
                    class="my-auto"
                    depressed
                    color="error"
                    text="Eyða"
                    @click="removeEntry"
                />
            </div>
            <!-- The Parser -->
            <XMLPreview ref="jsonparser" />
            <!-- XML Preview -->
            <div v-if="previewMode === 'xml'">
                <code>
                    <pre>{{ parsed }}</pre>
                </code>
            </div>
            <!-- XSD Preview -->
            <div v-if="previewMode === 'xsd'">
                <code>
                    <pre>
                        {{ allSheets.find((x) => x.id === selectedId).xsd }}
                    </pre>
                </code>
            </div>
            <!-- JSON Preview -->
            <div v-if="previewMode === 'json'">
                <pre>{{ selectedJson }}</pre>
            </div>
            <!-- The Form -->
            <div class="pb-8">
                <FormRenderer
                    :show="previewMode === 'form'"
                    :es-id="selectedId"
                    es-data-id=""
                    preview="true"
                />
            </div>
        </div>
        <!-- EDIT MODE -->
        <div v-if="mode === 'edit'">
            <EditMode
                :title="modeText[mode]"
                :all-sheet-names="allSheetNames"
                :auto-fields="autoFields"
                :data-types="dataTypes"
                :translations="translations"
            />
        </div>
        <!-- UPLOAD MODE -->
        <div v-if="mode === 'upload'">
            <h1 class="mx-auto text-center mb-4">{{ modeText[mode] }}</h1>
            <v-radio-group
                v-if="!uploaded"
                v-model="uploadMode"
                class="mx-auto"
                label="Tegund skjals"
                inline
            >
                <v-radio
                    v-for="(xval, index) in ['xml', 'xsd']"
                    :key="index"
                    :value="xval"
                >
                    <template #label>
                        <div class="mt-2">{{ xval }}</div>
                    </template>
                </v-radio>
            </v-radio-group>
            <!-- FILE HAS NOT BEEN UPLOADED -->
            <div v-if="!uploaded">
                <div v-if="uploadMode === 'xml'">
                    <DropZone
                        style="max-width: 300px; margin: 50px auto"
                        icon-class="fa-regular fa-cloud-arrow-up fa-xl mb-4 mx-auto"
                        :around="aroundDZ"
                        :around-drag="aroundDragDZ"
                        drop-text="Hægt er að draga .xml skjal hingað (eða smella til að velja)"
                        supported-extensions="xml"
                        @files-selected="XMLUpload"
                    />
                </div>
                <div v-else>
                    <v-select
                        :key="id"
                        v-model="selectedId"
                        class="col-6 mx-auto"
                        style="margin-top: 50px"
                        variant="outlined"
                        density="compact"
                        label="Smelltu hér til að sjá valmöguleika"
                        :items="allSheetNames"
                        item-title="name"
                        item-value="id"
                    />
                    <DropZone
                        v-if="selectedId > 0"
                        disabled="true"
                        style="max-width: 300px; margin: 50px auto"
                        icon-class="fa-regular fa-cloud-arrow-up fa-xl mb-4 mx-auto"
                        :around="aroundDZ"
                        :around-drag="aroundDragDZ"
                        drop-text="Hægt er að draga .xsd skjal hingað (eða smella til að velja)"
                        supported-extensions="xsd"
                        @files-selected="XSDUpload"
                    />
                </div>
            </div>
            <!-- FILE HAS BEEN UPLOADED -->
            <div v-if="uploaded" class="my-4">
                <h3>Skjal: {{ uploaded.filename }}</h3>
                <!-- Sheet id -->
                <div class="my-4">
                    <v-text-field
                        v-model="kindOfSheet"
                        label="Tegund skjals (id)"
                        hide-details="auto"
                        variant="outlined"
                        density="compact"
                    />
                </div>
                <div
                    v-for="(item, index) in JSON.parse(uploaded.json)"
                    :key="index"
                    class="d-flex flex-column mx-auto justify-content-between"
                    :class="determineBorder(item)"
                >
                    <!-- opening tags -->
                    <div
                        v-if="'opening' in item"
                        class="d-flex justify-content-center align-items-center"
                    >
                        <div class="mr-auto mb-5">{{ item.tag }}</div>
                        <div class="d-flex flex-row ml-auto">
                            <div class="d-flex align-items-center ml-auto mt-2">
                                <v-row class="justify-center mr-5">
                                    <v-checkbox
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).many
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">margir</div>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).hidden
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">falinn</div>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </div>
                            <v-text-field
                                variant="outlined"
                                density="compact"
                                placeholder="Flokkur"
                                class="ml-auto mt-1"
                                style="min-width: 200px; max-width: 200px"
                                @input="
                                    updateUserTitleChange(index, $event.target)
                                "
                            />
                        </div>
                    </div>
                    <!-- value tags -->
                    <div
                        v-if="'datatype' in item"
                        class="d-flex justify-content-between"
                    >
                        <div class="mt-2">{{ item.tag }}</div>
                        <div class="d-flex">
                            <v-select
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).datatype
                                "
                                label="Tegund"
                                :items="dataTypes"
                                variant="outlined"
                                density="compact"
                            />
                            <v-select
                                v-if="hasManyTrans(index)"
                                :key="index"
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).translation
                                "
                                class="swh"
                                variant="outlined"
                                density="compact"
                                label="Þýðingar"
                                :items="getTransArr(index)"
                            />
                            <v-text-field
                                v-if="hasManyTrans(index)"
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).translation
                                "
                                variant="outlined"
                                density="compact"
                                placeholder="Þýðing"
                                class="ml-auto mt-0 swh"
                            />
                            <v-text-field
                                v-else
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).translation
                                "
                                variant="outlined"
                                density="compact"
                                placeholder="Þýðing"
                                class="ml-auto mt-0 swh"
                            />

                            <v-select
                                :key="id"
                                v-model="
                                    fieldExtras.find(
                                        (value) => value.index === index,
                                    ).autofill
                                "
                                class="swh"
                                variant="outlined"
                                density="compact"
                                label="Sjálfvirkt"
                                :items="autoFields"
                                item-title="name"
                                item-value="name"
                            />
                            <div class="d-flex align-items-center mx-4 mt-2">
                                <v-row class="justify-center">
                                    <!-- DISABLED -->
                                    <v-checkbox
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).disabled
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">óvirkur</div>
                                        </template>
                                    </v-checkbox>
                                    <!-- HIDDEN -->
                                    <v-checkbox
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).hidden
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">falinn</div>
                                        </template>
                                    </v-checkbox>
                                    <!-- HARDCODED -->
                                    <v-checkbox
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).hardcoded
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">fast gildi</div>
                                        </template>
                                    </v-checkbox>
                                    <div
                                        v-if="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).hardcoded
                                        "
                                    >
                                        <v-text-field
                                            variant="outlined"
                                            density="compact"
                                            placeholder="Fast gildi"
                                            class="ml-2 mt-1"
                                            style="
                                                min-width: 200px;
                                                max-width: 200px;
                                            "
                                            @input="
                                                updateHardCodedValue(
                                                    index,
                                                    $event.target,
                                                )
                                            "
                                        />
                                    </div>
                                    <!-- MULTI LINE -->
                                    <v-checkbox
                                        v-if="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).datatype === 'string'
                                        "
                                        v-model="
                                            fieldExtras.find(
                                                (value) =>
                                                    value.index === index,
                                            ).multiline
                                        "
                                    >
                                        <template #label>
                                            <div class="mt-2">margar línur</div>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <button
                        type="button"
                        class="btn btn-success btn-lg mx-auto"
                        @click="updateSheet"
                    >
                        Uppfæra
                    </button>
                </div>
                <h3 class="my-4">XML Sýnishorn</h3>
                <div class="d-flex justify-content-center mt-4">
                    <pre class="mx-auto">{{ uploaded.xml }}</pre>
                </div>
                <!--pre style="color: blue">{{ fieldExtras }}</pre-->
            </div>
        </div>
    </div>
</template>

<script>
import DropZone from "@components/dropzone/dropzone.vue";
import FormRenderer from "@components/electronicSheets/formRenderer.vue";
import ElectronicSheetsAPI from "@src/api/electronicSheets";
import XMLPreview from "@components/electronicSheets/helpers/xmlPreview.vue";
import ImportChoice from "@components/electronicSheets/helpers/importChoiceModal.vue";
import EditMode from "@components/electronicSheets/editMode.vue";

import { useSnackbarStore } from "@stores/snackbarStore";

export default {
    components: { DropZone, XMLPreview, FormRenderer, ImportChoice, EditMode },
    data() {
        return {
            mode: "preview",
            modeText: {
                preview: "Skoða",
                upload: "Hlaða inn síðu",
                edit: "Breyta síðu",
                import: "Hlaða inn mörgum",
                export: "Hlaða niður json",
            },
            uploadMode: "xml", // xml or xsd
            fileLimit: 1,
            fileExceededError: false,
            kindOfSheet: "",

            // for preview
            previewMode: "form",
            pModeTypes: ["xml", "xsd", "json", "form"],
            selectedId: "",
            selectedJson: [],
            allSheets: [],
            allSheetNames: [],
            parsed: "",

            // for setting details after uploading
            fillInTypesMode: false,
            dataTypes: [
                "string",
                "bool",
                "integer",
                "float",
                "date",
                "datetime",
            ],
            autoFields: [
                { id: 0, name: "off" },
                { id: 1, name: "currentUserID" },
                { id: 2, name: "currentUserName" },
                { id: 3, name: "currentUserEmail" },
                { id: 4, name: "currentUserTitle" },
                { id: 5, name: "currentUserAccess" },
                { id: 6, name: "currentUserDataID" },
                { id: 7, name: "currentUserUsername" },
                { id: 8, name: "currentUserCodenumber" },
                { id: 9, name: "currentUnitID" },
                { id: 10, name: "currentUnitAddress" },
                { id: 11, name: "currentUnitInstitute" },
                { id: 12, name: "currentUnitCodenumber" },
                { id: 13, name: "currentUnitDepartment" },
                { id: 14, name: "currentPatientID" },
                { id: 15, name: "currentPatientAge" },
                { id: 16, name: "currentPatientSex" },
                { id: 17, name: "currentPatientName" },
                { id: 18, name: "currentPatientPhoto" },
                { id: 19, name: "currentPatientAddress" },
                { id: 20, name: "currentPatientBirthDate" },
                { id: 21, name: "currentPatientWorkPhone" },
                { id: 22, name: "currentPatientCodenumber" },
                { id: 23, name: "currentPatientAddressZip" },
                { id: 24, name: "currentPatientAddressCity" },
                { id: 25, name: "currentPatientAddressFull" },
                { id: 26, name: "currentPatientPhoneNumber" },
                { id: 27, name: "currentJournalType" },
                { id: 28, name: "currentJournalTypeID" },
                { id: 29, name: "currentJournalTypeName" },
                { id: 30, name: "signatureType" },
                { id: 31, name: "currentTime" },
                { id: 32, name: "currentDate" },
                { id: 33, name: "currentDateISO" },
            ],
            uploaded: "",
            fieldExtras: [], // datatype, translation, disabled etc.
            translations: [],

            snackbarStore: useSnackbarStore(),

            // for export
            exportSelectedIDs: [],
            exportAll: false,
            // for upload
            formDataJSON: "",
            duplicates: [],
        };
    },
    computed: {
        exportCount() {
            return this.exportSelectedIDs.length;
        },
    },
    watch: {
        mode() {
            this.selectedId = "";
            // update data
            const um = ["preview", "export", "import"];
            if (um.includes(this.mode)) {
                this.getAll();
            }
        },
        selectedId() {
            this.parsed = "";
            if (this.$refs.jsonparser && this.selectedId > 0) {
                const curr = this.allSheets.filter(
                    (x) => x.id === this.selectedId,
                )[0];
                const json = JSON.parse(curr.json);
                this.selectedJson = json;
                this.parsed = this.$refs.jsonparser.xmlFromJson(json);
            }
        },
        exportSelectedIDs(n, o) {
            // selection has been removed
            if (n.length < o.length) {
                this.exportAll = false;
            }
            // everything is selected
            if (n.length === this.allSheets.length) {
                this.exportAll = true;
            }
        },
        duplicates() {
            if (this.duplicates.length > 0) {
                if (this.$refs.importChoice) {
                    this.$refs.importChoice.openModal();
                }
            }
        },
    },
    created() {
        this.getAll();
    },
    methods: {
        // for translations
        hasManyTrans(index) {
            const item = this.fieldExtras[index];
            return Object.keys(item).indexOf("translations") >= 0;
        },
        // for translations
        getTransArr(index) {
            return this.fieldExtras[index].translations;
        },
        updateUserTitleChange(index, text) {
            this.fieldExtras[index].heading = text.value.trim();
        },
        updateUserSelection(index, index2) {
            this.fieldExtras[index].datatype = this.dataTypes[index2];
        },
        updateHardCodedValue(index, text) {
            this.fieldExtras[index].hardcodedValue = text.value.trim();
        },
        currentButtonValue(index, type) {
            const value = this.fieldExtras[index].datatype;
            return value === type;
        },
        removeEntry() {
            ElectronicSheetsAPI.destroy(this.selectedId)
                .then((res) => {
                    const id = res.data;
                    if (id === this.selectedId) {
                        this.snackbarStore.activate(
                            "Skjali var eytt",
                            "success",
                            2000,
                        );
                        window.location.reload();
                    }
                })
                .catch(() => {
                    this.snackbarStore.activate(
                        "Ekki gekk að eyða (kannski eru gögn tengd þessari síðu)",
                        "error",
                        2000,
                    );
                });
        },
        getAll() {
            this.allSheets = [];
            this.allSheetNames = [];
            ElectronicSheetsAPI.getAll()
                .then((res) => {
                    const data = res.data.data;
                    this.allSheets = data;
                    for (let entry of data) {
                        let openingTag = JSON.parse(entry.json)[0].tag;
                        const selection = `${openingTag} (${entry.filename})`;
                        this.allSheetNames.push({
                            name: selection,
                            id: entry.id,
                        });
                    }
                    ElectronicSheetsAPI.getTranslations()
                        .then((res) => {
                            if (res.data.length > 0) {
                                this.translations = res.data;
                                this.snackbarStore.activate(
                                    "Gögn voru sótt",
                                    "success",
                                    2000,
                                );
                            }
                        })
                        .catch(() =>
                            this.snackbarStore.activate(
                                "Ekki gekk að sækja gögn",
                                "error",
                                2000,
                            ),
                        );
                })
                .catch(() =>
                    this.snackbarStore.activate(
                        "Ekki gekk að sækja gögn",
                        "error",
                        2000,
                    ),
                );
        },
        getTranslation(tag) {
            let result = this.translations.filter((value) => value.tag === tag);
            if (result.length === 0) {
                return "";
            }
            result = result.map((value) => value.translation);
            return result.length === 1 ? result[0] : result;
        },
        reset() {
            this.uploaded = "";
            this.fieldExtras = [];
            this.fileExceededError = false;
        },
        XSDUpload(files, info) {
            if (info.length === 0) {
                return;
            }
            if (info.length > this.fileLimit) {
                this.snackbarStore.activate(
                    "Bara eitt skjal í einu",
                    "error",
                    2000,
                );
                return;
            }
            ElectronicSheetsAPI.storeSchema(this.selectedId, { files: files })
                .then(() => {
                    this.snackbarStore.activate(
                        "XSD var vistað á lista",
                        "success",
                        2000,
                    );
                    setTimeout(() => window.location.reload(), 2000);
                })
                .catch(() =>
                    this.snackbarStore.activate(
                        "Ekki gekk að vista XSD á lista",
                        "error",
                        2000,
                    ),
                );
        },
        XMLUpload(files, info) {
            this.reset();
            if (info.length === 0) {
                return;
            }
            if (info.length > this.fileLimit) {
                this.snackbarStore.activate(
                    "Bara eitt skjal í einu",
                    "error",
                    2000,
                );
                return;
            }

            ElectronicSheetsAPI.store({ files: files })
                .then((res) => {
                    const result = res.data;
                    if ("filename" in result) {
                        console.log("uploaded filename:", result.filename);
                        this.uploaded = result;
                        this.fieldExtras = [];
                        const json = JSON.parse(this.uploaded.json);
                        let index = 0;
                        for (let item of json) {
                            if ("opening" in item) {
                                this.fieldExtras.push({
                                    heading: "",
                                    index: index,
                                });
                            } else if ("datatype" in item) {
                                const translation = this.getTranslation(
                                    json[index].tag,
                                );
                                const manyTranslations =
                                    Array.isArray(translation);
                                let extra = {
                                    datatype: "string",
                                    index: index,
                                    tag: json[index].tag,
                                    disabled: false,
                                    autofill: "off",
                                };
                                if (manyTranslations) {
                                    extra.translation = "";
                                    extra.translations = translation;
                                } else {
                                    extra.translation = translation;
                                }
                                this.fieldExtras.push(extra);
                            } else {
                                this.fieldExtras.push({ index: index });
                            }
                            index += 1;
                        }
                    }
                })
                .catch((err) => {
                    let errorMessage = err.response.data.message;
                    this.snackbarStore.activate(errorMessage, "error", 2000);
                    this.reset();
                });
        },
        // when dropzone is used
        JSONImport(files, info) {
            if (info.length === 0) {
                return;
            }
            if (info.length > this.fileLimit) {
                this.snackbarStore.activate(
                    "Bara eitt skjal í einu",
                    "error",
                    2000,
                );
                return;
            }

            this.formDataJSON = "";

            ElectronicSheetsAPI.getJSON({ files: files })
                .then((res) => {
                    const data = res.data;
                    this.formDataJSON = data;
                    let duplicates = data.filter((a) =>
                        this.allSheets.some(
                            (b) => parseInt(a.kind) === parseInt(b.kind),
                        ),
                    );
                    if (duplicates.length > 0) {
                        this.duplicates = duplicates.map((obj) => obj.kind);
                    } else {
                        this.continueImport();
                    }
                })
                .catch((err) => console.log(err));
        },
        continueImport() {
            ElectronicSheetsAPI.importJSON({ json: this.formDataJSON })
                .then((res) => {
                    const data = res.data;
                    if ("saved" in data && data.saved) {
                        this.snackbarStore.activate(
                            data.count + " skjöl voru hlaðin inn",
                            "success",
                            2000,
                        );
                        setTimeout(() => window.location.reload(), 3000);
                    }
                    if ("saved" in data && !data.saved && "reason" in data) {
                        this.snackbarStore.activate(
                            "VILLA:" + data.reason,
                            "error",
                            2000,
                        );
                        this.formDataJSON = "";
                        this.duplicates = [];
                    }
                })
                .catch((err) => {
                    this.snackbarStore.activate(
                        "Villa kom upp við að hlaða inn síðum",
                        "error",
                        2000,
                    );
                    console.log(err);
                    this.formDataJSON = "";
                    this.duplicates = [];
                });
        },
        // if duplicates and abort is selected
        abortImport() {
            this.formDataJSON = "";
            this.duplicates = [];
            this.snackbarStore.activate("Hætt var við aðgerð", "error", 2000);
        },
        // saving data types and translations to json
        updateSheet() {
            // update is only allowed with valid sheet kind (id)
            if (this.kindOfSheet.length === 0) {
                this.snackbarStore.activate(
                    "Það vantar tegund skjals!",
                    "error",
                    2000,
                );
                return;
            }
            const sid = this.kindOfSheet.split(""),
                numbers = "1234567890".split("");
            const invalid = sid
                .map((value) => numbers.includes(value))
                .includes(false);

            if (invalid) {
                this.snackbarStore.activate(
                    "ID (kind) er ekki gilt",
                    "error",
                    2000,
                );
                return;
            }

            // kind id
            const id = this.uploaded.id;
            let json = JSON.parse(this.uploaded.json);
            const extras = this.fieldExtras;

            if (json.length != extras.length) {
                this.snackbarStore.activate("Villa í gögnum!", "error", 2000);
                return;
            }

            let index = 0;
            for (let entry of json) {
                // open tag heading
                if ("opening" in entry) {
                    if (extras[index].heading) {
                        entry.heading = extras[index].heading;
                    }
                    "many" in extras[index] && extras[index].many
                        ? (entry.many = true)
                        : "";
                    "many" in extras[index] && extras[index].many
                        ? (entry.count = 1)
                        : "";
                }
                // value tag type (default is string)
                if ("datatype" in entry) {
                    entry.datatype = extras[index].datatype;
                    if ("translation" in extras[index]) {
                        const translation = extras[index].translation.trim();
                        if (translation.length > 0) {
                            entry.translation = translation;
                        }
                    }
                    if ("autofill" in extras[index]) {
                        entry.autofill = extras[index].autofill;
                    }

                    const hardCoded =
                        "hardcoded" in extras[index] && extras[index].hardcoded;
                    if (
                        hardCoded &&
                        "hardcodedValue" in extras[index] &&
                        extras[index].hardcodedValue
                    ) {
                        entry.hardcoded = extras[index].hardcodedValue;
                    }

                    const multiLine =
                        "multiline" in extras[index] && extras[index].multiline;
                    if (multiLine && entry.datatype === "string") {
                        entry.multiline = true;
                    }
                }
                // only setting checked disabled fields
                "disabled" in extras[index] && extras[index].disabled
                    ? (entry.disabled = true)
                    : "";
                "hidden" in extras[index] && extras[index].hidden
                    ? (entry.hidden = true)
                    : "";
                index += 1;
            }

            const kindOfSheet = this.kindOfSheet;

            ElectronicSheetsAPI.updateElectronicSheet(id, {
                json: json,
                kind: kindOfSheet,
            })
                .then(() => {
                    this.reset();
                    this.fillInTypesMode = false;
                    this.snackbarStore.activate(
                        "Gögn voru uppfærð",
                        "success",
                        2000,
                    );
                    window.location.reload();
                })
                .catch(() =>
                    this.snackbarStore.activate(
                        "Ekki gekk að uppfæra gögn",
                        "error",
                        2000,
                    ),
                );
        },
        // downloads a .json file from selected ids
        exportJSON() {
            const eids = this.exportSelectedIDs;
            ElectronicSheetsAPI.exportJSON({ ids: eids })
                .then((res) => {
                    const data = res.data;
                    const l = this.exportSelectedIDs.length;
                    if ("id" in data[0] && data.length === l) {
                        var blob = new Blob([JSON.stringify(data)], {
                            type: "application/json",
                        });

                        let file = window.URL.createObjectURL(blob);
                        let url = document.createElement("a");
                        url.href = file;
                        url.setAttribute("download", "es-export.json");
                        document.body.appendChild(url);
                        url.click();
                        document.body.removeChild(url);
                        if (l === 1) {
                            this.snackbarStore.activate(
                                "1 skjal var sótt",
                                "success",
                                2000,
                            );
                            return;
                        }
                        this.snackbarStore.activate(
                            l + " skjöl voru sótt",
                            "success",
                            2000,
                        );
                    } else {
                        this.snackbarStore.activate(
                            "Ekki gekk að sækja json skjal",
                            "error",
                            2000,
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.snackbarStore.activate(
                        "Ekki gekk að sækja json skjal",
                        "error",
                        2000,
                    );
                });
        },
        exportAllCheckmarkChange() {
            if (!this.exportAll) {
                return;
            }
            this.exortSelectedIDs = [];
            for (let item of this.allSheetNames) {
                if (this.exportSelectedIDs.indexOf(item.id) < 0) {
                    this.exportSelectedIDs.push(item.id);
                }
            }
        },
        // appearance thing
        determineBorder(item) {
            return Object.keys(item).includes("closing") ? "" : "tableBorder";
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    margin-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}
.tableBorder {
    padding: 10px;
    margin: 0;
    border: 1px solid rgb(226, 226, 226);
    width: 100%;
    max-height: 60px;
    overflow-x: visible;
}
.swh {
    width: 200px;
    height: 40px;
}
.around {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 10px 20px;
}
</style>
