<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <v-avatar
                    class="mr-3"
                    density="compact"
                    :color="getHoverColor()"
                    @mouseenter="hovered = true"
                    @mouseleave="hovered = false"
                >
                    <v-icon :class="getAvatarIconClass()" size="x-small" />
                </v-avatar>
                <div class="text-bold user-select-none" :class="fontClass">
                    {{ journalData.data.name }}
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
                <v-chip
                    v-if="hasCode()"
                    size="x-small"
                    color="purple"
                    class="ml-3"
                >
                    {{ journalData.data.code }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode && hasComment()" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="text-normal">
                        {{ journalData.data.comment }}
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hovered: false,
            mdiPencil: "mdi-pencil",
            compactMode: false,
        };
    },
    computed: {
        transformedMeasureItems() {
            return this.measureItems.map((item) => ({
                name: item.data.name,
                code: item.data.code,
                comment: item.data.comment,
                owner_name: item.data.metadata.owner_name,
            }));
        },
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
    },
    methods: {
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        hasComment() {
            const t1 = this.journalData.data.comment;
            if (t1 === null) {
                return false;
            }
            const t2 = this.journalData.data.comment.trim() != "";
            return t1 && t2;
        },
        hasCode() {
            return (
                this.journalData.data.code &&
                this.journalData.data.code.length > 0
            );
        },
        getHoverColor() {
            return "green";
        },
        getAvatarIcon() {
            return "far fa-scalpel";
        },
        getAvatarIconClass() {
            return "far fa-scalpel";
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
