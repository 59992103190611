<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <!-- read only for now -->
                <!-- <v-avatar :color="getHoverColor()" @mouseenter="hovered = true" @mouseleave="hovered = false" density="compact"> -->
                <v-avatar
                    :color="getHoverColor(index)"
                    density="compact"
                    class="mr-3"
                >
                    <v-icon class="hoverIcon" size="x-small">
                        {{ getAvatarIcon() }}
                    </v-icon>
                </v-avatar>
                <div class="d-flex" :class="fontClass">
                    <div class="text-bold user-select-none">
                        {{ journalData.data.name }}
                    </div>
                    <div class="text-bold user-select-none ml-1">
                        {{ journalData.data.strength }}
                    </div>
                    <div class="text-bold user-select-none ml-1">
                        {{ journalData.data.package }}
                    </div>
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="text-normal mb-2">
                        {{ journalData.data.dosage }}
                    </div>
                    <div class="text-bold">
                        {{ $t("journal.view.prescription_valid_from") }}
                    </div>
                    <div class="text-normal mb-1">
                        {{ formatDate(journalData.data.start_date) }}
                        <span v-if="hasEndDate()">
                            - {{ formatDate(journalData.data.end_date) }}
                        </span>
                    </div>
                    <div v-if="hasComment()">
                        <div class="text-bold">
                            {{ $t("journal.view.comment") }}
                        </div>
                        <div class="text-normal">
                            {{ journalData.data.comment }}
                        </div>
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hovered: false,
            prescriptionItems: [],
            hoveredItemIndex: null,
            hoverColor: "blue",
            avatarIcon: "mdi-prescription",
            compactMode: false,
        };
    },
    computed: {
        isCollapsed() {
            return this.compactMode;
        },
        transformedPrescriptionItems() {
            return this.journalData.map((item) => ({
                name: item.data.name,
                strength: item.data.strength,
                dosage: item.data.dosage,
                package: item.data.package,
                start_date: item.data.start_date,
                end_date: item.data.end_date,
                owner_name: item.data.metadata.owner_name,
            }));
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
    },
    methods: {
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        hasEndDate() {
            return (
                this.journalData.data.end_date &&
                this.journalData.data.end_date.trim() != ""
            );
        },
        hasComment() {
            return (
                this.journalData.data.comment &&
                this.journalData.data.comment.trim() != ""
            );
        },
        handleMouseEnter(index) {
            this.hoveredItemIndex = index;
        },
        handleMouseLeave() {
            this.hoveredItemIndex = null;
        },
        getHoverColor() {
            return this.hovered ? "grey" : "blue";
        },
        getAvatarIcon() {
            return this.hovered ? "mdi-pencil" : "mdi-prescription";
        },
        formatDate(date) {
            const locale = this.$t("language.locale_name");
            return new Date(date).toLocaleDateString(locale);
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
