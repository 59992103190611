<template>
  <div class="editor pt-0 pb-0">
    <!-- no v-if on this please -->
    <ElectronicSheetFormRenderer
      :title="sheetTitle" 
      @closeSheet="closeSheet" 
      :show="showElectronicSheet" 
      :esId="esId" 
      esDataId="" 
    />
    
    <div v-if="showMenu">
      <div v-if="!showJournalViewToEditor">
        <div class="d-flex m-2 toolbar-height">
          <button type="button" class="close ml-auto mr-4" @click="editorClose">
            <span>&times;</span>
          </button>
        </div>
        <div class="d-flex align-items-center mt-4 my-auto toolbar-height">
          <h4 class="my-auto mx-auto">{{ $t('journal.editor.title') }}</h4>
        </div>

        <ElectronicSheetsModal 
          v-if="!showEditorMenuToComponent" 
          @sheetSelected="esIdSelected" 
          :sheets="sheetsDetails" 
          ref="electronicSheetsModal" 
          @closeModal="onModalClose" 
        />
      </div>
     
      <JournalEditorMenu 
        v-if="showJournalEditorMenu" 
        @select-editor="selectEditor" 
        @close="closeEditor" 
        :sheets="sheetsDetails" 
      />
    </div>

    <div v-if="showEditorMenuToComponent || showJournalViewToEditor">
      <!-- Conditional rendering of editors based on journalStore.editor.type -->
      <PrescriptionEditor
        v-if="journalStore.editor.type === '16'" 
        :dataRowId="journalStore.editor.dataRowId" 
        @close="closeEditor" 
      />
      <LabResultEditor 
        v-if="journalStore.editor.type === '4'" 
        :dataRowId="journalStore.editor.dataRowId" 
        @close="closeEditor" 
      />
      <AttentionEditor 
        v-if="journalStore.editor.type === '9'" 
        :dataRowId="journalStore.editor.dataRowId" 
        @close="closeEditor" 
      />
      <DiagnoseEditor 
        v-if="journalStore.editor.type === '2'" 
        :dataRowId="journalStore.editor.dataRowId" 
        @close="closeEditor" 
      />
      <NoteEditor 
        v-if="journalStore.editor.type === '5' && journalStore.editor.type != undefined" 
        :dataRowId="journalStore.editor.dataRowId" 
        @close="closeEditor" 
      />
    </div>
  </div>
</template>

<script>
import { useJournalStore } from '@src/stores/journalStore';
import { mapState } from 'pinia';

import JournalEditorMenu from './journalEditorMenu/journalEditorMenu.vue';
import PrescriptionEditor from '@src/components/prescriptionCreate/prescriptionCreate.vue';
import NoteEditor from './noteEditor/noteEditor.vue';
import DiagnoseEditor from './diagnoseEditor/diagnoseEditor.vue';
import AttentionEditor  from './attentionEditor/attentionEditor.vue';
import LabResultEditor from './labResultEditor/labResultEditor.vue';
import ElectronicSheetsAPI from '@src/api/electronicSheets';
import ElectronicSheetsModal from '@src/components/electronicSheets/helpers/selectESModal';
import ElectronicSheetFormRenderer from '@components/electronicSheets/formRenderer.vue';

export default {
  components: {
    JournalEditorMenu,
    NoteEditor,
    PrescriptionEditor,
    DiagnoseEditor,
    AttentionEditor,
    LabResultEditor,
    ElectronicSheetsModal,
    ElectronicSheetFormRenderer,
  },
  created() {
    ElectronicSheetsAPI.getAll().then(res => {
      let data = res.data.data;
      data = data.map(entry => ({ 
        id: entry.id, 
        type: JSON.parse(entry.json)[0].tag,
        name: this.$t(`journal.electronic_sheets.${JSON.parse(entry.json)[0].tag}`)
      }));
      data.map(entry => entry.name.indexOf('journal.electronic_sheets') >= 0 ? entry.name = entry.type : '');
      this.sheetsDetails = data;
    }).catch(err => console.log('error',err));
  },
  data() {
    return {
      sheetsDetails: [],
      esId: -1,
    };
  },
  computed: {
    ...mapState(useJournalStore, ['editor']),
    showEditorMenuToComponent() {

      if (this.journalStore.editor.type === '5') {

        console.log("journal data",this.journalStore.editor);

      return this.journalStore.editor.type !== undefined && this.journalStore.editor.dataRowId == undefined && this.journalStore.editor.templateId !== undefined;
      }else{
        return this.journalStore.editor.type !== undefined && this.journalStore.editor.dataRowId == undefined ;

      }
    },
    showJournalViewToEditor() {
      return this.journalStore.editor.type !== undefined && this.journalStore.editor.dataRowId !== undefined && this.journalStore.editor.templateId === undefined;
    },
    showJournalEditorMenu() {
      return this.journalStore.editor.type === undefined || this.journalStore.editor.dataRowId === undefined && this.journalStore.editor.templateId === undefined;
    },
    showElectronicSheet() {
      return this.esId > 0;
    },
    showMenu() {
      return this.esId < 0 && !this.showEditorMenuToComponent;
    },
    sheetTitle() {
      const item = this.sheetsDetails.find(item => item.id === this.esId);
      return item ? item.name : '';
    },
    journalStore() {
      return useJournalStore();
    },
  },
  watch: {
    'journalStore.editor': {
      handler(newVal) {
        if (newVal.type === undefined) {
          this.currentEditor = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.top ? this.$refs.top.scrollIntoView(true) : '';
    },
    esIdSelected(id) {
      this.esId = id;
    },
    closeEditor() {
      this.journalStore.resetEditor();
    },
    editorClose() {
      this.$emit('close');
    },
    closeSheet() {
      this.esId = -1;
      this.scrollToTop();
    },
    selectEditor(editorType) {
      this.journalStore.editor = {
        type: editorType, 
        dataRowId: this.journalStore.editor.dataRowId || undefined,
        templateId: this.journalStore.editor.templateId || undefined,
      };
    },
  },
};
</script>

<style scoped>
.toolbar-height {
  height: 64px;
}
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
}
.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}
.no-upper {
    text-transform: unset !important;
}
</style>
