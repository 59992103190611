<template>
    <v-list class="p-0 my-0 ml-1 bg-none">
        <v-list-item
            v-for="(item, index) in journalData.items.filter(
                (x) => !('do_not_render' in x),
            )"
            :key="index"
            class="p-0 m-0"
        >
            <component
                :is="componentTypes[item.type].component"
                :journal-data="item"
                :compact-entries="compactEntries"
                :font-class="fontClass"
                @edit-item="handleEditItem"
            />
        </v-list-item>
    </v-list>
</template>

<script>
import { markRaw } from "vue";
import Note from "@components/journal/journalview/note/note.vue";
import Vaccination from "@components/journal/journalview/vaccination/vaccination.vue";
import Diagnose from "@components/journal/journalview/diagnose/diagnose.vue";
import Prescription from "@components/journal/journalview/prescription/prescription.vue";
import Attention from "@components/journal/journalview/attention/attention.vue";
import Measure from "@components/journal/journalview/measure/measure.vue";
import LabResult from "@components/journal/journalview/labresult/labresult.vue";

import { useUserSettingStore } from "@stores/userSettingStore";

export default {
    components: {
        Note,
        Vaccination,
        Diagnose,
        Prescription,
        Attention,
        Measure,
        LabResult,
    },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    emits: ["edit-item"],
    data() {
        return {
            componentTypes: markRaw({
                2: { name: "Diagnose", component: Diagnose },
                3: { name: "Warning", component: Attention },
                4: { name: "Lab", component: LabResult },
                5: { name: "Note", component: Note },
                6: { name: "Contact", component: "Contact" },
                7: { name: "Measure", component: Measure },
                8: { name: "Document", component: "Document" },
                9: { name: "Attention", component: Attention },
                10: { name: "Summary", component: "Summary" },
                11: { name: "Symbol", component: "Symbol" },
                12: { name: "Dictate", component: "Dictate" },
                13: { name: "Measurement", component: "Measurement" },
                15: { name: "Growth", component: "Growth" },
                16: { name: "Prescription", component: Prescription },
                17: { name: "Draw", component: "Draw" },
                18: { name: "Vaccination", component: Vaccination },
                19: { name: "Audiometry", component: "Audiometry" },
                20: { name: "StatusCheck", component: "StatusCheck" },
                21: { name: "Accident", component: "Accident" },
                22: { name: "HealthIssues", component: "HealthIssues" },
                23: { name: "Optometry", component: "Optometry" },
                24: { name: "MedicalAdvice", component: "MedicalAdvice" },
                25: { name: "ChildDevelopment", component: "ChildDevelopment" },
                26: { name: "Heredity", component: "Heredity" },
                30: { name: "Nutrition", component: "Nutrition" },
                31: { name: "Ophthalmology", component: "Ophthalmology" },
                32: {
                    name: "DrugAdministration",
                    component: "DrugAdministration",
                },
            }),
            userStore: useUserSettingStore(),
            compactEntries: false,
        };
    },
    mounted() {
        this.handleCompactSetting();
    },
    methods: {
        handleEditItem(info) {
            this.$emit("edit-item", info);
        },
        handleCompactSetting() {
            // detect collapse changes in journal setting
            this.userStore.$subscribe((mutation) => {
                if (mutation.storeId === "userSetting") {
                    const updatedSettings =
                        this.userStore.getJournalSettingsCompactSettings();
                    this.compactEntries = updatedSettings.notes;
                }
            });

            const setting = this.userStore.getJournalSettingsCompactSettings();
            this.compactEntries = setting.notes;
        },
    },
};
</script>
<style scoped>
.bg-none {
    background: none;
}
</style>
