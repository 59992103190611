<template>
    <div class="col-10 mx-auto mt-0 pt-0 mb-4">
        <div class="d-flex justify-content-between align-items-center my-4">
            <v-text-field
                v-model="searchQuery"
                rounded
                variant="solo"
                clearable
                prepend-inner-icon="mdi-magnify"
                hide-details
                :label="$t('journal.editor-menu.search')"
                @click:clear="clearSearch"
            ></v-text-field>
        </div>
        <div class="d-flex flex-column">
            <div
                v-for="editor in filteredEditors"
                :key="editor.type"
                class="pointer-cursor px-0"
                @click="selectEditor(editor)"
            >
                <div
                    class="d-flex flex-row justify-content-between py-1 choice"
                >
                    <!-- ELECTRONIC SHEETS -->
                    <div
                        v-if="['-1', '5'].includes(editor.type)"
                        class="d-flex justify-content-start"
                    >
                        <v-icon class="icon" :color="editor.color">{{
                            editor.icon
                        }}</v-icon>
                        <span class="ml-2">{{ editor.name }}</span>
                    </div>
                    <!-- OTHER TYPES -->
                    <div v-else class="d-flex justify-content-start">
                        <v-icon class="icon" :color="editor.color">
                            {{ editor.icon }}
                        </v-icon>
                        <span class="ml-2">{{ editor.name }}</span>
                    </div>
                    <!-- COLLAPSE ICONS -->
                    <div v-if="editor.type === '-1'">
                        <v-icon
                            v-if="expandedSheets"
                            size="x-small"
                            class="text-black font-weight-light icon"
                        >
                            mdi-chevron-down
                        </v-icon>
                        <v-icon
                            v-else
                            size="x-small"
                            class="text-black font-weight-light icon"
                        >
                            mdi-chevron-right
                        </v-icon>
                    </div>
                    <div v-if="editor.type === '5'">
                        <v-icon
                            v-if="expandedNotes"
                            size="x-small"
                            class="text-black font-weight-light icon"
                        >
                            mdi-chevron-down
                        </v-icon>
                        <v-icon
                            v-else
                            size="x-small"
                            class="text-black font-weight-light icon"
                        >
                            mdi-chevron-right
                        </v-icon>
                    </div>
                </div>
                <!-- LIST FOR NOTES -->
                <v-expand-transition appear leave>
                    <div
                        v-if="editor.type === '5' && expandedNotes"
                        class="d-flex flex-column"
                    >
                        <ul
                            v-for="item in filteredNotes"
                            :key="item.template_id"
                            style="list-style: none"
                            class="pointer-cursor px-0 notes-list mb-0"
                            @click="
                                item.isFreeText
                                    ? openFreeTextNoteEditor()
                                    : navigateToNoteEditor(item.template_id)
                            "
                        >
                            <li class="d-flex align-items-center">
                                <v-icon class="icon" color="primary">
                                    mdi-star-four-points-small
                                </v-icon>
                                <span class="ml-0">
                                    {{ item.template_name }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </v-expand-transition>
                <v-expand-transition appear leave>
                    <!-- LIST FOR ES -->
                    <div
                        v-if="editor.type === '-1' && expandedSheets"
                        class="d-flex flex-column"
                    >
                        <ul
                            v-for="item in filteredSheets"
                            :key="item.id"
                            style="list-style: none"
                            class="pointer-cursor px-0 notes-list"
                            @click="selectSheet(item)"
                        >
                            <li class="d-flex align-items-center">
                                <v-icon class="icon" color="primary">
                                    mdi-star-four-points-small
                                </v-icon>
                                <span class="ml-0">{{ item.name }}</span>
                            </li>
                        </ul>
                    </div>
                </v-expand-transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { mapGetters } from "vuex";
import { useJournalStore } from "@src/stores/journalStore";
import { useUserSettingStore } from "@stores/userSettingStore";
import cbmtemplate from "@src/api/cbmtemplate";

export default {
    props: {
        sheets: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    emits: ["sheetSelected", "select-editor"],
    data() {
        return {
            searchQuery: "",
            editors: this.getMenuItems(),
            cbmData: [],
            expandedNotes: false,
            expandedSheets: false,
            journalStore: useJournalStore(),
            userStore: useUserSettingStore(),
        };
    },
    computed: {
        ...mapState(useJournalStore, ["editor"]),
        // search feature
        filteredEditors() {
            const query = this.searchQuery.toLowerCase();
            let result = [];
            let showSheets = this.sheets.some((item) =>
                item.name.toLowerCase().includes(query),
            );
            let showNotes = this.cbmData.some((item) =>
                item.template_name.toLowerCase().includes(query),
            );

            for (let editor of this.editors) {
                if (editor.type === "-1") {
                    showSheets ? result.push(editor) : "";
                    continue;
                }
                if (editor.type === "5") {
                    showNotes ? result.push(editor) : "";
                    continue;
                }
                editor.name.toLowerCase().includes(query)
                    ? result.push(editor)
                    : "";
            }
            return result;
        },
        filteredNotes() {
            const query = this.searchQuery.toLowerCase();
            return this.cbmData.filter((item) =>
                item.template_name.toLowerCase().includes(query),
            );
        },
        filteredSheets() {
            const query = this.searchQuery.toLowerCase();
            return this.sheets.filter((item) =>
                item.name.toLowerCase().includes(query),
            );
        },
        ...mapGetters(["currentJournalTypeID"]),
    },
    watch: {
        searchQuery() {
            if (this.searchQuery.length > 0) {
                this.expandedNotes = true;
                this.expandedSheets = true;
            }
        },
        currentJournalTypeID() {
            this.initializeComponent();
        },
    },
    mounted() {
        this.initializeComponent();
    },
    methods: {
        resetCBM() {
            this.cbmData = [
                {
                    template_id: null,
                    template_name: this.$t("journal.editor-menu.free_text"),
                    isFreeText: true,
                },
            ];
        },
        async initializeComponent() {
            this.resetCBM();
            const activeJournalTypeId = this.currentJournalTypeID;
            const params = activeJournalTypeId
                ? { active_journal_type_id: activeJournalTypeId }
                : {};
            try {
                const response = await this.fetchDataWithParams(
                    cbmtemplate,
                    params,
                );
                const unsorted = [...this.cbmData, ...response.data];
                const sorted = unsorted.sort((a, b) => {
                    return a.template_name > b.template_name;
                });
                this.cbmData = sorted;
            } catch (error) {
                console.error("Error initializing component:", error);
            }
        },
        async fetchDataWithParams(api, params = {}) {
            try {
                return await api.index(params);
            } catch (error) {
                console.error("Error fetching data:", error);
                return [];
            }
        },
        selectSheet(sheet) {
            this.$emit("sheetSelected", sheet.id);
        },
        selectEditor(editor) {
            const editorType = editor.type,
                mode = editor.mode ? editor.mode : "";
            // collapseble
            if (["-1", "5"].includes(editorType)) {
                if (editorType === "-1") {
                    this.expandedSheets = !this.expandedSheets;
                } else {
                    this.expandedNotes = !this.expandedNotes;
                }
                return;
            }

            this.$emit("select-editor", editorType, mode);
            this.journalStore.setEditor({ type: editorType });
        },
        navigateToNoteEditor(templateId) {
            this.$emit("select-editor", "5");
            localStorage.setItem("templateId", templateId);

            this.journalStore.setEditor({
                type: "5",
                templateId: templateId,
            });
        },
        openFreeTextNoteEditor() {
            this.$emit("select-editor", "5");
            this.journalStore.setEditor({
                type: "5", // Note Editor type
                templateId: null, // No template for free text
                isFreeText: true, // Flag for free text mode
                cbm_keyword: "ahbkbkjb",
                title: "Note",
            });
        },
        clearSearch() {
            this.searchQuery = "";
        },
        getColor(color) {
            const colorMap = {
                primary: "#1976d2",
                secondary: "#009688",
            };
            return colorMap[color] || color;
        },
        getMenuItems() {
            // lab is not being used
            // { type: "4", name: this.$t("journal.editor-menu.lab"), icon: "mdi-flask", color: "primary" }
            return [
                {
                    type: "5",
                    name: this.$t("journal.editor-menu.note"),
                    icon: "mdi-file",
                    color: "primary",
                },
                {
                    type: "16",
                    name: this.$t("journal.editor-menu.prescription"),
                    icon: "mdi-prescription",
                    color: "primary",
                },
                {
                    type: "2",
                    mode: "standard",
                    name: this.$t("journal.editor-menu.diagnose"),
                    icon: "fa:fas fa-person-dots-from-line",
                    color: "primary",
                },
                {
                    type: "2",
                    mode: "icpc",
                    name: this.$t("journal.editor-menu.diagnose_icpc"),
                    icon: "fa:fas fa-hand-dots",
                    color: "primary",
                },
                {
                    type: "-1",
                    name: this.$t("journal.electronic_sheets.name"),
                    icon: "mdi-email-newsletter",
                    color: "primary",
                },
                {
                    type: "9",
                    name: this.$t("journal.editor-menu.attention"),
                    icon: "mdi-alert-circle",
                    color: "primary",
                },
            ];
        },
    },
};
</script>

<style scoped>
.favorites {
    margin-top: 50px;
}
.pointer-cursor {
    cursor: pointer;
}
.icon {
    width: 25px;
}
.notes {
    margin-top: 1.5rem;
    margin-bottom: 10px;
    cursor: default;
}
.notes-list {
    margin-left: 10px;
    margin-bottom: 0;
}
.choice:hover,
li:hover {
    color: rgba(var(--v-theme-primary), 0.9);
    /*width: fit-content;*/
}
</style>
