<template >
    <h5 class="my-3 text-right user-select-none"><b>{{ dataset.length }} {{ $t('autoletter.results') }}</b></h5>
    <v-table height="1000px" density="compact" fixed-header class="mb-3">
        <thead >
            <tr>
                <th v-for="(column, index) in header" :key="index" class="font-weight-bold" @click="columnClicked(index)">
                    {{ column }}
                    <i v-if="index === sortingColumn && index === 1 && ascending" class="fa-solid fa-arrow-down-a-z ml-auto"></i>
                    <i v-if="index === sortingColumn && index === 1 && !ascending" class="fa-solid fa-arrow-down-z-a"></i>
                    <i v-if="index === sortingColumn && index  > 1 && ascending" class="fa-solid fa-arrow-down-short-wide"></i>
                    <i v-if="index === sortingColumn && index  > 1 && !ascending" class="fa-solid fa-arrow-up-short-wide"></i>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(data, trindex) in dataset" :key="trindex" class="vtable-row">
                <td v-for="(entry, index) in header" :key="index" @click="rowClicked(trindex)">
                    {{ this.getData(data, index) }}
                </td>
            </tr>
        </tbody>
    </v-table>
</template>
<script>

export default {
    components: {
    },
    props: {
        mode: {
            type: String,
            required: true,
            default: 'tmitem'
        },
        dataset: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            sortingColumn: -1,
            ascending: true
        }
    },
    computed: {
        header() {
            const text = {
                "ssn": this.$t('autoletter.table.ssn'),
                "name": this.$t('autoletter.table.name'),
                "date": this.$t('autoletter.table.date'),
                "treatment_start": this.$t('autoletter.table.treatment_start'),
                "treatment_end": this.$t('autoletter.table.treatment_end'),
                "admission": this.$t('autoletter.table.admission'),
                "discharge": this.$t('autoletter.table.discharge'),
                "arrival": this.$t('autoletter.table.arrival'),
                "status": this.$t('autoletter.table.status')
            };

            let header = [text.ssn, text.name];
            if (this.mode === 'tmitem') {
                header.push(text.arrival);
            }
            if (this.mode === 'casebook') {
                header.push(text.date);
            }
            if (this.mode === 'case') {
                header.push(text.treatment_start);
                header.push(text.treatment_end);
            }
            if (this.mode === 'ipitem') {
                header.push(text.admission);
                header.push(text.discharge);
            }
            header.push(text.status);
            return header;
        }
    },
    methods: {
        columnClicked(index) {
            if(index < 1) {
                return;
            }
            // clicking the same column as before
            // changes order
            if(this.sortingColumn === index) {
                this.ascending = !this.ascending;
            } else {
                // default is ascending
                this.ascending = true;
            }
            this.sortingColumn = index;

            const last = index === this.header.length - 1;

            this.$emit('columnClicked', this.sortingColumn, this.ascending, last);
        },
        rowClicked(index) {
            this.$emit('rowClicked', index)
        },
        getData(data, index) {
            // last index is always send date
            if(index === this.header.length - 1) {
                return data.sendDate ? new Date(data.sendDate).format('Y-m-d H:i') : '';
            }
            if(index === 0) {
                // formatting ssn
                if(data.codenumber.length === 10) {
                    let str = data.codenumber.toString()
                    let firstHalf = str.substring(0, 6);
                    let secondHalf = str.substring(6);
                    return firstHalf + "-" + secondHalf;
                }
                return data.codenumber;
            }
            if(index === 1) {
                return data.name;
            }
            if(index === 2) {
                return data.inDate ? new Date(data.inDate).format('Y-m-d H:i') : '';
            }
            return data.outDate ? new Date(data.outDate).format('Y-m-d H:i') : ''
        }
    }
    
};
</script>
<style scoped>
.vtable-row:hover {
    background-color: rgba(24,103,192,0.7);
    color: white;
    cursor: pointer;
}
</style>
