<template id="Temp">
  <v-app id="app">
    <v-toolbar color="transparent" class="pt-2">
      <v-app-bar-nav-icon class="ml-8"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ patient_name }} Journal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="IsEditorCollapsed" icon @click="showEditor">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid fill-height class="scrollable-container" @scroll="onScroll">
      <template v-for="(date, index) in groupedByDateKeys" :key="date">
        <journal-day :date="date" :entries="groupedByDate[date]"
          :journalData="groupedByDate[date]" @edit-diagnose-item="handleEditDiagnoseItem"
          @edit-attention-item="handleEditAttentionItem" @edit-note-item="handleEditNoteItem"
          @edit-lab-item="handleEditlabResultItem" />
      </template>

      <div v-if="journalData.length === 0" class="no-more-data text-center">
        <span class="text-h6">No Journal Entries</span>
      </div>

      <!-- Loader below data in reduced width -->
      <div v-if="loading" class="loader-container">
        <v-col cols="12" class="text-center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Loading more data
          </v-col>
          <v-progress-linear color="primary" height="6" indeterminate rounded
            style="width: 50%; margin: 0 auto;"></v-progress-linear>
          <v-col class="text-subtitle-1 text-center" cols="12">
            <v-icon>mdi-chevron-down</v-icon>
          </v-col>
        </v-col>
      </div>
    </v-container>

  </v-app>
</template>


<script>
import JournalDay from './journalday/journalday.vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { getCurrentInstance } from 'vue';


export default {
  props: {
    journalData: {
      type: Array,
      required: true,
    },
    sortOrder: {
      type: String,
      required: true,
    },
    IsEditorCollapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    JournalDay,
  },

  setup() {
    const store = useStore();
    const patient_name = computed(() => store.getters.currentPatientName);
    const loading = ref(false);

    const { proxy } = getCurrentInstance();
    const emit = proxy.$emit;

    const onScroll = (event) => {
      const target = event.target;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        loading.value = true;
        emit('fetch-more-data');
      }
    };

    return {
      patient_name,
      onScroll,
      loading
    };
  },

  computed: {
    // Sort journalData by date
    sortedData() {
      let sortedData = this.journalData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
      if (this.sortOrder === 'desc') {
        sortedData.reverse();
      }
      return sortedData;
    },
    // Group the sorted data by date
    groupedByDate() {
      return this.sortedData.reduce((grouped, item) => {
        const key = new Date(item.date).toISOString().split('T')[0];
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
      }, {});
    },
    groupedByDateKeys() {
      return Object.keys(this.groupedByDate);
    },
  },

  methods: {
    onNoMoreData() {
      console.log("No more data left - received in JournalView");
    },
    handleEditDiagnoseItem(index) {
      this.$emit('edit-diagnose-item', index);
    },
    handleEditAttentionItem(item) {
      this.$emit('edit-attention-item', item);
    },
    handleEditNoteItem(item) {
      this.$emit('edit-note-item', item);
    },
    handleEditlabResultItem(item) {
      this.$emit('edit-lab-item', item);
    },
    showEditor() {
      this.$emit('show-editor');
    },
  },
};
</script>

<style scoped>
#app {
  height: 90vh;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.scrollable-container {
  height: fit-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
}
</style>
