import ApiBase from "./apibase";

export default new (class Dialtem extends ApiBase {
    constructor() {
        super("diaItem");
    }

    /**
     * Get most used diaItem array
     * @returns {Promise}
     */
    mostUsed() {
        return this.get(`${this.resourceUrl}/mostUsed`);
    }
})();
