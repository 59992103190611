<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <div
                    @mouseenter="handleMouseEnter(index)"
                    @mouseleave="handleMouseLeave()"
                >
                    <v-avatar
                        :color="getHoverColor(index)"
                        density="compact"
                        class="mr-3"
                    >
                        <v-icon
                            v-if="hoveredItemIndex === index"
                            size="x-small"
                            @click.stop="editDiagnose(journalData)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            v-else
                            size="x-small"
                            :class="getAvatarIconClass(index)"
                        />
                    </v-avatar>
                </div>
                <div class="text-bold user-select-none" :class="fontClass">
                    {{ getHeaderTitle() }}
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
                <v-chip
                    v-if="chipLabel"
                    color="red"
                    class="ml-2 user-select-none"
                    size="x-small"
                >
                    {{ chipLabel }}
                </v-chip>
                <v-chip
                    v-if="hasDialistName()"
                    color="success"
                    class="ml-2"
                    size="x-small"
                >
                    {{ journalData.data.dia_list_name }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode && hasComment()" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="text-bold">
                        {{ $t("journal.view.comment") }}
                    </div>
                    <div class="text-normal">
                        {{ journalData.data.comment }}
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>
<script>
import Owner from "./../owner/owner.vue";

export default {
    components: {
        Owner,
    },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    emits: ["edit-item"],
    data() {
        return {
            hoveredItemIndex: null,
            compactMode: false,
        };
    },
    computed: {
        chipLabel() {
            if (this.journalData.data.chronic_dia === "1") {
                const chronic = this.$t("diagnose.chronic");
                const year = this.journalData.data.chronic_year;
                if (year && year.length === 4) {
                    let since = this.$t("diagnose.since");
                    since = since.toLowerCase();
                    return `${chronic}, ${since} ${year}`;
                }
                return chronic;
            } else if (this.journalData.data.main_dia === "1") {
                return this.$t("diagnose.main");
            } else if (this.journalData.data.sec_dia === "1") {
                return this.$t("diagnose.secondary");
            }
            return "";
        },
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        },
    },
    mounted() {
        this.compactMode = this.compactEntries;
    },
    methods: {
        getHoverColor(index) {
            return this.hoveredItemIndex === index ? "grey" : "teal-lighten-2";
        },
        getAvatarIconClass(index) {
            return this.hoveredItemIndex === index
                ? ""
                : "fat fa-person-dots-from-line";
        },
        getHeaderTitle() {
            const notSpecified = this.$t("diagnose.not_specified");
            let name = "",
                code = "";
            try {
                name = this.journalData.data.name;
                code = this.journalData.data.code.trim();
            } catch {
                return name ? name : notSpecified;
            }

            if (name && code) {
                return `${name} (${code})`;
            }

            return name ? name : notSpecified;
        },
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        hasComment() {
            const t1 = this.journalData.data.comment;
            if (t1 === null) {
                return false;
            }
            const t2 = this.journalData.data.comment.trim() != "";
            return t1 && t2;
        },
        hasDialistName() {
            return (
                this.journalData.data.dia_list_name &&
                this.journalData.data.dia_list_name != ""
            );
        },
        handleMouseEnter(index) {
            this.hoveredItemIndex = index;
        },
        handleMouseLeave() {
            this.hoveredItemIndex = null;
        },
        editDiagnose(item) {
            const id = item.data.data_row_id;
            this.$emit("edit-item", {
                id: id,
                type: "2",
                version: item.data.version,
            });
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
