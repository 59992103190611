<template>
	<div class="tip-tap">
		<div v-if="editor && content">
			<editor-content :editor="editor" />
		</div>
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Italic from '@tiptap/extension-italic'
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';

const CustomItalic = Italic.extend({
	addAttributes() {
		return {
			color: {
				default: null,
				renderHTML: () => {
					return {
						style: 'color: black; background-color: orange; font-style: normal;'
					}
				},
			},
		}
	}
});

export default {
	components: {
		EditorContent,
	},
	props: {
		content: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			editor: null,
		};
	},
	mounted() {
		this.initializeEditor();
	},
	beforeUnmount() {
		if (this.editor) {
			this.editor.destroy();
		}
	},
	methods: {
		initializeEditor() {
			try {
				this.editor = new Editor({
					content: this.content,
					editable: false,
					extensions: [
						CustomItalic,
						Superscript,
						Subscript,
						StarterKit.configure({
							italic: false, // Disabled default Italic extension
						}),
					]
				});
			} catch (error) {
				console.error('Editor initialization error:', error);
			}
		},
	},
	watch: {
		content(newValue) {
			if (this.editor && this.editor.getHTML() !== newValue) {
				this.editor.commands.setContent(newValue, false);
			}
		},
	},
};
</script>

<style scoped>
.tip-tap {
padding: 10px;
}
</style>
