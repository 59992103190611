<template>
    <div ref="scrollTop" class="scroll-top"></div>
    <div class="editor pt-0 pb-1 overflow-y-auto">
        <!-- no v-if on this please -->
        <ElectronicSheetFormRenderer
            :title="sheetTitle"
            :show="showElectronicSheet"
            :es-id="esId"
            es-data-id=""
            @close-sheet="closeSheet"
        />
        <div v-if="showMenu">
            <div v-if="!showJournalViewToEditor">
                <div class="d-flex toolbar-height">
                    <button
                        type="button"
                        class="close ml-auto mr-4"
                        @click="editorClose"
                    >
                        <span>&times;</span>
                    </button>
                </div>
            </div>
            <JournalEditorMenu
                v-if="showJournalEditorMenu"
                :sheets="sheetsDetails"
                @select-editor="selectEditor"
                @sheet-selected="esIdSelected"
                @close="closeEditor"
            />
        </div>
        <div v-if="showEditorMenuToComponent || showJournalViewToEditor">
            <!-- Conditional rendering of editors based on journalStore.editor.type -->
            <DiagnoseEditor
                v-if="journalStore.editor.type === '2'"
                :data-row-id="journalStore.editor.dataRowId"
                :icpc-mode="icpcMode"
                @close="closeEditor"
            />
            <LabResultEditor
                v-if="journalStore.editor.type === '4'"
                :data-row-id="journalStore.editor.dataRowId"
                @close="closeEditor"
            />
            <NoteEditor
                v-if="journalStore.editor.type === '5'"
                :data-row-id="journalStore.editor.dataRowId"
                @close="closeEditor"
            />
            <AttentionEditor
                v-if="journalStore.editor.type === '9'"
                :data-row-id="journalStore.editor.dataRowId"
                @close="closeEditor"
            />
            <PrescriptionEditor
                v-if="journalStore.editor.type === '16'"
                :data-row-id="journalStore.editor.dataRowId"
                @close="closeEditor"
            />
        </div>
    </div>
</template>

<script>
import { useJournalStore } from "@src/stores/journalStore";
import { mapState } from "pinia";

import JournalEditorMenu from "./journalEditorMenu/journalEditorMenu.vue";
import PrescriptionEditor from "@src/components/prescriptionCreate/prescriptionCreate.vue";
import NoteEditor from "./noteEditor/noteEditor.vue";
import DiagnoseEditor from "./diagnosisEditor/diagnosisEditor.vue";
import AttentionEditor from "./attentionEditor/attentionEditor.vue";
import LabResultEditor from "./labResultEditor/labResultEditor.vue";
import ElectronicSheetsAPI from "@src/api/electronicSheets";
import ElectronicSheetFormRenderer from "@components/electronicSheets/formRenderer.vue";

export default {
    components: {
        JournalEditorMenu,
        NoteEditor,
        PrescriptionEditor,
        DiagnoseEditor,
        AttentionEditor,
        LabResultEditor,
        ElectronicSheetFormRenderer,
    },
    data() {
        return {
            sheetsDetails: [],
            esId: -1,
            // for diagnosis
            icpcMode: false,
        };
    },

    computed: {
        ...mapState(useJournalStore, ["editor"]),
        showEditorMenuToComponent() {
            if (this.journalStore.editor.type === "5") {
                console.log("journal data", this.journalStore.editor);

                return (
                    this.journalStore.editor.type !== undefined &&
                    this.journalStore.editor.dataRowId == undefined &&
                    this.journalStore.editor.templateId !== undefined
                );
            } else {
                return (
                    this.journalStore.editor.type !== undefined &&
                    this.journalStore.editor.dataRowId == undefined
                );
            }
        },
        showJournalViewToEditor() {
            return (
                this.journalStore.editor.type !== undefined &&
                this.journalStore.editor.dataRowId !== undefined &&
                this.journalStore.editor.templateId === undefined
            );
        },
        showJournalEditorMenu() {
            return (
                this.journalStore.editor.type === undefined ||
                (this.journalStore.editor.dataRowId === undefined &&
                    this.journalStore.editor.templateId === undefined)
            );
        },
        showElectronicSheet() {
            return this.esId > 0;
        },
        showMenu() {
            return this.esId < 0 && !this.showEditorMenuToComponent;
        },
        sheetTitle() {
            const item = this.sheetsDetails.find(
                (item) => item.id === this.esId,
            );
            return item ? item.name : "";
        },
        journalStore() {
            return useJournalStore();
        },
    },
    watch: {
        "journalStore.editor": {
            handler(newVal) {
                if (newVal.type === undefined) {
                    this.currentEditor = null;
                }
            },
            immediate: true,
        },
    },
    created() {
        ElectronicSheetsAPI.getAll()
            .then((res) => {
                let data = res.data.data;
                data = data.map((entry) => ({
                    id: entry.id,
                    type: JSON.parse(entry.json)[0].tag,
                    name: this.$t(
                        `journal.electronic_sheets.${JSON.parse(entry.json)[0].tag}`,
                    ),
                }));
                data.map((entry) =>
                    entry.name.indexOf("journal.electronic_sheets") >= 0
                        ? (entry.name = entry.type)
                        : "",
                );
                this.sheetsDetails = data;
            })
            .catch((err) => console.log({ error: err }));
    },
    methods: {
        scrollToTop() {
            this.$refs.scrollTop.scrollIntoView(true);
        },
        esIdSelected(id) {
            this.esId = id;
            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
        closeEditor() {
            this.icpcMode = false;
            this.journalStore.resetEditor();
            this.journalStore.unsetEditItem();
            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
        editorClose() {
            this.$emit("close");
        },
        closeSheet() {
            this.esId = -1;
            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
        selectEditor(editorType, mode) {
            const diagnosisType = "2";
            this.icpcMode = editorType === diagnosisType && mode === "icpc";

            this.journalStore.editor = {
                type: editorType,
                dataRowId: this.journalStore.editor.dataRowId || undefined,
                templateId: this.journalStore.editor.templateId || undefined,
                newEntry: true,
            };
            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
    },
};
</script>

<style scoped>
.editor {
    overflow-y: scroll;
    margin-bottom: 135px;
}
.toolbar-height {
    height: 63px;
    border-bottom: 1px solid lightgray;
    margin: 0;
    padding: 0;
}
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s;
}
.slide-enter,
.slide-leave-to {
    transform: translateX(100%);
}
.no-upper {
    text-transform: unset !important;
}
</style>
