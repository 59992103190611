import * as actions from "./actionTypes";
import * as mutations from "./mutationTypes";
import localService from "@src/api/local";
import PatientApi from "@src/api/patient";
import KioskApi from "@src/api/kiosk";
import ReceptionApi from "@src/api/reception";
import JournalType from "@src/api/JournalType";

// Define vuex root store actions here
export default {
    [actions.PASSWORD_LOGIN]({ commit }, { username, password }) {
        return new Promise((resolve, reject) => {
            localService
                .passwordLogin(username, password)
                .then((res) => {
                    commit(mutations.LOGIN_USER, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.EID_LOGIN]({ commit }, username) {
        return new Promise((resolve, reject) => {
            localService
                .loginEid(username)
                .then((res) => {
                    commit(mutations.LOGIN_USER, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.USER_LOGOUT]({ commit }) {
        return new Promise((resolve, reject) => {
            localService
                .logout()
                .then((res) => {
                    commit(mutations.LOGOUT_USER);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_AUTH_USER]({ getters, commit, state }) {
        return new Promise((resolve, reject) => {
            if (getters.userExists) {
                resolve(state.user);
            } else {
                localService
                    .getAuthUser()
                    .then((res) => {
                        commit(mutations.LOGIN_USER, res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    },
    [actions.GET_CURRENT_UNIT]({ commit }) {
        return new Promise((resolve, reject) => {
            localService
                .getCurrentUnit()
                .then((res) => {
                    commit(mutations.CHANGE_UNIT, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.SELECT_UNIT]({ commit }, unit) {
        return new Promise((resolve, reject) => {
            localService
                .switchUnit(unit.id)
                .then((res) => {
                    commit(mutations.CHANGE_UNIT, unit);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_CURRENT_USER_UNITS]({ state, getters, commit }) {
        return new Promise((resolve, reject) => {
            if (!getters.userExists) {
                reject(new Error("No current user"));
            }
            localService
                .getUserUnits(state.user.id)
                .then((res) => {
                    commit(mutations.UPDATE_AVAILABLE_UNITS, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_CURRENT_PATIENT]({ commit }) {
        return new Promise((resolve, reject) => {
            localService
                .getCurrentPatient()
                .then((res) => {
                    commit(mutations.CHANGE_PATIENT, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.FIND_PATIENT_BY_CODENUMBER]({ commit }, codenumber) {
        return new Promise((resolve, reject) => {
            PatientApi.getPatientByCodenumber(codenumber)
                .then((res) => {
                    commit(mutations.CHANGE_PATIENT, res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.UPDATE_CURRENT_USER_EMAIL]({ commit }, email) {
        return new Promise((resolve, reject) => {
            localService
                .updateAuthUserEmail(email)
                .then((res) => {
                    commit(mutations.SET_USER_EMAIL, email);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_IS_CLIENT]({ commit }) {
        return new Promise((resolve, reject) => {
            localService
                .getIsClient()
                .then(({ data }) => {
                    commit(mutations.SET_CLIENT, data.is_client);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_SESSION_POS]({ commit }) {
        return new Promise((resolve, reject) => {
            KioskApi.getSessionPos()
                .then((response) => {
                    commit(mutations.SET_SESSION_POS, response);
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.SET_JOURNAL_TYPE]({ commit }, journalType) {
        return new Promise((resolve, reject) => {
            JournalType.setActiveJournalType({
                journal_type_id: journalType.id,
            })
                .then(() => {
                    commit(mutations.SET_JOURNAL_TYPE, journalType);
                    resolve(journalType);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_JOURNAL_TYPE]({ commit }) {
        return new Promise((resolve, reject) => {
            JournalType.getActiveJournalType()
                .then(({ data }) => {
                    commit(mutations.SET_JOURNAL_TYPE, data.data);
                    resolve(data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [actions.GET_SIGNATURE_TYPE]({ commit }) {
        return new Promise((resolve, reject) => {
            ReceptionApi.getSignatureType()
                .then(({ data }) => {
                    commit(mutations.SET_SIGNATURE_TYPE, data.signature_type);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
