<template>
    <v-card flat>
        <v-toolbar color="transparent">
            <v-avatar :color="hoverColor" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <v-icon v-if="isHovered" @click="editAttention(journalData)">mdi-pencil</v-icon>
                <template v-else>
                    <i v-if="journalData.type === '9'" :class="getCategoryIcon(journalData.data.category)"></i>
                    <i v-else class="fal fa-exclamation-triangle"></i>
                </template>
            </v-avatar>
            <v-toolbar-title>
                <strong>{{ journalData.type === '9' ? getTranslatedCategory(journalData.data.category) : $t('Warning')
                    }}</strong>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip prepend-icon="mdi-doctor" class="text-0" size="small">
                {{ journalData.user }}
            </v-chip>
        </v-toolbar>
        <div class="journal-indent">
            <v-card-text class="pt-0">{{ journalData.data.text }}</v-card-text>
            <v-card-title
                v-if="(journalData.data.atc_code && journalData.data.atc_code != '') || (journalData.data.atc_code_desc && journalData.data.atc_code_desc != '')"
                class="pt-0">ATC</v-card-title>
            <v-card-text
                v-if="(journalData.data.atc_code && journalData.data.atc_code != '') || (journalData.data.atc_code_desc && journalData.data.atc_code_desc != '')"
                class="pt-0">
                {{ journalData.data.atc_code_desc }}
                <v-chip class="ml-1" size="small">{{ journalData.data.atc_code }}</v-chip>
            </v-card-text class="pt-0">
            <v-card-title v-if="journalData.data.comment && journalData.data.comment != ''"
                class="pt-0">Comment</v-card-title>
            <v-card-text v-if="journalData.data.comment && journalData.data.comment != ''" class="pt-0">{{
                journalData.comment
            }}</v-card-text>
        </div>
        <confirmationmodal 
    v-model:isModalOpen="isModalOpen" 
    @confirm="confirmEdit(journalData)"
    @cancel="cancelEdit()">
</confirmationmodal>





    </v-card>
</template>

<script>
import AttentionEditor from '../../journalEditor/attentionEditor/attentionEditor.vue';
import { useJournalStore } from '@src/stores/journalStore';
import confirmationmodal from '@components/journal/journalview/confirmationModal/confirmationModal.vue';

export default {
    components: {
        AttentionEditor,
        confirmationmodal
    },
    props: {
        journalData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isModalOpen: false,

            attentionItems: [],
            warningItems: [],
            hoverColor: 'blue',
            hoverColorwarning: 'red',
            isHovered: false,
            isAttentionEditorOpen: false,
            selectedDataRowId: null,
            selectedVersion: null,
            categoryIcons: {
                A: 'fal fa-person-dots-from-line',
                I: 'fal fa-virus',
                B: 'mdi mdi-water-alert',
                N: 'fal fa-exclamation-circle',
                P: 'fal fa-person-pregnant',
                L: 'fal fa-person-breastfeeding',
                M: 'mdi mdi-screw-round-top',
            },
        };
    },
    computed: {
        // transformedAttentionItems() {
        //     return this.attentionItems.map(item => ({
        //         ...item.data,
        //         type: item.type,
        //     }));
        // },
        // transformedWarningItems() {
        //     return this.warningItems.map(item => ({
        //         ...item.data,
        //     }));
        // },
    },
    methods: {
        handleMouseEnter() {
            this.isHovered = true;
            this.hoverColor = 'grey';
            this.hoverColorwarning = 'grey';
        },
        handleMouseLeave() {
            this.isHovered = false;
            this.hoverColor = 'blue';
            this.hoverColorwarning = 'red';
        },
        getCategoryIcon(category) {
            return this.categoryIcons[category] || 'fal fa-exclamation-triangle';
        },
        // attentionItem() {
        //     const journalStore = useJournalStore();
        //     return journalStore.getAttentionItem();

        // },
        editAttention(item) {

            this.$emit('edit-attention-item', item);

            console.log("edit item is",item);
            const journalStore = useJournalStore();

            // currently temporaray dta is also being saved , need to modify logic 
            if (journalStore.editor.dataRowId === undefined && journalStore.editor.type === undefined) {
                this.setEditor(item); // Proceed directly if both are undefined
            } else {
                this.isModalOpen = true;
            }
            // console.log('Updated editor:', journalStore.editor);

            // if (this.isAttentionEditorOpen && this.selectedDataRowId === item.data_row_id) {
            //     this.closeAttentionEditor();
            // } else {
            //     this.selectedDataRowId = item.data_row_id;
            //     this.selectedVersion = item.version;
            //     this.isAttentionEditorOpen = true;
            // }
        },

        confirmEdit(item) {

            this.$emit('edit-attention-item', item);


            const journalStore = useJournalStore();

            journalStore.setEditor({
                dataRowId: this.journalData.data.data_row_id,
                type: this.journalData.data.metadata.module_id,
                templateId: undefined,
            });

            journalStore.showEditor = true; // Show the editor
            this.isModalOpen = false; // Close the modal


        },
        cancelEdit() {

            // Reset the editor properties
            const journalStore = useJournalStore();
            journalStore.resetEditor();
            this.isModalOpen = false; // Close the modal without making changes


        },
        setEditor(item) {
            const journalStore = useJournalStore();

            // Set editor properties
            journalStore.setEditor({
                dataRowId: item.data.data_row_id,
                type: item.data.metadata.module_id,
                templateId: undefined,
            });
            journalStore.showEditor = true; // Show the editor
        },
        // showEditor() {
        //     this.$emit('show-editor');
        // },
        // closeAttentionEditor() {
        //     this.isAttentionEditorOpen = false;
        //     this.selectedDataRowId = null;
        //     this.selectedVersion = null;
        // },
        getTranslatedCategory(category) {
            return this.$t(`attention.${category}`);
        },
    },
    mounted() {
        this.attentionItems = this.journalData;
        const type3Items = this.journalData;
        this.warningItems = type3Items;
    },
};
</script>

<style scoped>
.journal-indent {
    margin-left: 40px;
}
</style>
