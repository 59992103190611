<template>
    <EditNewEntryModal
        v-model:is-modal-open="isEditModalOpen"
        @confirm="modalConfirmEdit"
        @cancel="isEditModalOpen = false"
    />
    <DeleteEntryModal
        v-model:is-modal-open="isDeleteModalOpen"
        @confirm="modalConfirmDelete"
        @cancel="modalCancelDelete"
    />
    <div class="d-flex flex-column journal-container">
        <div class="d-flex vh-100">
            <NoPatientCard v-if="noPatient" />
            <div
                v-else
                class="d-flex overflow-y-hidden mb-120 px-0 mx-0"
                :class="journalClass"
            >
                <v-fade-transition appear leave>
                    <JournalView
                        v-if="show"
                        :key="currentPatientID"
                        :journal-data="originalJournalData"
                        :sort-order="sortOrder"
                        :is-editor-collapsed="isEditorCollapsed"
                        :on-no-more-data="onNoMoreData"
                        :font-class="fontClass"
                        class="bg-transparent"
                        @show-editor="toggleEditor"
                        @edit-item="handleEditItem"
                        @fetch-more-data="onFetchMoreData"
                    />
                </v-fade-transition>
                <v-fade-transition appear leave>
                    <div v-if="!show" class="d-flex flex-column ml-4 pl-4">
                        <h1
                            class="ml-4 fade-out-element text-muted user-select-none loading-text"
                        >
                            {{ $t("journal.loading") }}...
                        </h1>
                        <v-skeleton-loader
                            elevation="12"
                            class="skeleton-loader"
                            :type="getRandomLoad()"
                        />
                    </div>
                </v-fade-transition>
            </div>
            <v-scroll-x-transition
                appear
                leave
                class="d-flex"
                :class="editorClass"
            >
                <div
                    v-if="!isEditorCollapsed && !noPatient"
                    class="d-flex flex-column mx-0 px-0 bg-white"
                >
                    <JournalEditor
                        :is-collapsed="isEditorCollapsed"
                        @close="closeEditor()"
                    />
                </div>
            </v-scroll-x-transition>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, nextTick, onBeforeUnmount } from "vue";
import JournalView from "./journalview/journalview.vue";
import JournalEditor from "./journalEditor/journalEditor.vue";
import NoPatientCard from "./journalview/missing/noPatientCard.vue";
import { useStore, mapGetters } from "vuex";
import journalApi from "@src/api/journalApi";
import journalAccessLogApi from "@src/api/journalAccessLog";
import Note from "@src/api/note";
import Attention from "@src/api/attention";
import Diagnose from "@src/api/diagnose";
import { useSidebarsStore } from "@stores/SidebarsStore";
import { useJournalStore } from "@src/stores/journalStore";
import { useUserSettingStore } from "@stores/userSettingStore";
import { useSnackbarStore } from "@stores/snackbarStore";
import { useI18n } from "vue-i18n";

import EditNewEntryModal from "@components/journal/modals/editNewEntryModal.vue";
import DeleteEntryModal from "@components/journal/modals/deleteEntryModal.vue";

export default {
    components: {
        JournalView,
        JournalEditor,
        NoPatientCard,
        EditNewEntryModal,
        DeleteEntryModal,
    },
    props: {
        isCollapsed: Boolean,
    },
    setup() {
        const store = useStore();
        const sideStore = useSidebarsStore();
        const journalStore = useJournalStore();
        const snackbarStore = useSnackbarStore();
        const journalData = ref([]);
        const originalJournalData = ref([]);
        const storedJournalData = ref([]);
        const datesArray = ref([]);
        const datesLoaded = ref(0);
        const datesLoadedForFilter = ref(0);
        const dateChunkSize = ref(10);
        const apiCount = ref(0);
        const selectedEntry = ref(null);
        const isEditorCollapsed = ref(false);
        const onNoMoreData = ref(false);
        const sortOrder = ref("asc");
        const show = ref(false);
        const isFiltering = ref(false);
        const isSorting = ref(false);
        const datevalue = ref([]);
        const { t } = useI18n();

        const isEditModalOpen = ref(false);
        const isDeleteModalOpen = ref(false);
        // used when confirm is pressed in edit new modal
        const tempEditor = ref({
            dataRowId: undefined,
            type: undefined,
        });

        watch(
            () => sideStore.typesFromJournalStore,
            async () => {
                // a change was detected (no check needed)
                isFiltering.value = true;
                await JournalData();
            },
            { deep: true },
        );

        watch(
            () => sideStore.sortFromJournalStore,
            async () => {
                isSorting.value = true;
                await JournalData();
            },
        );

        // type will maybe be used later
        const liveUpdate = (data, meta, type, dataRowID) => {
            let todayIsPresent = false;

            for (let item of originalJournalData.value) {
                // for updating old notes
                if ("data" in item && "data_row_id" in item.data) {
                    const id = item.data.data_row_id;
                    if (id === dataRowID) {
                        item.data = data;
                        return;
                    }
                }
                // for new notes
                if (!todayIsPresent) {
                    let inputDate = new Date(item.date).setHours(0, 0, 0, 0);
                    let todaysDate = new Date().setHours(0, 0, 0, 0);
                    // entry is only added if today is seen
                    if (inputDate === todaysDate) {
                        todayIsPresent = true;
                    }
                }
            }

            // needs to be set according to the
            // filter in journal settings (tbd)
            // TODO
            todayIsPresent = true;

            // first note
            if (originalJournalData.value.length === 0) {
                todayIsPresent = true;
            }

            // will be added with scroll
            if (!todayIsPresent) {
                return;
            }

            originalJournalData.value.unshift({
                date: meta.data_date || "N/A",
                user: meta.owner_name || "N/A",
                unit: meta.unit_name || "N/A",
                type: meta.module_id || "N/A",
                title: data.title || "N/A",
                data: data,
            });
        };

        const deleteEntry = () => {
            isDeleteModalOpen.value = true;
        };

        let currentPatientID = store.getters.currentPatientID;
        let currentUnitID = store.getters.currentUnitID;
        const currentJournalID = store.getters.currentJournalTypeID;
        const accessLogId = ref(null);
        const logEntryData = ref({});

        const handlePatientChange = async (id) => {
            currentPatientID = id;
            await reset();
            await init();
            show.value = true;
        };

        const handleUnitChange = async (id) => {
            currentUnitID = id;
            await reset();
            await init();
            show.value = true;
        };

        const getRandomLoad = () => {
            const t1 =
                "list-item-avatar, list-item-two-line, list-item-three-line";
            const t2 = "list-item-avatar, text, paragraph, text";
            const t3 =
                "list-item-avatar, paragraph, sentences, text, list-item-two-line";
            const t4 = "list-item-avatar, paragraph, text, text, article";
            return [t1, t2, t3, t4]
                .map((value) => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value);
        };

        const logJournalAccess = async () => {
            try {
                const response = await journalAccessLogApi.store({
                    patient_id: currentPatientID,
                    unit_id: currentUnitID,
                    journal_type_id: currentJournalID,
                    access_date_time: new Date().toISOString(),
                });

                logEntryData.value = response.data;

                if (response.data && response.data.access_id) {
                    accessLogId.value = response.data.access_id; // Store the log ID for fetching later
                } else {
                    console.error("Access log ID not found in response");
                }
            } catch (error) {
                // log object to prevent console flooding  :)
                console.error({ "Failed to log journal access:": error });
            }
        };

        const updateJournalCloseLog = async () => {
            if (accessLogId.value && logEntryData.value) {
                try {
                    await journalAccessLogApi.update(accessLogId.value, {
                        ...logEntryData.value,
                        close_date_time: new Date().toISOString(), // here only updating close time whenever moved away from journal page
                    });
                } catch (error) {
                    console.error({
                        "Failed to update journal close log:": error,
                    });
                }
            } else {
                console.log(
                    "Access log ID or log entry data not available for update.",
                );
            }
        };

        const onFetchMoreData = async () => {
            await loadNextDateChunk();
        };

        const transformData = (data) => {
            // Check if the data is an array
            if (!Array.isArray(data)) {
                console.error("Data is not an array:", data);
                return [];
            }

            // Transform the data and filter out null or undefined items
            return data
                .map((item) => {
                    // Check if item or its `data` or `metadata` is null/undefined
                    if (!item || !item.data || !item.data.metadata) {
                        console.warn(
                            "Invalid or missing data for an item:",
                            item,
                        );
                        return null;
                    }

                    return {
                        date: item.data.metadata.data_date || "N/A",
                        user: item.data.metadata.owner_name || "N/A",
                        unit: item.data.metadata.unit_name || "N/A",
                        type: item.data.metadata.module_id || "N/A",
                        title: item.data.title || "N/A",
                        created:item.data.metadata.created || "N/A",
                        data: item.data,
                    };
                })
                .filter((item) => item !== null);
        };

        const JournalData = async () => {
            try {
                const newParamsforDays = {
                    unit_id: currentUnitID,
                    journal_type_id: currentJournalID,
                    patient_id: currentPatientID,
                    modules: sideStore.typesFromJournalStore,
                    direction: sideStore.sortFromJournalStore,
                };

                const ShowJournalDates =
                    await journalApi.index(newParamsforDays);
                datesArray.value = ShowJournalDates.map((entry) => entry.date);
                await loadNextDateChunk();
            } catch {
                return [];
            }
        };

        const loadNextDateChunk = async () => {
            let totalFetched = 0;
            if (isFiltering.value == true) {
                // when item from filter is clicked
                // Reset the count to fetch a new 10-date batch from the newly fetched dates collection
                datesLoadedForFilter.value = 0;
                dateChunkSize.value = 10;
                apiCount.value = 0;

                let isFirstBatch = true;

                while (totalFetched < 20) {
                    if (datesLoadedForFilter.value >= datesArray.value.length)
                        break; // Stop if no more dates left

                    datevalue.value = datesArray.value.slice(
                        datesLoadedForFilter.value,
                        datesLoadedForFilter.value + dateChunkSize.value,
                    );
                    datesLoadedForFilter.value += dateChunkSize.value;

                    if (datevalue.value.length == 0) {
                        // To handle the 422 response from the journal API, a date is added to the API request.
                        datevalue.value.push("2040-01-01");
                    }

                    const requestData = {
                        patient_id: currentPatientID,
                        unit_id: currentUnitID,
                        journal_type_id: currentJournalID,
                        modules: sideStore.typesFromJournalStore,
                        dates: datevalue.value,
                        direction: sideStore.sortFromJournalStore,
                    };

                    const ShowJournalDataResponse = await journalApi.show(
                        currentJournalID,
                        requestData,
                    );
                    const transformedData = transformData(
                        ShowJournalDataResponse.data,
                    );
                    totalFetched += transformedData.length;

                    apiCount.value += 1;

                    if (isFirstBatch) {
                        originalJournalData.value = [...transformedData];
                        isFirstBatch = false;
                    } else {
                        originalJournalData.value.push(...transformedData);
                    }

                    journalData.value.push(...transformedData);
                }

                datesLoaded.value = 0;
                isFiltering.value = false;
            } else if (isSorting.value == true) {
                while (totalFetched < 20) {
                    if (datesLoaded.value >= datesArray.value.length) break;

                    if (datesLoaded.value == 0) {
                        datesLoaded.value = 10;
                    }

                    datesLoaded.value = datesLoaded.value - 10;
                    datevalue.value = datesArray.value.slice(
                        datesLoaded.value,
                        datesLoaded.value + dateChunkSize.value,
                    );
                    datesLoaded.value += dateChunkSize.value;

                    if (datevalue.value.length == 0) {
                        // To handle the 422 response from the journal API, a date is added to the API request.
                        datevalue.value.push("2040-01-01");
                    }

                    const requestData = {
                        patient_id: currentPatientID,
                        unit_id: currentUnitID,
                        journal_type_id: currentJournalID,
                        modules: sideStore.typesFromJournalStore,
                        dates: datevalue.value,
                        direction: sideStore.sortFromJournalStore,
                    };

                    const ShowJournalDataResponse = await journalApi.show(
                        currentJournalID,
                        requestData,
                    );
                    const transformedData = transformData(
                        ShowJournalDataResponse.data,
                    );
                    originalJournalData.value = [...transformedData];
                    journalData.value.push(...transformedData);
                    totalFetched += transformedData.length;

                    apiCount.value = apiCount.value + 1;
                }
                isSorting.value = false;
            } else {
                // when data is scrolled

                while (totalFetched < 20) {
                    if (datesLoaded.value >= datesArray.value.length) break;

                    datevalue.value = datesArray.value.slice(
                        datesLoaded.value,
                        datesLoaded.value + dateChunkSize.value,
                    );
                    datesLoaded.value += dateChunkSize.value;

                    if (datevalue.value.length == 0) {
                        // To handle the 422 response from the journal API, a date is added to the API request.
                        datevalue.value.push("2040-01-01");
                    }

                    const requestData = {
                        patient_id: currentPatientID,
                        unit_id: currentUnitID,
                        journal_type_id: currentJournalID,
                        modules: sideStore.typesFromJournalStore,
                        dates: datevalue.value,
                        direction: sideStore.sortFromJournalStore,
                    };

                    const ShowJournalDataResponse = await journalApi.show(
                        currentJournalID,
                        requestData,
                    );
                    const transformedData = transformData(
                        ShowJournalDataResponse.data,
                    );
                    totalFetched += transformedData.length;
                    apiCount.value = apiCount.value + 1;
                    originalJournalData.value.push(...transformedData);
                    journalData.value.push(...transformedData);
                }
                isFiltering.value = false;
            }

            if (Math.ceil(datesArray.value.length / 10) == apiCount.value) {
                //no more data left
                onNoMoreData.value = true;
            }
        };

        // reset the component
        const reset = async () => {
            // settings.value = {};
            journalData.value = [];
            // currentChunkIndex.value = 0;
            originalJournalData.value = [];
            storedJournalData.value = [];
            datesArray.value = [];
            datesLoaded.value = 0;
            apiCount.value = 0;
            selectedEntry.value = null;
            // isEditorCollapsed.value = true;
            onNoMoreData.value = false;
            sortOrder.value = "asc";
            show.value = false;

            // close the editor (if open)
            journalStore.unsetEditItem();
            journalStore.resetEditor();
        };

        const init = async () => {
            sideStore.closePatientSidebar();
            logJournalAccess();

            const startTime = performance.now();
            const data = await JournalData();
            const endTime = performance.now();
            const timeTaken = endTime - startTime;
            const timeInSeconds = timeTaken / 1000;
            console.log(
                `Journal data fetch took ${timeInSeconds} seconds to run`,
            );

            if (Array.isArray(data)) {
                journalData.value = data;
            } else {
                // console.error('Journal data is not an array:', data);
            }
            show.value = true;
        };

        onMounted(async () => {
            await init();
            show.value = true;
        });

        onBeforeUnmount(() => {
            updateJournalCloseLog();
        });

        const handleSorting = (order) => {
            sortOrder.value = order;
        };

        const handleSearchQuery = (searchQuery) => {
            if (!searchQuery) {
                originalJournalData.value = [...storedJournalData.value];
            } else {
                const filteredData = storedJournalData.value.filter((item) => {
                    if (
                        item.data &&
                        ((item.data.title &&
                            item.data.title
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())) ||
                            (item.data.name &&
                                item.data.name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())))
                    ) {
                        return true;
                    }
                    return false;
                });

                originalJournalData.value = filteredData;
            }
        };

        const closeEditor = () => {
            isEditorCollapsed.value = true;
        };

        const toggleEditor = () => {
            isEditorCollapsed.value = !isEditorCollapsed.value;
        };

        // there are 3 states
        // 1. nothing is being edited (open right away)
        // 2. a note is being opened but not the same (modal)
        // 3. the same note is being edited (do nothing)
        const handleEditItem = (info) => {
            // editor should always open when edit is pressed
            isEditorCollapsed.value = false;

            const beingEditedID = journalStore.beingEdited.dataRowId;
            const editorClosed = beingEditedID === undefined;
            const beingEdited = info.id === beingEditedID;

            if (beingEdited) {
                return;
            }

            // editor is closed
            if (editorClosed) {
                journalStore.setEditor({
                    dataRowId: info.id,
                    type: info.type,
                    templateId: undefined,
                    version: info.version,
                });

                journalStore.setEditItem(info.id, info.type);
                return;
            }

            // user wants to edit another note
            tempEditor.value = { dataRowId: info.id, type: info.type };
            isEditModalOpen.value = true;
        };

        const modalConfirmEdit = () => {
            isEditModalOpen.value = false;
            nextTick(() => {
                journalStore.resetEditor();
                nextTick(() => {
                    journalStore.setEditor({
                        dataRowId: tempEditor.value.dataRowId,
                        type: tempEditor.value.type,
                        templateId: undefined,
                    });
                    // for live update
                    nextTick(() => {
                        journalStore.unsetEditItem();
                        nextTick(() => {
                            journalStore.setEditItem(
                                tempEditor.value.dataRowId,
                                tempEditor.value.type,
                            );
                        });
                    });
                });
            });
        };

        const modalConfirmDelete = async () => {
            const dataRowId = journalStore.beingDeleted.dataRowId,
                type = journalStore.beingDeleted.type;
            const DIAGNOSE = type === "2",
                NOTE = type === "5",
                ATTENTION = type === "9";
            let response = {},
                index = 0;

            isDeleteModalOpen.value = false;
            journalStore.unsetDeleteItem();

            if (DIAGNOSE) {
                response = await Diagnose.destroy(dataRowId);
            }
            if (NOTE) {
                response = await Note.destroy(dataRowId);
            }
            if (ATTENTION) {
                response = await Attention.destroy(dataRowId);
            }

            const success = Object.keys(response.data).includes("success");

            if (!success) {
                journalStore.resetEditor();
                journalStore.unsetEditItem();
                snackbarStore.activate(
                    t("journal.snackbar.error"),
                    "error",
                    2000,
                );
                return;
            }

            // remove the element from journal view
            for (let item of originalJournalData.value) {
                if ("data" in item && "data_row_id" in item.data) {
                    const id = item.data.data_row_id;
                    if (id.toString() === dataRowId.toString()) {
                        originalJournalData.value.splice(index, 1);
                        break;
                    }
                }
                index += 1;
            }

            journalStore.resetEditor();
            journalStore.unsetEditItem();
            snackbarStore.activate(
                t("journal.snackbar.deleted"),
                "success",
                2000,
            );
        };

        const modalCancelDelete = () => {
            isDeleteModalOpen.value = false;
            journalStore.unsetDeleteItem();
        };

        return {
            liveUpdate,
            init,
            show,
            handlePatientChange,
            handleUnitChange,
            getRandomLoad,
            handleEditItem,
            journalData,
            selectedEntry,
            apiCount,
            isEditorCollapsed,
            closeEditor,
            toggleEditor,
            originalJournalData,
            handleSearchQuery,
            handleSorting,
            sortOrder,
            onFetchMoreData,
            logJournalAccess,
            updateJournalCloseLog,
            onNoMoreData,
            datevalue,
            deleteEntry,
            isEditModalOpen,
            modalConfirmEdit,
            modalCancelDelete,
            modalConfirmDelete,
            isDeleteModalOpen,
            tempEditor,
            t,
        };
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            minWidth: 800,
            journalStore: useJournalStore(),
            userStore: useUserSettingStore(),
            fontClass: "",
        };
    },
    computed: {
        ...mapGetters(["currentPatientID", "currentUnitID"]),
        noPatient() {
            return this.currentPatientID === undefined;
        },
        editorClass() {
            return this.windowWidth > this.minWidth ? "col-5" : "col-12";
        },
        journalClass() {
            if (this.isEditorCollapsed) {
                return "col-12";
            }
            return this.windowWidth > this.minWidth ? "col-7" : "nw";
        },
    },
    watch: {
        currentPatientID() {
            this.handlePatientChange(this.currentPatientID);
        },
        currentUnitID() {
            this.handleUnitChange(this.currentUnitID);
        },
    },
    mounted() {
        this.handleFontSetting();
        window.addEventListener("resize", this.handleResize);
        this.journalStore.unsetEditItem();
        this.journalStore.unsetDeleteItem();
        // listeners
        this.journalStore.$subscribe((mutation) => {
            if (mutation.storeId === "journal") {
                const key = mutation.events.key;
                if (key === "beingEdited") {
                    const newValue = mutation.events.newValue;
                    const oldValue = mutation.events.oldValue;

                    const t1 = newValue.dataRowId != undefined;
                    const t2 = oldValue.dataRowId === undefined;
                    if (t1 || t2) {
                        return;
                    }

                    const dataRowId = oldValue.dataRowId;
                    const dataType = oldValue.type;
                    const timeOut = 2000;

                    const DIAGNOSE = dataType === "2";
                    const NOTE = dataType === "5";
                    const ATTENTION = dataType === "9";

                    setTimeout(() => {
                        if (DIAGNOSE) {
                            const info = {
                                data_row_id: dataRowId,
                                version: "",
                            };
                            Diagnose.show(dataRowId, info)
                                .then((response) => {
                                    const data = response.data[0];
                                    const meta = data.metadata;
                                    this.liveUpdate(data, meta, "2", dataRowId);
                                })
                                .catch(() => {});
                        }

                        if (NOTE) {
                            Note.show(dataRowId)
                                .then((response) => {
                                    const data = response.data[0];
                                    const meta = data.metadata;
                                    this.liveUpdate(data, meta, "5", dataRowId);
                                })
                                .catch(() => {});
                        }

                        if (ATTENTION) {
                            Attention.show(dataRowId)
                                .then((response) => {
                                    const data = response.data;
                                    const meta = data.metadata;
                                    this.liveUpdate(data, meta, "9", dataRowId);
                                })
                                .catch(() => {});
                        }
                    }, timeOut);
                }

                if (key === "beingDeleted") {
                    const newValue = mutation.events.newValue;
                    if (newValue.dataRowId === undefined) {
                        return;
                    }
                    this.deleteEntry();
                }
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        handleFontSetting() {
            // detect font size change in journal setting
            this.journalStore.$subscribe((mutation) => {
                if (mutation.storeId === "journal") {
                    const key = mutation.events.key;
                    if (key === "fontSizeIndex") {
                        const index = mutation.events.newValue;
                        const validIndex = index >= 0 && index <= 4;
                        if (validIndex) {
                            this.userStore.setSettingBySlug(
                                "journal_font_size",
                                index,
                            );
                            this.fontClass =
                                this.journalStore.getCurrentFontSizeClass();
                        }
                    }
                }
            });

            const index = this.userStore.getJournalFontSize();
            if (index === -1) {
                this.userStore.setSettingBySlug("journal_font_size", 1);
                this.fontClass = "text-journal-normal";
                return;
            }

            const validIndex = index >= 0 && index <= 4;
            if (validIndex) {
                this.journalStore.setCurrentFontSizeIndex(index);
                this.fontClass = this.journalStore.getCurrentFontSizeClass();
            }
        },
    },
};
</script>

<style scoped>
.journal-container {
    margin: 15px 2px 0px -10px;
    height: 100vh;
    overflow-y: hidden;
}
.scroll::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

.scroll::-webkit-scrollbar-track {
    background: #bdbdbd;
}

.scroll::-webkit-scrollbar-thumb {
    background: #8e8d8d;
    border-radius: 10px;
}

.loading-text {
    margin: 100px 0 40px 40px;
}

.skeleton-loader {
    border: 0px solid transparent;
    background-color: transparent !important;
    box-shadow: none !important;
}

.nw {
    width: 0;
    visibility: hidden;
}

.mb-120 {
    margin-bottom: 120px;
}
</style>
