import ApiBase from "./apibase";

export default new (class JournalType extends ApiBase {
    constructor() {
        super("journalType");
    }

    /**
     * Get the active journal type
     * @returns {Promise}
     */
    getActiveJournalType() {
        return this.get(`${this.resourceUrl}/active`);
    }

    /**
     * Set the active journal type
     * @param {Object} params
     * @returns {Promise}
     */
    setActiveJournalType(params) {
        return this.put(`${this.resourceUrl}/active`, params);
    }
})();
