// Define mutations for root vuex store here
import * as types from "./mutationTypes";

export default {
    [types.LOGIN_USER](state, user) {
        state.user = user;
    },
    [types.LOGOUT_USER](state) {
        state.user = {};
    },
    [types.SET_USER_EMAIL](state, email) {
        state.user.email = email;
    },
    [types.CHANGE_UNIT](state, unit) {
        state.unit = unit;
    },
    [types.CHANGE_PATIENT](state, patient) {
        state.patient = patient;
    },
    [types.UPDATE_AVAILABLE_UNITS](state, units) {
        state.availableUnits = units;
    },
    [types.SET_PRINT_VIEW](state, print) {
        state.print = print;
    },
    [types.SET_CLIENT](state, client) {
        state.client = client;
    },
    [types.SET_DEBUG](state, debug) {
        state.debug = debug;
    },
    [types.SET_SESSION_POS](state, pos) {
        state.pos = pos;
    },
    [types.SET_SIGNATURE_TYPE](state, signatureType) {
        state.signatureType = signatureType;
    },
    [types.SET_LANG](state, payload) {
        state.lang = payload;
    },
    [types.SET_JOURNAL_TYPE](state, payload) {
        state.journalType = payload;
    },
};
