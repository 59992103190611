import ApiBase from './apibase';

export default new class ElectronicSheets extends ApiBase {

    // store an electronic sheet
    store(params) {
        return this.post('es/store', params.files, { headers: { "Content-Type": "multipart/form-data" } });
    }

    // store an electronic sheet
    storeSchema(id, params) {
        return this.post(`es/storeschema/${id}`, params.files, { headers: { "Content-Type": "multipart/form-data" } });
    }

    // store electronic sheet data
    storeData(params) {
        return this.post('es/storedata', params);
    }

    // get all electronic sheets
    getAll() {
        return this.get('es/index');
    }

    // get a single electronic sheet
    getSingleSheet(id) {
        return this.get(`es/show/${id}`);
    }

    // get a single electronic sheet data
    getSingleDataSheet(id) {
        return this.get(`es/showdata/${id}`);
    }

    // update a single electronic sheet
    updateElectronicSheet(id, params) {
        return this.put(`es/update/${id}`, params);
    }

    // get all translations for electronic sheets
    getTranslations() {
        return this.get('es/translations');
    }

    // delete an electronic sheet
    destroy(id) {
        return this.delete(`es/destroy/${id}`);
    }

    // get addresses
    async getAddresses(mode) {
        return this.get('srl/addresses', { mode });
    }

    // export json
    exportJSON(ids) {
        return this.get('es/export', { ids });
    }

    // export json
    importJSON(params) {
        return this.post('es/import', params);
    }

    // returns json from formdata (dropzone)
    getJSON(params) {
        return this.post('es/json', params.files, { headers: { "Content-Type": "multipart/form-data" } });
    }
};
