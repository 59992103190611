import { defineStore } from "pinia";

export const useJournalStore = defineStore("journal", {
    state: () => ({
        view: {},
        editor: {
            dataRowId: undefined,
            type: undefined,
            templateId: undefined,
            version: undefined,
            newEntry: undefined,
        },
        showEditor: false,
        beingEdited: { dataRowId: undefined, type: undefined },
        beingDeleted: { dataRowId: undefined, type: undefined },
        fontSizeNames: ["small", "normal", "medium", "large", "extra-large"],
        fontSizeIndex: 1,
    }),
    getters: {
        getEditor(state) {
            return state.editor;
        },
    },
    actions: {
        setCurrentFontSizeIndex(index) {
            this.fontSizeIndex = index;
        },
        getCurrentFontSizeIndex() {
            return this.fontSizeIndex;
        },
        getCurrentFontSizeName() {
            return this.fontSizeNames[this.fontSizeIndex];
        },
        getCurrentFontSizeClass() {
            return `text-journal-${this.fontSizeNames[this.fontSizeIndex]}`;
        },
        increaseFontSize() {
            if (this.fontSizeIndex >= 4) {
                return;
            }
            this.fontSizeIndex += 1;
        },
        decreaseFontSize() {
            if (this.fontSizeIndex <= 0) {
                return;
            }
            this.fontSizeIndex -= 1;
        },
        // for live update
        setEditItem(dataRowID, type) {
            this.beingEdited = { dataRowId: dataRowID, type: type };
        },
        unsetEditItem() {
            this.beingEdited = { dataRowId: undefined, type: undefined };
        },
        setDeleteItem(dataRowId, type) {
            this.beingDeleted = { dataRowId: dataRowId, type: type };
        },
        unsetDeleteItem() {
            this.beingDeleted = { dataRowId: undefined, type: undefined };
        },
        setEditor(editor) {
            // Spread existing state and incoming editor changes
            this.editor = { ...this.editor, ...editor };
        },
        resetEditor() {
            // Reset the editor back to its initial state
            this.editor = {
                dataRowId: undefined,
                type: undefined,
                templateId: undefined,
                newEntry: undefined,
            };
        },
    },
});
