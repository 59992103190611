// This file contains all constants for mutations
export const LOGIN_USER = "loginUser";
export const LOGOUT_USER = "logoutUser";
export const SET_USER_EMAIL = "changeUserEmail";
export const SET_CLIENT = "setClient";

export const CHANGE_UNIT = "changeUnit";
export const UPDATE_AVAILABLE_UNITS = "updateAvailableUnits";

export const CHANGE_PATIENT = "changePatient";

export const ADD_DOCTOR_PRESCRIPTION = "addDoctorPrescription";
export const ADD_DOCTOR_INVOICE = "addDoctorInvoice";
export const UPDATE_DOCTOR_PRESCRIPTION = "updateDoctorPrescription";
export const UPDATE_DOCTOR_PRESCRIPTIONS = "updateDoctorPrescriptions";
export const UPDATE_DOCTOR_INVOICES = "updateDoctorInvoices";
export const UPDATE_DOCTOR_CASH_REGISTERS = "updateDoctorCashRegisters";
export const UPDATE_INVOICE_PRINTED = "updateInvoicePrinted";

export const SET_PRINT_VIEW = "setPrintView";

export const CHANGE_LOCALE = "changeLocale";

export const SET_SESSION_LIFETIME = "setSessionLifetime";
export const UPDATE_SESSION = "updateSession";

export const SET_DEBUG = "setDebug";
export const SET_SESSION_POS = "setSessionPos";
export const SET_SIGNATURE_TYPE = "setSignatureType";

export const ADD_NOTIFICATION = "addNotification";
export const REMOVE_NOTIFICATION = "removeNotification";

export const SET_JOURNAL_TYPE = "setJournalType";

export const SET_LANG = "setLang";
