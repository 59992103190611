import Dashboard from '@components/signal/dashboard';

import kioskRoutes from './../routes/kioskRoutes';
import journalRoutes from './../routes/journalRoutes';
import electronicSheetRoutes from './../routes/electronicSheetRoutes';
import signalInvoiceRoutes from './../routes/signalInvoiceRoutes';

// todo remove what is not needed

import adminRoutes from './../routes/adminRoutes';
import cashRegisterRoutes from './../routes/cashRegisterRoutes';
import errorRoutes from './../routes/errorRoutes';
import patientIndexRoutes from './../routes/patientIndexRoutes';
import prescriptionRoutes from './../routes/prescriptionRoutes';
import prescriptionRegistryRoutes from './../routes/prescriptionRegistryRoutes';
import receptionRoutes from './../routes/receptionRoutes';
import srlRoutes from './../routes/srlRoutes';
import userRoutes from './../routes/userRoutes';
import signatureRoutes from './../routes/signatureRoutes';


export default [
    {
        path: '/',
        component: Dashboard,
        name: 'home' 
    },
    ...adminRoutes,
    ...cashRegisterRoutes,
    ...errorRoutes,
    ...signalInvoiceRoutes,
    ...patientIndexRoutes,
    ...prescriptionRoutes,
    ...prescriptionRegistryRoutes,
    ...receptionRoutes,
    ...userRoutes,
    ...kioskRoutes,
    ...journalRoutes,
    ...signatureRoutes,
    ...electronicSheetRoutes,
    ...srlRoutes

];
