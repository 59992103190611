<template>
    <v-card class="jv-card" elevation="3">
        <v-card-title class="d-flex header" @click="collapseFunction">
            <div class="d-flex align-items-center">
                <!-- read only for now -->
                <!-- <v-avatar class="buttonHover mr-3" @mouseenter="hovered = true" @mouseleave="hovered = false" :color="getHoverColor(index)" density="compact"> -->
                <v-avatar
                    :color="getHoverColor(index)"
                    density="compact"
                    class="mr-3"
                >
                    <v-icon size="x-small">{{ getAvatarIcon(index) }}</v-icon>
                </v-avatar>
                <div class="text-bold" :class="fontClass">
                    {{ journalData.data.name }}
                </div>
                <v-chip
                    v-if="isDraft()"
                    prepend-icon="mdi-progress-alert"
                    size="x-small"
                    class="user-select-none ml-2"
                    color="warning"
                >
                    {{ $t("journal.view.draft") }}
                </v-chip>
                <v-chip
                    v-if="journalData.data.atc && journalData.data.atc != ''"
                    color="red"
                    class="ml-2"
                    size="x-small"
                >
                    {{ journalData.data.atc }}
                </v-chip>
            </div>
            <Owner
                :name="journalData.data.metadata.owner_name"
                :compact="compactMode"
                :font-size-class="fontClass"
                :created="journalData.data.metadata.created"
                :use-time="journalData.data.metadata.use_time === '1'"
            />
        </v-card-title>
        <v-card-text v-if="!compactMode" class="content-margin">
            <v-scroll-y-transition appear leave>
                <div class="pt-4" :class="fontClass">
                    <div class="mb-1">
                        <div class="text-bold">
                            {{ $t("journal.view.vaccination_disease") }}
                        </div>
                        <div class="text-normal">
                            {{ journalData.data.disease }}
                        </div>
                    </div>
                    <div class="mb-1">
                        <div class="text-bold">
                            {{ $t("journal.view.vaccination_administration") }}
                        </div>
                        <div class="text-normal">
                            {{ journalData.data.administration }}
                        </div>
                    </div>
                    <div class="mb-1">
                        <div class="text-bold">
                            {{ $t("journal.view.vaccination_location") }}
                        </div>
                        <div class="text-normal">
                            {{ journalData.data.location }}
                        </div>
                    </div>
                </div>
            </v-scroll-y-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import Owner from "./../owner/owner.vue";

export default {
    components: { Owner },
    props: {
        journalData: {
            type: Object,
            required: true,
        },
        compactEntries: {
            type: Boolean,
            required: false,
            default: false,
        },
        fontClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            hovered: false,
            vaccinationItems: [],
            hoverColor: "blue",
            avatarIcon: "mdi-needle",
            hoveredItemIndex: null,
            compactMode: false,
        };
    },
    computed: {
        transformedVaccinationItems() {
            return this.vaccinationItems.map((item) => ({
                name: item.data.name,
                atc: item.data.atc,
                owner_name: item.data.metadata.owner_name,
                disease: item.data.disease,
                administration: item.data.administration,
                location: item.data.location,
            }));
        },
        isCollapsed() {
            return this.compactMode;
        },
    },
    watch: {
        compactEntries() {
            this.compactMode = this.compactEntries;
        }
    },
    mounted() {
        this.compactMode = this.compactEntries;
        this.vaccinationItems = this.journalData;
    },
    methods: {
        isDraft() {
            const draft = this.journalData["data"]["metadata"]["draft"];
            return draft === "1";
        },
        getHoverColor() {
            return this.hovered ? "grey" : "blue";
        },
        getAvatarIcon() {
            return this.hovered ? "mdi-pencil" : "mdi-needle";
        },
        collapseFunction() {
            this.compactMode = !this.compactMode;
        },
    },
};
</script>
